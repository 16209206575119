import {
  Button,
  Card,
  Form,
  Input,
  Space,
  Select,
  Col,
  Row,
  Switch,
  InputNumber,
  Tooltip,
} from 'antd';
import DisplayAlert from '../../../components/Commons/Alert';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery, useMutation } from 'react-query';
import { AlertService } from '../../../services/alert.service';
import { create, getById, update } from '../../../services/users.service';
import React, { useEffect, useState } from 'react';
import ConfirmModal from '../../../components/Commons/Modal/confirm-modal.component';
import { getUserProfilesActive } from '../../../services/profiles.service';
import { CopyOutlined } from '@ant-design/icons';

export default function FormPage(props: any) {
  const [leaveThePage, setLeaveThePage] = useState<boolean>(false);
  const [valuesChanged, setValuesChanged] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [passwordForm, setPasswordForm] = useState('');

  const [formUser] = Form.useForm();
  const { id } = useParams();

  const [profiles, setProfiles] = useState<any[]>([]);

  const generateRandomPassword = (length: number) => {
    if (!passwordForm) {
      var charset =
        'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      var password = '';
      for (var i = 0; i < length; i++) {
        var randomIndex = Math.floor(Math.random() * charset.length);
        password += charset[randomIndex];
      }
      setPasswordForm(password);
      formUser.setFieldValue('password', password);
    }
    return passwordForm;
  };

  generateRandomPassword(8);

  const { refetch: loadProfiles } = useQuery(
    ['loadProfiles'],
    () => getUserProfilesActive(),
    {
      onSuccess: (response) => {
        setProfiles(response);
      },
      refetchOnWindowFocus: false,
    }
  );

  const navigate = useNavigate();

  const toPage = (page: string) => {
    let pages = {
      create: '/manager/users/create',
      list: `/manager/users`,
    };

    navigate(pages[page as keyof typeof pages]);
  };

  const settings = {
    create: { title: 'Criar novo utilizador' },
    edit: { title: 'Editar utilizador' },
  };

  const onSubmit = () => {
    formUser
      .validateFields()
      .then((values) => {
        if (!id) {
          createUserMutate(values);
        } else {
          updateUserMutate({
            values: { ...values, active: checked },
            id: Number(id),
          });
        }
      })
      .catch(() =>
        AlertService.sendAlert([
          { text: 'Campo(s) com preenchimento inválido', type: 'error' },
        ])
      );
  };

  const {
    mutate: createUserMutate,
    isLoading: createUserLoading,
    isSuccess: isSuccessNewUser,
    isError: isErrorNewUser,
  } = useMutation(create);

  const {
    mutate: updateUserMutate,
    isLoading: updateUserLoading,
    isSuccess: isSuccessEditUser,
    isError: isErrorEditUser,
  } = useMutation(update);

  useEffect(() => {
    if (isSuccessNewUser) {
      formUser.resetFields();
      AlertService.sendAlert([
        {
          text: 'Novo utilizador gravado com sucesso.',
          nextPage: true,
        },
      ]);
      toPage('list');
    }

    if (isSuccessEditUser) {
      formUser.resetFields();
      AlertService.sendAlert([
        {
          text: 'Utilizador editado com sucesso.',
          nextPage: true,
        },
      ]);
      toPage('list');
    }
  }, [isSuccessNewUser, isErrorNewUser, isSuccessEditUser, isErrorEditUser]);

  useQuery(['loadUser', id], () => (id ? getById(Number(id)) : null), {
    onSuccess: (response) => {
      if (response) {
        formUser.setFieldsValue(response.data);

        setChecked(response.data.active);
      }
    },
    refetchOnWindowFocus: false,
  });

  const [submittable, setSubmittable] = useState(false);

  const values = Form.useWatch([], formUser);

  useEffect(() => {
    formUser
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        setSubmittable(true);
      })
      .catch((error: any) => {
        setSubmittable(false);
      });
  }, [formUser, values]);

  const handleCopyClick = () => {
    const tempInput = document.createElement('input');

    tempInput.value = passwordForm;
    document.body.appendChild(tempInput);

    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices

    document.execCommand('copy');
    document.body.removeChild(tempInput);
  };

  return (
    <>
      <DisplayAlert />
      <div className="content_page">
        <Card
          title={settings[props.action as keyof typeof settings].title}
          headStyle={{
            color: '#107B99',
            fontWeight: 600,
          }}
        >
          <Form
            form={formUser}
            name="create_users"
            layout="vertical"
            className="form_table"
            autoComplete="off"
            onValuesChange={() => setValuesChanged(true)}
          >
            <Row gutter={20} style={{ marginTop: 20 }}>
              <Col span={8}>
                <Form.Item
                  name="name"
                  label="Nome"
                  tooltip="Após gravado, este campo não poderá ser mais alterado."
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input size="large" maxLength={255} disabled={!!id} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="email"
                  label="Email"
                  tooltip="Após gravado, este campo não poderá ser mais alterado."
                  rules={[
                    { type: 'email', message: 'Formato do email inválido' },
                    { required: true, message: 'Campo obrigatório' },
                  ]}
                >
                  <Input size="large" maxLength={50} disabled={!!id} />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="phone"
                  label="Contacto"
                  rules={[
                    {
                      required: true,
                      message: 'Campo obrigatório',
                    },
                    {
                      validator: (_, value: number) => {
                        if (value >= 0 && value?.toString()?.length < 9) {
                          return Promise.reject(
                            'O número de telefone deve ter 9 dígitos'
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    style={{ width: '100%' }}
                    maxLength={11}
                    parser={(value) => `${value}`.replace(/[^0-9]/g, '')}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="profile_id"
                  label="Perfil"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Select
                    placeholder="Seleciona perfil"
                    size="large"
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    options={profiles.map((profile: any) => {
                      return { value: profile.id, label: profile.description };
                    })}
                  />
                </Form.Item>
              </Col>
              {props.action == 'create' && (
                <Col span={8}>
                  <Form.Item
                    name="password"
                    tooltip="Esta é a senha de acesso do usuário. Por favor, certifique-se de anotá-la em um local seguro, pois não será exibida novamente."
                    label="Password"
                    data-html="true"
                    rules={[
                      { required: true, message: 'Campo obrigatório' },
                      // {
                      //   min: 8,
                      //   message: "A password deve ter no mínimo 8 caracteres",
                      // },
                      // {
                      //   pattern: new RegExp(
                      //     "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
                      //   ),
                      //   message:
                      //     "A password deve ter letras maiúsculas, minúsculas, números e caracteres especiais",
                      // },
                    ]}
                    initialValue={passwordForm}
                  >
                    <Input
                      placeholder="Password"
                      readOnly
                      size="large"
                      maxLength={20}
                      addonAfter={
                        <Tooltip title="Clique aqui para copiar a palavra passe">
                          <Button
                            type="text"
                            onClick={() => handleCopyClick()}
                            icon={<CopyOutlined />}
                          />
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                </Col>
              )}
              {props.action == 'edit' && (
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: 25,
                  }}
                >
                  <Form.Item name="active">
                    <Row>
                      <Col flex="auto">
                        <Switch
                          checked={checked}
                          onChange={($event) => {
                            setChecked($event);
                            setValuesChanged(true);
                          }}
                        />
                      </Col>
                      <Col style={{ marginLeft: 10 }}>
                        Utilizador {checked == true ? 'ativo' : 'inativo'}
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row
              gutter={20}
              style={{
                marginTop: 20,
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Col>
                <Space>
                  <Button
                    loading={createUserLoading || updateUserLoading}
                    size="large"
                    onClick={() => {
                      if (!id || valuesChanged) {
                        setLeaveThePage(true);
                      } else {
                        toPage('list');
                      }
                    }}
                  >
                    Cancelar
                  </Button>

                  <Button
                    type="primary"
                    size="large"
                    loading={createUserLoading || updateUserLoading}
                    onClick={onSubmit}
                    disabled={!submittable}
                  >
                    Gravar
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>

          <ConfirmModal
            open={leaveThePage}
            title="Tem certeza que deseja sair sem gravar as alterações?"
            content="Toda a edição não gravada será perdida."
            okText="Sair"
            cancelText="Voltar"
            onOk={() => {
              setLeaveThePage(false);
              toPage('list');
            }}
            onCancel={() => setLeaveThePage(false)}
          ></ConfirmModal>
        </Card>
      </div>
    </>
  );
}

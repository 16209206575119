import { Button, Card, Col, Divider, Form, Input, InputNumber, Row, Select, Switch, Typography } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AlertService } from "../../services/alert.service";
import { useMutation, useQuery } from "react-query";
import { getOneById, storeProduct, editProduct } from "../../services/products.service";
import DisplayAlert from "../../components/Commons/Alert";
import ConfirmModal from "../../components/Commons/Modal/confirm-modal.component";
import { EnumCoins } from "../../Enums/Coins.enums";

const { Title } = Typography;

export default function FormProductsPage(props: any) {
    const [formProducts] = Form.useForm();
    const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);
    const [status, setStatus] = useState<any>(false);

    const { id } = useParams();
    const navigate = useNavigate();

    useQuery(["loadProduct", id], () => (id ? getOneById(Number(id)) : null), {
        enabled: !!id,
        onSuccess: (client) => {
            const { data } = client;
            setStatus(data?.status);
            formProducts.setFieldsValue(data);
        },
    });

    const {
        mutate: store,
        isLoading: isLoadingStore,
        isSuccess: isSuccessStore,
    } = useMutation(storeProduct);

    const {
        mutate: edit,
        isLoading: isLoadingEdit,
        isSuccess: isSuccessEdit,
    } = useMutation(editProduct);

    const goToProducts = () => {
        navigate("/products");
    }

    const onChangeStatus = (checked: boolean) => {
        setStatus(checked)
    };

    useEffect(() => {
        if (isSuccessStore) {
            formProducts.resetFields();

            AlertService.sendAlert([
                {
                    text: "Produto criado com sucesso.",
                    nextPage: true,
                },
            ]);
            goToProducts();
        }
    }, [isSuccessStore]);

    useEffect(() => {
        if (isSuccessEdit) {
            formProducts.resetFields();

            AlertService.sendAlert([
                {
                    text: "Produto editado com sucesso.",
                    nextPage: true,
                },
            ]);
            goToProducts();
        }
    }, [isSuccessEdit]);

    const save = () => {
        formProducts
            .validateFields()
            .then((values) => {
                if (!id)
                    values.status = true

                if (id) {
                    values.status = (status === 0 || status === false) ? false : true
                    return edit({ data: values, id: Number(id) });
                }

                store(values);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className="content_page">
            <DisplayAlert />
            <Card>
                <Row gutter={8}>
                    <Col span={24}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-end",
                            }}
                        >
                            <Title className={'title_page'} style={{ marginTop: 0, marginBottom: 0 }} level={5}>{id ? "Editar produto" : "Criar novo produto"}</Title>
                            {id && <div><Switch size="small" defaultChecked onChange={onChangeStatus} checked={status} /> Ativo</div>}
                        </div>
                    </Col>
                </Row>
                <Divider />
                <Form
                    form={formProducts}
                    layout="vertical"
                >
                    <Row gutter={8}>
                        <Col span={8}>
                            <Form.Item
                                label="Nome"
                                name="name"
                                rules={[{ required: true, message: "Campo obrigatório" }]}
                            >
                                <Input
                                    size="large"
                                    maxLength={255}
                                    placeholder="Nome"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Preço unitário"
                                name="value"
                                rules={[
                                    { required: true, message: "Campo obrigatório" },
                                ]}
                            >
                                <Input
                                    size="large"
                                    style={{ width: "100%" }}
                                    maxLength={255}
                                    placeholder="Preço unitário"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="Comissões"
                                name="commission"
                            >
                                <Input
                                    size="large"
                                    style={{ width: "100%" }}
                                    maxLength={255}
                                    placeholder="Comissões"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="Moeda"
                                name="coin"
                            >
                                <Select
                                    placeholder="Moeda"
                                    size="large"
                                    options={Object.keys(EnumCoins).map(key => {
                                        return {
                                            value: key,
                                            label: EnumCoins[key as keyof typeof EnumCoins]
                                        }
                                    })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col offset={18} span={6} style={{ display: 'flex', justifyContent: 'flex-end', gap: 16 }}>
                            <Button type="default" size="large" loading={isLoadingStore || isLoadingEdit} onClick={() => setOpenModalCancel(true)}>Cancelar</Button>
                            <Button type="primary" size="large" loading={isLoadingStore || isLoadingEdit} onClick={save}>Gravar</Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <ConfirmModal
                open={openModalCancel}
                title="Tem certeza que deseja sair sem gravar as alterações?"
                content="Toda a informação não gravada será perdida."
                okText="Sair"
                cancelText="Cancelar"
                onOk={goToProducts}
                onCancel={() => setOpenModalCancel(false)}
            ></ConfirmModal>
        </div>
    );
}
import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Alert,
  Image,
  Space,
  Typography,
  Checkbox,
} from "antd";
import { authenticate } from "../../services/user.service";
import { useMutation } from "react-query";
import { AlertService } from "../../services/alert.service";
import DisplayAlert from "../Commons/Alert";

export default function Left() {
  const [error, setError] = useState(false);

  const [formLogin] = Form.useForm();

  const { Title, Text } = Typography;

  const { mutate: login, isLoading: loginLoading } = useMutation(authenticate, {
    onSuccess: response => {
      localStorage.setItem("user", JSON.stringify(response.user));

      return window.location.replace("/");
    }
  });

  const onFinish = async (values: any) => {
    formLogin
      .validateFields()
      .then(values => {
        values.email = values.email.trim();
        values.password = values.password.trim();

        login(values);
      })
      .catch(() => AlertService.sendAlert([
        { text: 'Campo(s) com preenchimento inválido', type: 'error' }
      ]));
  };

  return (
    <Space
      direction="vertical"
      size="large"
      style={{
        display: "flex",
        width: "328px",
        margin: "auto",
        padding: "auto",
      }}
    >
      <Image
        preview={false}
        src="/logo-uni.svg"
        style={{
          margin: "auto",
        }}
      />

      <Title level={2}>
        Login{" "}
        <Text type="warning" underline strong>
          __
        </Text>
      </Title>

      <Text>Insira seus dados para aceder a sua conta</Text>

      <DisplayAlert />

      <Form
        onFinish={onFinish}
        form={formLogin}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Por favor insira um e-mail válido!",
            },
          ]}
        >
          <Input key="email_input" id="email_input" size="large" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Palavra-passe"
          rules={[
            {
              required: true,
              message: "Por favor insira uma password válida!",
            },
          ]}
        >
          <Input.Password
            key="password_input"
            id="password_input"
            size="large"
          />
        </Form.Item>
        <Form.Item>
          <Checkbox>Manter-me conectado</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loginLoading}
            size="large"
            block
          >
            <Text>Entrar</Text>
          </Button>
        </Form.Item>
      </Form>

      <Text
        type="secondary"
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        © Copyrights, 2023 Todos os direitos reservados
      </Text>
    </Space>
  );
}

import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Typography,
} from 'antd';
import DisplayAlert from '../../components/Commons/Alert';
import { useEffect, useState } from 'react';
import Search from 'antd/es/input/Search';
import { useMutation, useQuery } from 'react-query';
import {
  checkIfEmailLinkedToReferrer,
  RequestToChangeReferrer,
} from '../../services/clients.service';
import { EuroOutlined, InfoCircleFilled } from '@ant-design/icons';
import RequestToChangeReferrerComponent from '../../components/Clients/requestToChangeReferrer.component';
import { useNavigate, useParams } from 'react-router-dom';
import TextArea from 'antd/es/input/TextArea';
import { EnumPaymentType } from '../../Enums/PaymentType.enums';
import {
  getBusinessType,
  storeBusiness,
} from '../../services/business.service';
import { getAll } from '../../services/users.service';
import { AlertService } from '../../services/alert.service';
import ConfirmModal from '../../components/Commons/Modal/confirm-modal.component';
import { EnumTypeKanban } from '../../Enums/TypeOfKanban.enums';
import { getAllProducts, getProducts } from '../../services/products.service';

const { Title } = Typography;

export default function BusinessForm() {
  const [formBusiness] = Form.useForm();
  const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);
  const [referrerAlreadyExist, setReferrerAlreadyExist] = useState<
    undefined | any
  >();
  const [listBusinessType, setListBusinessType] = useState<undefined | any>([]);
  const [listBusinessStage, setListBusinessStage] = useState<undefined | any>();
  const userLogged: any = localStorage.getItem('user');
  const [user, setUser] = useState(
    localStorage.getItem('user') ? JSON.parse(userLogged) : null
  );
  const [
    openModalRequestToChangeReferrer,
    setOpenModalRequestToChangeReferrer,
  ] = useState<boolean>(false);
  const [disabledTypeBusiness, setDisabledTypeBusiness] =
    useState<boolean>(false);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [userList, setUserList] = useState([]);
  const [productList, setProductList] = useState([]);

  const navigate = useNavigate();
  const { id } = useParams();

  const { isLoading: userLoading } = useQuery(
    'userList',
    () => getAll({ status: 'active' }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (users) => {
        setUserList(users.data);
      },
    }
  );

  const { isLoading: isLoadingDataBusinessType } = useQuery(
    ['getBusinessKanban'],
    getBusinessType,
    {
      refetchOnWindowFocus: false,
      onSuccess: (list) => {
        setListBusinessType(list.data);
      },
    }
  );

  const {
    mutate: store,
    data: dataStore,
    isLoading: isLoadingStore,
    isSuccess: isSuccessStore,
  } = useMutation(storeBusiness);

  const {
    mutate: mutateCheckIfEmailLinkedToReferrer,
    data: dataCheckIfEmailLinkedToReferrer,
    isLoading: isLoadingCheckIfEmailLinkedToReferrer,
    isSuccess: isSuccessCheckIfEmailLinkedToReferrer,
  } = useMutation(checkIfEmailLinkedToReferrer);

  const {
    mutate: mutateRequestToChangeReferrer,
    isLoading: isLoadingRequestToChangeReferrer,
    isSuccess: isSuccessRequestToChangeReferrer,
  } = useMutation(RequestToChangeReferrer);

  const requestNewReferrer = () => {
    mutateRequestToChangeReferrer({
      referrer: user.id,
      client: id ?? referrerAlreadyExist.id,
    });
  };

  useQuery(['listProducts'], () => getAllProducts({ status: 'active' }), {
    onSuccess: (data) => {
      if (data.length) setProductList(data);
    },
  });

  useEffect(() => {
    if (isSuccessRequestToChangeReferrer) {
      setOpenModalRequestToChangeReferrer(false);
      AlertService.sendAlert([
        {
          text: 'A solicitação foi enviada! Aguarde enquanto contactamos o referenciador responsável.',
          type: 'info',
          nextPage: false,
        },
        {
          text: 'Continue o preenchimento dos Detalhes do negócio, enquanto aguarda a resposta do referenciador responsável.',
          type: 'info',
          nextPage: false,
        },
      ]);
    }
  }, [isSuccessRequestToChangeReferrer]);

  const formatPrice: any = (price: any) => {
    return Math.round(price * 100) / 100;
  };

  const parseCurrency: any = (value: any) => {
    const parsedValue = parseFloat(value.replace(/[^\d.-]/g, ''));

    if (isNaN(parsedValue)) {
      return null;
    }
    return parsedValue;
  };

  const changeStage = (id: number) => {
    let stages = listBusinessType.filter((value: any) => value.id === id)[0]
      ?.columns;
    setListBusinessStage(stages);
    formBusiness.setFieldValue('stage', null);
  };

  const goToBusiness = (id: number) => {
    navigate(`/business/details/${id}`);
  };

  const goToBoard = () => {
    navigate(`/business`);
  };

  const save = () => {
    formBusiness
      .validateFields()
      .then((values) => {
        values.referrer_id = user.id;
        values.client_id = referrerAlreadyExist.id;

        store(values);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isSuccessStore) {
      formBusiness.resetFields();

      AlertService.sendAlert([
        {
          text: 'Negócio adicionado com sucesso.',
          nextPage: true,
        },
      ]);
      goToBusiness(dataStore?.data?.id);
    }
  }, [isSuccessStore, dataStore]);

  useEffect(() => {
    if (isSuccessCheckIfEmailLinkedToReferrer) {
      setReferrerAlreadyExist(dataCheckIfEmailLinkedToReferrer.data);

      if (!dataCheckIfEmailLinkedToReferrer.data) {
        formBusiness.setFieldsValue({
          email: '',
          client_name: '',
          phone: '',
          funnel_type: '',
          client_id: '',
        });
        setDisabledTypeBusiness(false);
        setListBusinessStage(null);
        setIsSubmit(false);
      }
    }
  }, [isSuccessCheckIfEmailLinkedToReferrer, dataCheckIfEmailLinkedToReferrer]);

  useEffect(() => {
    if (user) {
      formBusiness.setFieldsValue({
        referrer: user.name,
        referrer_id: user.id,
      });
    }
  }, [user]);

  useEffect(() => {
    if (listBusinessType?.length > 0 && referrerAlreadyExist) {
      const stages = {
        business_club: listBusinessType.find(
          (value: any) => value.type === EnumTypeKanban.COACHING_BUSINESS_CLUB
        )?.id,
        diagnostic: listBusinessType.find(
          (value: any) => value.type === EnumTypeKanban.DIAGNOSIS
        )?.id,
      };

      if (referrerAlreadyExist) {
        formBusiness.setFieldsValue({
          email: referrerAlreadyExist.email,
          client_name: referrerAlreadyExist.name,
          phone: referrerAlreadyExist.phone,
        });
        setIsSubmit(true);

        formBusiness.setFieldsValue({
          business_kanban_id: referrerAlreadyExist.type_business
            ? stages[referrerAlreadyExist.type_business as keyof typeof stages]
            : '',
        });
        setDisabledTypeBusiness(!!referrerAlreadyExist.type_business);
        setListBusinessStage(null);
        if (referrerAlreadyExist.type_business) {
          changeStage(
            stages[referrerAlreadyExist.type_business as keyof typeof stages]
          );
        }
      }
    }
  }, [referrerAlreadyExist, listBusinessType]);

  const validateCurrency = (rule: any, value: any) => {
    if (value === undefined || value === null || value === '') {
      return Promise.resolve(); // Allow empty values
    }
    const currencyRegex = /^(?:0|[1-9]\d*)(?:\.\d{1,2})?$/; // Updated regex
    if (!currencyRegex.test(value.toString())) {
      return Promise.reject('Por favor, insira um valor de moeda válido');
    }
    return Promise.resolve();
  };

  return (
    <div className="content_page">
      <DisplayAlert />
      <Card>
        <Row gutter={8}>
          <Col span={24}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <Title
                className={'title_page'}
                style={{ marginTop: 0, marginBottom: 0 }}
                level={5}
              >
                Criar novo negócio
              </Title>
            </div>
          </Col>
        </Row>
        <Divider />
        <Form
          form={formBusiness}
          layout="vertical"
          initialValues={{ business_status: '⬤ Aberto' }}
        >
          <Row gutter={8}>
            <Col span={24}>
              <Title style={{ marginTop: 0, marginBottom: 16 }} level={5}>
                Informações do cliente
              </Title>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={10}>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[
                  { type: 'email', message: 'Formato do email inválido' },
                  { required: true, message: 'Campo obrigatório' },
                ]}
              >
                <Search
                  size="large"
                  maxLength={50}
                  placeholder="exemplo@exemplo.com"
                  loading={isLoadingCheckIfEmailLinkedToReferrer}
                  enterButton={true}
                  onSearch={(value) => {
                    mutateCheckIfEmailLinkedToReferrer(value);
                  }}
                />
                {referrerAlreadyExist &&
                  referrerAlreadyExist?.referencer?.id !== user.id && (
                    <p style={{ display: 'inline', verticalAlign: 'middle' }}>
                      <InfoCircleFilled
                        style={{
                          fontSize: 18,
                          color: '#FFC53D',
                          marginRight: 8,
                        }}
                      />
                      Este cliente está associado a outro referenciador.
                      <span
                        style={{ color: '#0E3441', cursor: 'pointer' }}
                        onClick={() =>
                          setOpenModalRequestToChangeReferrer(true)
                        }
                      >
                        {' '}
                        Clique aqui{' '}
                      </span>
                      e solicita a alteração do referenciador deste cliente.
                    </p>
                  )}
                {isSuccessCheckIfEmailLinkedToReferrer &&
                  !referrerAlreadyExist && (
                    <p style={{ display: 'inline', verticalAlign: 'middle' }}>
                      <InfoCircleFilled
                        style={{
                          fontSize: 18,
                          color: '#FFC53D',
                          marginRight: 8,
                        }}
                      />
                      Cliente não encontrado.
                    </p>
                  )}
              </Form.Item>
              {openModalRequestToChangeReferrer && (
                <RequestToChangeReferrerComponent
                  client={referrerAlreadyExist}
                  open={openModalRequestToChangeReferrer}
                  close={() => setOpenModalRequestToChangeReferrer(false)}
                  title="Pedido para alteração de Referenciador"
                  readOnly={true}
                  requestNewReferrer={requestNewReferrer}
                  loading={isLoadingRequestToChangeReferrer}
                />
              )}
            </Col>
            <Col span={7}>
              <Form.Item
                label="Nome"
                name="client_name"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input
                  size="large"
                  maxLength={255}
                  placeholder="Nome do cliente"
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Contacto"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                  {
                    validator: (_, value: number) => {
                      if (value >= 0 && value?.toString()?.length < 9) {
                        return Promise.reject(
                          'O número de telefone deve ter 9 dígitos'
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  maxLength={11}
                  parser={(value) => `${value}`.replace(/[^0-9]/g, '')}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  }
                  placeholder="Contacto"
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={8}>
            <Col span={24}>
              <Title style={{ marginTop: 0, marginBottom: 16 }} level={5}>
                Detalhes do negócio
              </Title>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={10}>
              <Form.Item
                label="Nome do negócio"
                name="name"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input
                  size="large"
                  maxLength={255}
                  placeholder="Nome do negócio"
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Valor total" name="value">
                <InputNumber
                  size="large"
                  addonAfter={<EuroOutlined />}
                  style={{ width: '100%' }}
                  formatter={formatPrice}
                  parser={parseCurrency}
                  maxLength={8}
                  min={0}
                  placeholder="Valor total"
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Produto"
                name="product_id"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select placeholder="Escolha um produto" size="large">
                  {productList &&
                    productList.map((value: any) => (
                      <Select.Option key={value.id} value={value.id}>
                        {value.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={10}>
              <Form.Item
                label="Tipo de funil"
                name="business_kanban_id"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select
                  placeholder="Escolha um tipo de funil"
                  size="large"
                  onChange={changeStage}
                  loading={isLoadingDataBusinessType}
                  disabled={disabledTypeBusiness || !listBusinessType}
                >
                  {listBusinessType &&
                    listBusinessType.map((value: any) => (
                      <Select.Option key={value.id} value={value.id}>
                        {value.type === 'COACHING_BUSINESS_CLUB'
                          ? 'Clube de empresários'
                          : 'Diagnóstico'}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Etapa"
                name="stage"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select
                  placeholder="Escolha uma etapa"
                  size="large"
                  disabled={!listBusinessStage}
                >
                  {listBusinessStage &&
                    listBusinessStage.map(
                      (value: any) =>
                        !value.is_last && (
                          <Select.Option key={value.id} value={value.id}>
                            {value.name}
                          </Select.Option>
                        )
                    )}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Status do negócio"
                name="business_status"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input
                  size="large"
                  maxLength={255}
                  placeholder="Status do negócio"
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item label="Descrição do negócio" name="description">
                <TextArea
                  showCount
                  size="large"
                  maxLength={600}
                  placeholder="Descrição do negócio"
                />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={8}>
            <Col span={24}>
              <Title style={{ marginTop: 0, marginBottom: 16 }} level={5}>
                Responsáveis do negócio
              </Title>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={10}>
              <Form.Item
                label="Referenciador"
                name="referrer_id"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select
                  placeholder="Referenciador"
                  size="large"
                  loading={userLoading}
                  disabled
                >
                  {userList &&
                    userList.map((value: any) => (
                      <Select.Option key={value.id} value={value.id}>
                        {value.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Comissão total"
                name="referrer_commission"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <InputNumber
                  size="large"
                  addonAfter={<EuroOutlined />}
                  style={{ width: '100%' }}
                  formatter={formatPrice}
                  parser={parseCurrency}
                  maxLength={8}
                  min={0}
                  placeholder="Valor da comissão"
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Tipo de pagamento"
                name="referrer_commission_method"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select placeholder="Tipo de pagamento" size="large">
                  {Object.keys(EnumPaymentType)
                    .slice(0, -1)
                    .map((key) => (
                      <Select.Option key={key} value={key}>
                        {EnumPaymentType[key as keyof typeof EnumPaymentType]}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={10}>
              <Form.Item label="Closer" name="closer_id">
                <Select placeholder="Closer" size="large" loading={userLoading}>
                  {userList &&
                    userList.map((value: any) => (
                      <Select.Option key={value.id} value={value.id}>
                        {value.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Comissão total"
                name="closer_commission"
                rules={[
                  ({ getFieldValue }) => ({
                    required: !!getFieldValue('closer_id'),
                    message: 'Campo obrigatório',
                  }),
                  { validator: validateCurrency },
                ]}
              >
                <InputNumber
                  size="large"
                  addonAfter={<EuroOutlined />}
                  style={{ width: '100%' }}
                  formatter={formatPrice}
                  parser={parseCurrency}
                  maxLength={8}
                  min={0}
                  placeholder="Valor da comissão"
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Tipo de pagamento"
                name="closer_commission_method"
                rules={[
                  ({ getFieldValue }) => ({
                    required: !!getFieldValue('closer_id'),
                    message: 'Campo obrigatório',
                  }),
                ]}
              >
                <Select placeholder="Tipo de pagamento" size="large">
                  {Object.keys(EnumPaymentType)
                    .slice(0, -1)
                    .map((key) => (
                      <Select.Option key={key} value={key}>
                        {EnumPaymentType[key as keyof typeof EnumPaymentType]}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={10}>
              <Form.Item label="Business coach" name="coach_id">
                <Select
                  placeholder="Business coach"
                  size="large"
                  loading={userLoading}
                >
                  {userList &&
                    userList.map((value: any) => (
                      <Select.Option key={value.id} value={value.id}>
                        {value.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Comissão total"
                name="coach_commission"
                rules={[
                  ({ getFieldValue }) => ({
                    required: !!getFieldValue('coach_id'),
                    message: 'Campo obrigatório',
                  }),
                  { validator: validateCurrency },
                ]}
              >
                <InputNumber
                  size="large"
                  addonAfter={<EuroOutlined />}
                  style={{ width: '100%' }}
                  formatter={formatPrice}
                  parser={parseCurrency}
                  maxLength={8}
                  min={0}
                  placeholder="Valor da comissão"
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label="Tipo de pagamento"
                name="coach_commission_method"
                rules={[
                  ({ getFieldValue }) => ({
                    required: !!getFieldValue('coach_id'),
                    message: 'Campo obrigatório',
                  }),
                ]}
              >
                <Select placeholder="Tipo de pagamento" size="large">
                  {Object.keys(EnumPaymentType)
                    .slice(0, -1)
                    .map((key) => (
                      <Select.Option key={key} value={key}>
                        {EnumPaymentType[key as keyof typeof EnumPaymentType]}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              offset={18}
              span={6}
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 16 }}
            >
              <Button
                type="default"
                size="large"
                onClick={() => setOpenModalCancel(true)}
              >
                Cancelar
              </Button>
              <Button
                type="primary"
                size="large"
                disabled={!isSubmit}
                loading={isLoadingStore}
                onClick={save}
              >
                Gravar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <ConfirmModal
        open={openModalCancel}
        title="Tem certeza que deseja sair sem gravar as alterações?"
        content="Toda a informação não gravada será perdida."
        okText="Sair"
        cancelText="Cancelar"
        onOk={goToBoard}
        onCancel={() => setOpenModalCancel(false)}
      ></ConfirmModal>
    </div>
  );
}

import { ExportOutlined, FilterFilled } from "@ant-design/icons";
import { Button, DatePicker, Form, Popover, Space, Tag, Typography } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import { useState } from "react";
import { downloadExcel } from "../../services/utils";

const { Title } = Typography;
export default function TopFilter({ filterContent, data, tableName, title, params }: any) {
    const [openFilter, setOpenFilter] = useState<boolean>(false);

    const labels = {
        businessCoach: 'Business Coach',
        client: 'Cliente',
        closer: 'Closer',
        referrer: 'Referenciador',
    }

    const exportData = () => downloadExcel(data, tableName);

    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Title level={4} style={{ margin: 0 }}>{title}</Title>
            </div>
            <div className="dashboard_filters">
                <Space direction="vertical">
                    <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type="default" size="large" onClick={() => exportData()}
                            disabled={!(data.length)}
                            style={{ borderWidth: "1px", borderColor: '#0E3441' }}
                        ><ExportOutlined /> Exportar</Button>

                        <Popover
                            content={filterContent}
                            trigger="click"
                            open={openFilter}
                            onOpenChange={() => setOpenFilter(!openFilter)}
                            placement="bottomRight"
                        >
                            <Button type="primary" size="large">
                                <FilterFilled />Filtro
                            </Button>
                        </Popover>
                    </Space>

                    <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {Object.entries(params)
                            .filter(([key, value]) => ['businessCoach', 'client', 'closer', 'referrer'].includes(key) && value)
                            .map(([key, value]) => (<Tag>{`${labels[key as keyof typeof labels]} ${value}`}</Tag>))}

                        {params?.startDate && params?.endDate &&
                            <Tag>{`${params?.startDate} - ${params?.endDate}`}</Tag>
                        }
                    </Space>
                </Space>
            </div>
        </div>
    );
}
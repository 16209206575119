import {
  Alert,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { cutLongText } from '../../services/utils';
import { useQuery } from 'react-query';
import { paymentsDetails } from '../../services/business.service';
import { EnumProfile } from '../../Enums/Profile.enums';

const { Title } = Typography;

export default function DetailComponent({
  payment,
  open,
  close,
  title,
  readOnly,
}: any) {
  const [formPayment] = Form.useForm();
  const [listPayments, setListPayments] = useState<any | null>(null);
  const [total, setTotal] = useState<any | null>(null);

  const { isLoading: listLoading } = useQuery(
    ['payments'],
    () => paymentsDetails(payment.id),
    {
      onSuccess: (data) => {
        let users: any = [];
        let sum: any = 0;
        data.data.map((user: any, key: number) => {
          users.push({
            ...user,
            key: key,
          });
          sum = parseFloat(sum) + parseFloat(user.value);
        });
        setListPayments(users);
        setTotal(sum);
      },
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (payment) {
      const totalValue = parseFloat(total).toLocaleString('pt-PT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
      });

      formPayment.setFieldsValue({
        ...{
          name: payment.business.name,
          total: payment.value,
          status: payment.business.closed_state,
          month_payment: payment.month_payment_date,
          status_payment: payment.paid_at
            ? `Pago em ${payment.paid_at}`
            : 'Pendente',
        },
      });
    }
  }, [payment, total]);

  const columns = [
    {
      title: 'Responsáveis',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, record: any) => cutLongText(record.user.name, 40),
    },
    {
      title: 'Função no negócio',
      dataIndex: 'function',
      key: 'function',
      render: (_: any, record: any) => (
        <div>{EnumProfile[record.responsible as keyof typeof EnumProfile]}</div>
      ),
    },
    {
      title: 'Mês do pagamento',
      dataIndex: 'month',
      key: 'month',
      render: (_: any, record: any) => (
        <div style={{ textTransform: 'capitalize' }}>
          {record.month_payment_date}
        </div>
      ),
    },
    {
      title: 'Status da comissão',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: any) => (
        <div>{record.paid_at ? `Pago em ${record.paid_at}` : 'Pendente'}</div>
      ),
    },
    {
      title: 'Valor comissão',
      dataIndex: 'value',
      key: 'value',
      render: (_: any, record: any) => (
        <div>
          {parseFloat(record.value).toLocaleString('pt-PT', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
          })}
        </div>
      ),
    },
  ];

  return (
    <Modal
      title={title}
      centered
      open={open}
      onCancel={close}
      destroyOnClose={true}
      width={1000}
      footer={null}
    >
      <Divider />
      <Form form={formPayment} layout="vertical">
        <Row gutter={8}>
          <Col span={24}>
            <Title style={{ margin: '0px 0px 16px 0px' }} level={5}>
              Informações do negócio
            </Title>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item label="Nome" name="name">
              <Input
                readOnly={readOnly}
                size="large"
                maxLength={255}
                placeholder="Nome"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Mês do pagamento" name="month_payment">
              <Input
                style={{ textTransform: 'capitalize' }}
                readOnly={readOnly}
                size="large"
                maxLength={255}
                placeholder="Mês do pagamento"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Status do pagamento" name="status_payment">
              <Input readOnly={readOnly} size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={8}>
            <Form.Item label="Valor potencial à receber" name="total">
              <Input
                readOnly={readOnly}
                size="large"
                maxLength={11}
                addonAfter="€"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Status do negócio" name="status">
              <Input
                style={{ textTransform: 'capitalize' }}
                readOnly={readOnly}
                size="large"
                maxLength={255}
                placeholder="Status do negócio"
              />
            </Form.Item>
          </Col>
        </Row>
        <Divider />

        <Row gutter={8}>
          <Col span={24}>
            <Table
              dataSource={listPayments}
              columns={columns}
              pagination={false}
              loading={listLoading}
              summary={() => (
                <Table.Summary fixed={'bottom'}>
                  <Table.Summary.Row>
                    <Table.Summary.Cell index={4} colSpan={4} align="right">
                      <b>Total</b>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} className="bg-blue">
                      {parseFloat(total).toLocaleString('pt-PT', {
                        style: 'currency',
                        currency: 'EUR',
                        minimumFractionDigits: 2,
                      })}
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </Table.Summary>
              )}
            />
          </Col>
        </Row>

        <Row gutter={8}>
          <Col span={24}>
            <Alert
              message="Para que seja realizado o pagamento, os negócios têm que ser fechados e pagos."
              type="warning"
              banner
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}

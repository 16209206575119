import { Button, Card, Divider, Form, Space, Input, Tooltip, Switch } from "antd";
import DisplayAlert from "../../../components/Commons/Alert";
import GenericTable from "../../../components/Commons/Table/generic-table.component";
import { useState, useEffect } from "react";
import {
  CloseCircleFilled,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { getAll, remove } from "../../../services/profiles.service";
import { AlertService } from "../../../services/alert.service";
import ConfirmModal from "../../../components/Commons/Modal/confirm-modal.component";
import { PaginationProps } from "antd/lib";
import ShowDataLength from "../../../components/Commons/Table/results-length-table.component";

const { Search } = Input;

export default function ListProfilePage(props: any) {
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);
  const [perPage, setPerPage] = useState<number | undefined>(10);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<string>('');
  const [formSearch] = Form.useForm();
  const [profileSelected, setProfileSelected] = useState<string | null>(null)

  const [removeProfileId, setRemoveProfileId] = useState<number | undefined>(
    undefined
  );
  const [warnRemoveProfileId, setWarnRemoveProfileId] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const toPage = (page: string, profile_id: number = 0) => {
    let pages = {
      create: "/manager/profiles_privileges/create",
      edit: `/manager/profiles_privileges/${profile_id}/edit`,
      show: `/manager/profiles_privileges/${profile_id}/show`,
    };

    navigate(pages[page as keyof typeof pages]);
  };

  const removeProfile = (record: any) => {
    if (record.total_users > 0) {
      setWarnRemoveProfileId(true);
      setProfileSelected(record.role)
    } else {
      setRemoveProfileId(record.id);
    }
  };

  const columns = [
    {
      title: "Perfil",
      dataIndex: "description",
      sorter: false,
      key: "description",
    },
    {
      title: "Utilizadores atribuídos",
      dataIndex: "total_users",
      sorter: false,
      key: "total_users",
    },
    {
      title: "Status",
      dataIndex: "active",
      sorter: false,
      key: "status",
      render: (value: any) => (
        <span>{value == true ? "Ativo" : "Inativo"}</span>
      ),
    },
    {
      title: "Ações",
      key: "action",
      render: (_: any, record: any) => (
        <div style={{ display: 'flex', justifyContent: 'end', gap: 4 }}>
          <Tooltip placement="top" title="Visualizar">
            <Button
              type="default"
              shape="circle"
              size="middle"
              icon={<EyeOutlined />}
              onClick={() => toPage("show", record.id)}
            />
          </Tooltip>
          {record.role !== 'admin' && <Tooltip placement="top" title="Editar">
            <Button
              type="default"
              shape="circle"
              size="middle"
              icon={<EditOutlined />}
              onClick={() => toPage("edit", record.id)}
            />
          </Tooltip>
          }

          {!record.readonly && (
            <Tooltip placement="top" title="Apagar">
              <Button
                type="default"
                shape="circle"
                size="middle"
                icon={<DeleteOutlined />}
                onClick={() => removeProfile(record)}
              />
            </Tooltip>
          )}
        </div>
      ),
      width: 100,
    },
  ];

  const [profileList, setProfileList] = useState<
    { id: number; name: string }[]
  >([]);

  const {
    data: dataProfileList,
    refetch: RefetchProfileList,
    isLoading: profileLoading,
  } = useQuery(
    ["profileList", currentPage, search, perPage],
    () => getAll({
      page: currentPage,
      per_page: perPage,
      search,
    }), {
    refetchOnWindowFocus: false,
  });

  const { mutate: RemoveProfileMutate } = useMutation(remove, {
    onSuccess: () => {
      AlertService.sendAlert([{ text: "Perfil apagado com sucesso." }]);
      setRemoveProfileId(undefined);
      RefetchProfileList();
    },
  });

  useEffect(() => {
    if (dataProfileList) {
      setProfileList(
        dataProfileList.data.map((profile: any) => {
          return {
            key: profile.id,
            ...profile,
          };
        })
      );
      setTotal(dataProfileList.meta.total);
    }
  }, [dataProfileList]);

  const handleTableChange = (
    pagination: PaginationProps,
  ) => {
    if (pagination.current != currentPage) {
      setCurrentPage(pagination.current);
    }

    if (pagination.pageSize != perPage) {
      setPerPage(pagination.pageSize);
      setCurrentPage(1);
    }
  };

  const onSearch = (search_value: any) => {
    setCurrentPage(1);
    setSearch(search_value);
  };



  return (
    <>
      <DisplayAlert />
      <div className="content_page">
        <Card
          bodyStyle={{ padding: 8 }}
          headStyle={{
            paddingTop: 20,
            paddingBottom: 20,
            color: "#107B99",
            fontWeight: 600,
          }}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Form
              form={formSearch}
              name="search_user"
              layout="inline"
              autoComplete="off"
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Form.Item name="search_user">
                <Search
                  onSearch={onSearch}
                  placeholder="Pesquisar por nome do perfil"
                  enterButton="Pesquisar"
                  allowClear
                  size="large"
                  maxLength={255}
                />
              </Form.Item>
            </Form>
            <Button type="primary" size="large" onClick={() => toPage("create")}>
              Novo perfil
            </Button>
          </div>
          <Divider />
          <div className="profile_table">
            <GenericTable
              columns={columns}
              dataSource={profileList}
              loading={profileLoading}
              pagination={{
                current: currentPage,
                pageSize: perPage,
                total: total,
                showSizeChanger: true,
              }}
              totalChildren={
                <div className="zone_result_size_n_export" style={{ padding: 16 }}>
                  <ShowDataLength size={total} className="show_result_size" />
                </div>
              }
              handleTableChange={handleTableChange}
            />

            <ConfirmModal
              open={!!removeProfileId}
              title="Tem certeza que deseja apagar o perfil?"
              content="Nenhum utilizador será afetado."
              okText="Apagar"
              cancelText="Voltar"
              onOk={() => RemoveProfileMutate(removeProfileId)}
              onCancel={() => setRemoveProfileId(undefined)}
            ></ConfirmModal>

            <ConfirmModal
              open={!!warnRemoveProfileId}
              title="Não é possível apagar este perfil pois existem utilizadores associados à ele"
              content="Antes de continuar, aceda à secção de gestão de utilizadores para editar as associações."
              okText="Editar associações"
              cancelText="Voltar"
              icon={
                <CloseCircleFilled
                  style={{ color: "#f5222d", marginRight: 10, fontSize: 18 }}
                />
              }
              onOk={() => {
                setWarnRemoveProfileId(false);
                navigate(`/manager/users?role=${profileSelected}`);
              }}
              onCancel={() => setWarnRemoveProfileId(false)}
            ></ConfirmModal>
          </div>
        </Card>
      </div>
    </>
  );
}

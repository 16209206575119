import { Form, Input, Modal } from "antd";
import { useState } from "react";
import { useMutation } from "react-query";
import { authenticate } from "../../../services/user.service";
import { AlertService } from "../../../services/alert.service";

const { Password } = Input;

export default function ConfirmPasswordModal({ callback, open, onCancel, title, content }: any) {
    const [loading, setLoading] = useState<boolean>(false)
    const userLogged: any = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')!) : null;
    const [formPassword] = Form.useForm();

    const { mutate } = useMutation(authenticate, {
        onSuccess: () => {
            callback(true)
            setLoading(false)
            AlertService.clearAlert()
        },
        onError: (error) => {
            callback(false)
            setLoading(false)
        }
    });

    const handleOk = (event: any) => {
        event.preventDefault();

        formPassword.validateFields().then(() => {
            setLoading(true);
            mutate({
                email: userLogged.email,
                ...formPassword.getFieldsValue()
            })
        }).catch(() => {
            console.log('error')
            callback(false)
            setLoading(false)
        })
    }

    return (
        <Modal
            open={open}
            title={title}
            okText="Validar password"
            cancelText="Voltar"
            onCancel={() => onCancel()}
            onOk={(event) => handleOk(event)}
            confirmLoading={loading}
        >
            <Form
                form={formPassword}
                layout="vertical"
            >
                <p>
                    {!!content && <p style={{ marginLeft: 31 }}>{content}</p>}
                </p>
                <p>
                    <Form.Item
                        name="password"
                        label="Password"
                        required
                        rules={[{ required: true, message: "Campo obrigatório" }]}
                    >
                        <Password />
                    </Form.Item>
                </p>
            </Form>
        </Modal>
    )
}
import { Button, Col, Divider, Form, Input, Modal, Row, Typography } from "antd";
import { useEffect } from "react";
import { useMutation } from "react-query";
import { acceptRequestToChangeReferrer, rejectRequestToChangeReferrer } from "../../services/notification.service";

const { Title } = Typography;

const typeOfBusiness = [
    { id: 'diagnostic', description: 'Diagnóstico' },
    { id: 'business_club', description: 'Clube de Empresários' }
]

export default function AcceptRequestToChangeReferrerComponent({ client, open, close, title, readOnly, loading }: any) {

    const [formClient] = Form.useForm();

    useEffect(() => {
        if (client) {
            client.type_business = typeOfBusiness.find((type) => type.id === client.type_business)?.description;
            
            formClient.setFieldsValue(client);
        }
    }, [client, formClient]);

    const {
        mutate: mutateacceptRequest,
        isLoading: isLoadingacceptRequest
    } = useMutation(acceptRequestToChangeReferrer, {
        onSuccess: () => {
            close();
        }
    });

    const {
        mutate: mutateRejectRequest,
        isLoading: isLoadingRejectRequest
    } = useMutation(rejectRequestToChangeReferrer, {
        onSuccess: () => {
            close();
        }
    });

    const rejectRequestNewReferrer = () => {
        mutateRejectRequest({ request_id: client.request_id, notification_id: client.notification_id });
    }

    const acceptRequestNewReferrer = () => {
        mutateacceptRequest({ request_id: client.request_id, notification_id: client.notification_id });
    }

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={close}
            destroyOnClose={true}
            width={1000}
            footer={null}
        >
            <Divider />
            <Form
                form={formClient}
                layout="vertical"
            >
                <Row gutter={8}>
                    <Col span={24}>
                        <Title style={{ margin: '0px 0px 16px 0px' }} level={5}>Informações do Novo Referenciador </Title>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="Referenciador"
                            name="referencer_name"
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                                maxLength={100}
                                placeholder="Nome do referenciador"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="E-mail"
                            name="referencer_email"
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Contacto"
                            name="referencer_phone"
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24}>
                        <Title style={{ margin: '0px 0px 16px 0px' }} level={5}>Informações do Cliente</Title>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome"
                            name="name"
                            rules={[
                                { required: true, message: "Campo obrigatório" },
                            ]}
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                                maxLength={255}
                                placeholder="Nome do cliente"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Email"
                            name="email"
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                                placeholder="Email"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'right', gap: 8 }}>
                        <Button onClick={() => rejectRequestNewReferrer()}
                            loading={
                                isLoadingacceptRequest ||
                                isLoadingRejectRequest ||
                                loading
                            }>Rejeitar</Button>
                        <Button type="primary" onClick={() => acceptRequestNewReferrer()}
                            loading={
                                isLoadingacceptRequest ||
                                isLoadingRejectRequest ||
                                loading
                            }>Aprovar</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
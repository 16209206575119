import {
  AutoComplete,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertService } from '../../services/alert.service';
import { useMutation, useQuery } from 'react-query';
import {
  deleteClient,
  getOneById,
  getOneByIdOnlyBusinessOpen,
  list,
} from '../../services/clients.service';
import DisplayAlert from '../../components/Commons/Alert';
import ConfirmModal from '../../components/Commons/Modal/confirm-modal.component';
import { EuroOutlined } from '@ant-design/icons';
import { getLabel } from '../../Enums/TypeOfKanban.enums';
import {
  cancelOneBusiness,
  changeClient,
  closeOneBusiness,
} from '../../services/business.service';

const { Title, Text } = Typography;

export default function DeleteClientPage(props: any) {
  const [formClient] = Form.useForm();
  const [formBusinessOne] = Form.useForm();
  const [formBusinessTwo] = Form.useForm();
  const userLogged: any = localStorage.getItem('user');
  const [user, setUser] = useState(
    localStorage.getItem('user') ? JSON.parse(userLogged) : null
  );
  const [openChangeClient, setChangeClient] = useState<number | null>(null);
  const [businesses, setBusinesses] = useState<any>([]);
  const [clientList, setClientList] = useState([]);
  const [clients, setClient] = useState([]);
  const [clientSelected, setClientSelected] = useState<any>();
  const [openModalDelete, setOpenModalDelete] = useState<number | null>(null);

  const { id, administrative } = useParams();

  const prepareClientForm = (client: any) => {
    client.data.referencer = client?.data?.referencer?.name ?? user?.name;
    const { data } = client;

    setBusinesses(data.business);
    if (!data.business.length) {
      setOpenModalDelete(data.id);
    }

    formClient.setFieldsValue(data);
  };

  useQuery(
    'clientList',
    () =>
      list({
        page: undefined,
        perPage: undefined,
        search: undefined,
        sort: undefined,
        order: undefined,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (clients) => {
        const clientsList = clients.data.map((client: any) => {
          return {
            label: client.data.name,
            value: client.data.name,
            client: client.data,
            id: client.data.id,
            key: client.data.id,
          };
        });

        setClientList(clientsList);
        setClient(clientsList);
      },
    }
  );

  const { refetch, isLoading: isLoadingProfile } = useQuery(
    ['loadProfile', id],
    () => (id ? getOneByIdOnlyBusinessOpen(Number(id)) : null),
    {
      enabled: !!id,
      onSuccess: prepareClientForm,
      refetchOnWindowFocus: false,
    }
  );

  const {
    mutate: mutateDeleteClient,
    isSuccess: isSuccessdelete,
    isError: isErrordelete,
  } = useMutation(deleteClient);

  useEffect(() => {
    if (isErrordelete || isSuccessdelete) setOpenModalDelete(null);

    if (isSuccessdelete) {
      AlertService.sendAlert([
        {
          text: 'Cliente apagado com sucesso.',
          nextPage: true,
        },
      ]);

      if (administrative) {
        navigate('/manager/administrative');
      } else {
        goToClients();
      }
    }
  }, [isSuccessdelete, isErrordelete]);

  const navigate = useNavigate();

  const goToClients = () => {
    navigate('/clients');
  };

  const { mutate: closeBusinessMutation, isLoading: isLoadingClose } =
    useMutation(closeOneBusiness, {
      onSuccess: () => {
        refetch();
        setChangeClient(null);
        setClientSelected(undefined);
      },
    });

  const closeBusiness = (id: number) => {
    closeBusinessMutation({ id });
  };

  const formatPrice: any = (price: any) => {
    return Math.round(price * 100) / 100;
  };

  const parseCurrency: any = (value: any) => {
    const parsedValue = parseFloat(value.replace(/[^\d.-]/g, ''));

    if (isNaN(parsedValue)) {
      return null;
    }
    return parsedValue;
  };

  const formatDate = (date: any) => {
    return new Date(date).toLocaleDateString('pt-PT');
  };

  const getSearchClient = (searchText: string) => {
    return searchText.length > 2
      ? clientList.filter(
          (option: any) =>
            option.client.name
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1 ||
            option.client.email
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1 ||
            option.client.phone
              .toLowerCase()
              .indexOf(searchText.toLowerCase()) !== -1
        )
      : clientList;
  };

  const onSelectClient = (data: any, option: any) => {
    setClientSelected(option.id);
  };

  const { mutate: saveChangeClient, isLoading: loadingChangeClient } =
    useMutation(changeClient, {
      onSuccess: (data: any) => {
        refetch();
        setChangeClient(null);
        setClientSelected(undefined);
      },
    });

  const saveNewClientToBusiness = (businessId: number, key: number) => {
    key === 0 ? formBusinessOne.resetFields() : formBusinessTwo.resetFields();

    saveChangeClient({ businessId, clientSelected });
  };

  return (
    <div className="content_page">
      <DisplayAlert />
      <Card>
        <Row gutter={8}>
          <Col span={24}>
            <Title
              className={'title_page'}
              style={{ marginTop: 0, marginBottom: 0 }}
              level={5}
            >
              Apagar cliente
            </Title>
          </Col>
        </Row>
        <Divider />
        <Form form={formClient} layout="vertical">
          <Row gutter={8}>
            <Col span={24}>
              <Title style={{ marginTop: 0, marginBottom: 16 }} level={5}>
                Informações do cliente
              </Title>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item label="Nome" name="name">
                <Input
                  readOnly
                  size="large"
                  maxLength={255}
                  placeholder="Nome do cliente"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="E-mail" name="email">
                <Input
                  readOnly
                  size="large"
                  maxLength={255}
                  placeholder="E-mail"
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Contacto" name="phone">
                <InputNumber
                  readOnly
                  size="large"
                  style={{ width: '100%' }}
                  maxLength={11}
                  parser={(value) => `${value}`.replace(/[^0-9]/g, '')}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
      {!!businesses.length && (
        <Row>
          <Col span={24}>
            <Title style={{ marginBottom: 16 }} level={5}>
              Negócios associados
            </Title>
          </Col>
        </Row>
      )}
      {businesses.map((business: any, key: number) => (
        <Card style={{ marginBottom: 16 }}>
          <Row gutter={8}>
            <Col span={16}>
              <Title
                className={'title_page'}
                style={{ marginTop: 0, marginBottom: 0 }}
                level={5}
              >
                Detalhes do negócio
              </Title>
            </Col>
            <Col
              span={8}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Text>Data de criação: {formatDate(business.created_at)}</Text>
            </Col>
          </Row>
          <Divider />
          <Form
            form={key === 0 ? formBusinessOne : formBusinessTwo}
            layout="vertical"
            initialValues={{
              name: business.name,
              value: business.value,
              product: business?.product?.name,
              business_kanban: getLabel(business.business_kanban.type),
              stage: business.stage.name,
              business_status: business.state_business,
            }}
          >
            <Row gutter={8}>
              <Col span={10}>
                <Form.Item label="Nome do negócio" name="name">
                  <Input
                    size="large"
                    maxLength={255}
                    placeholder="Nome do negócio"
                    readOnly
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="Valor total" name="value">
                  <InputNumber
                    size="large"
                    addonAfter={<EuroOutlined />}
                    style={{ width: '100%' }}
                    formatter={formatPrice}
                    parser={parseCurrency}
                    maxLength={8}
                    min={0}
                    placeholder="Valor total"
                    readOnly
                  />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="Produto" name="product">
                  <Input
                    size="large"
                    maxLength={255}
                    placeholder="Produto"
                    readOnly
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={8}>
              <Col span={10}>
                <Form.Item label="Tipo de funil" name="business_kanban">
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="Etapa" name="stage">
                  <Input size="large" readOnly />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label="Status do negócio" name="business_status">
                  <Input
                    size="large"
                    maxLength={255}
                    placeholder="Status do negócio"
                    readOnly
                    style={{ textTransform: 'capitalize' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {business.id !== openChangeClient && (
              <Row>
                <Col
                  offset={18}
                  span={6}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 16,
                  }}
                >
                  <Button
                    type="default"
                    size="large"
                    danger
                    loading={isLoadingClose}
                    onClick={() => closeBusiness(business.id)}
                  >
                    Fechar negócio
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      setChangeClient(business.id);
                      setClientSelected(undefined);
                      key === 0
                        ? formBusinessOne.resetFields()
                        : formBusinessTwo.resetFields();
                    }}
                    style={{ background: '#428B93' }}
                  >
                    Associar à outro cliente
                  </Button>
                </Col>
              </Row>
            )}

            {business.id === openChangeClient && (
              <Row>
                <Col
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: 16,
                  }}
                >
                  <Form.Item
                    label="Cliente"
                    name="client"
                    tooltip={
                      'O cliente só pode ser associado ao negócio se já for cadastrado no sistema'
                    }
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                  >
                    <AutoComplete
                      options={clients}
                      onSelect={(data, option) => onSelectClient(data, option)}
                      onSearch={(text) => setClient(getSearchClient(text))}
                      placeholder="Pesquise por nome, email ou telefone do cliente"
                      allowClear
                      size="large"
                      style={{
                        width: 400,
                      }}
                      onClear={() => {
                        setClientSelected(undefined);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        gap: 8,
                      }}
                    >
                      <Button
                        type="primary"
                        size="large"
                        disabled={!clientSelected}
                        loading={loadingChangeClient || isLoadingProfile}
                        onClick={() =>
                          saveNewClientToBusiness(business.id, key)
                        }
                      >
                        Associar à outro cliente
                      </Button>
                      <Button
                        type="default"
                        size="large"
                        onClick={() => {
                          setChangeClient(null);
                          setClientSelected(undefined);
                          key === 0
                            ? formBusinessOne.resetFields()
                            : formBusinessTwo.resetFields();
                        }}
                      >
                        Cancelar
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            )}
          </Form>
        </Card>
      ))}
      <ConfirmModal
        open={!!openModalDelete}
        title="Tem a certeza que deseja apagar o cliente?"
        content="Nenhum negócio será afetado"
        okText="Apagar"
        cancelText="Voltar"
        onOk={() =>
          openModalDelete
            ? mutateDeleteClient(openModalDelete)
            : setOpenModalDelete(null)
        }
        onCancel={() => setOpenModalDelete(null)}
      ></ConfirmModal>
    </div>
  );
}

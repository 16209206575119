import { useEffect, useState } from "react";
import { Layout as LayoutAnt, Menu } from "antd";
import DrawerSchedule from "../components/schedule/DrawerSchedule";
import ModalAlertEvent from "../components/schedule/ModalAlertEvent";
import { useNavigate } from "react-router-dom";
import { ControlOutlined, ExperimentOutlined, HomeOutlined, TeamOutlined, WalletOutlined } from "@ant-design/icons";

const { Sider } = LayoutAnt;

const routes = [
  {
    key: "dashboard",
    url: "/dashboard",
    icon: <HomeOutlined />,
    label: "Dashboard",
    permissions: [
      'dashboard-dashboard_all:have',
      'dashboard-dashboard_personal:have'
    ],
  },
  {
    key: "clients",
    url: "/clients",
    icon: <TeamOutlined />,
    label: "Clientes",
    permissions: [
      'clients-delete_client:have',
      'clients-edit_client:have',
      'clients-new_client:have',
      'clients-view_client:have'
    ],
  },
  {
    key: "business",
    url: "/business",
    icon: <ExperimentOutlined />,
    label: "Negócios",
    permissions: [
      'business-delete_business:have',
      'business-edit_business:have',
      'business-new_business:have',
      'business-new_follow_up:have',
      'business-view_business:have'
    ],
  },
  {
    key: "manager",
    url: "/manager",
    icon: <ControlOutlined />,
    label: "Gestão",
    disabled: false,
    children: [
      {
        key: "manager/profiles_privileges",
        label: "Perfil",
        url: '/manager/profiles_privileges',
        permissions: ['manager-profiles:read', 'manager-profiles:write']
      },
      {
        key: "manager/users",
        label: "Utilizadores",
        url: '/manager/users',
        permissions: ['manager-users:read', 'manager-users:write']
      },
      {
        key: "manager/payment",
        label: "Pagamentos",
        url: '/manager/payment',
        permissions: ['manager-payments:read', 'manager-payments:write']
      },
      {
        key: "manager/administrative",
        label: "Administrativa",
        url: '/manager/administrative',
        permissions: ['manager-administrative:read', 'manager-administrative:write']
      }
    ]
  },
  {
    key: "products",
    url: "/products",
    icon: <WalletOutlined />,
    label: "Produtos",
    permissions: [
      'products-delete_products:have',
      'products-edit_products:have',
      'products-new_products:have',
      'products-view_products:have'
    ],
  },
];

export default function SiderCustom({ modulePermissions, collapsed, setTitle }: any) {
  const navigate = useNavigate();

  const [currentKey, setCurrentKey]: any = useState(null);
  const [currentComponent, setCurrentComponent] = useState(null);
  const [oldCurrentKey, setOldCurrentKey] = useState(null);
  const [isOnlyModule, setIsOnlyModule] = useState(false);
  const [currentReminder, setCurrentReminder] = useState(null);
  const [authorizedRoutes, setAuthorizedRoutes] = useState<any[]>([]);

  const findRouteByPathname = (pathname: String, items: any) => {
    let routeFound = items.find((route: any) => pathname == route.url);

    if (!routeFound) {
      for (let route of items) {
        if (route.children && route.children.length) {
          routeFound = findRouteByPathname(pathname, route.children);

          if (routeFound) {
            routeFound.childLabel = [route.label, routeFound.label].join(' / ');
            return routeFound;
          }
        }
      }
    }

    return routeFound;
  }

  const filterAuthorizedRoutes = (routes: any[]) => {
    return routes.filter((route: any) => {
      if (route?.children?.length) {
        route.children = filterAuthorizedRoutes(route.children);

        if (route.children.length) {
          return route;
        }
      }

      if (route?.permissions?.some((permission: string) => modulePermissions.includes(permission))) {
        return route;
      }
    });
  };

  useEffect(() => {
    if (window.location.pathname) {
      let pathname = window.location.pathname;

      let splittedPathname = pathname.substring(1).split('/');

      if (splittedPathname.length > 2) {
        pathname = '/' + splittedPathname.slice(0, 2).join('/');
      }

      let routeFound = findRouteByPathname(pathname, authorizedRoutes);

      if (!window.location.pathname.includes('administrative')) {
        document.cookie = "adminsitrativa=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
      }

      if (routeFound) {
        setTitle(routeFound.childLabel || routeFound.label);
        delete routeFound.childLabel;
        setCurrentKey(routeFound.key);
      }
    }
  }, [window.location.pathname, authorizedRoutes]);

  useEffect(() => {
    if (modulePermissions.length) {
      if (Object.keys(modulePermissions).length === 1) {
        let [module, permission] = Object.keys(modulePermissions)[0].split('-');

        const onlyRoute: any = routes.find(route => route.key === module);

        if (onlyRoute) {
          setIsOnlyModule(true);
          setCurrentKey(onlyRoute.key);
          goToModule(onlyRoute.key);
        }
      } else {
        setAuthorizedRoutes(filterAuthorizedRoutes(routes));
      }
    }
  }, [modulePermissions]);

  const goToModule = (key: string) => {
    if (!window.location.href.includes(key)) {
      navigate(key);
    }
  };

  const closeSchedule = () => {
    setCurrentKey(oldCurrentKey);
    setCurrentComponent(null);
  };

  function MenuComponent({ routes, ...props }: any) {
    return (
      <Menu
        mode="inline"
        onClick={({ key }) => {
          let componentRoute = routes.find((route: any) => route.key === key);

          if (!componentRoute) {
            routes.forEach((route: any) => {
              if (route.children) {
                const childRoute = route.children.find((child: any) => child.key === key);

                if (childRoute) {
                  childRoute.childLabel = [route.label, childRoute.label].join(' / ');
                  componentRoute = childRoute;
                }
              }
            });
          }

          if (!componentRoute) return;

          setTitle(componentRoute.childLabel || componentRoute.label);
          delete componentRoute.childLabel;

          if (componentRoute?.component) {
            setCurrentComponent(componentRoute.component);
            setOldCurrentKey(currentKey);
            setCurrentKey(key);
            return;
          }

          setCurrentKey(key);
          navigate(key);
        }}
        defaultSelectedKeys={[currentKey]}
        defaultOpenKeys={[currentKey ? currentKey.split('/')[0] : currentKey]}
        items={routes.map((route: any) => {
          return {
            key: route.key,
            icon: route.icon,
            label: route.label,
            children: route.children,
            disabled: route.disabled
          };
        })}
      />
    );
  }

  const closeModalAlertEvent = () => {
    setCurrentReminder(null);
  };

  return (
    <>
      {!isOnlyModule && (
        <>
          <Sider
            collapsible
            collapsed={collapsed}
            trigger={null}
            style={{ background: "#FFFFFF", boxShadow: "2px 0px 6px #0000001A", zIndex: 99 }}
          >
            {!collapsed ? (
              <a href="/" className="nav-link">
                <img
                  src="/logo-uni.svg"
                  className="client_logo"
                  alt="logo"
                />
              </a>
            ) : (
              <div style={{ marginTop: 69 }}></div>
            )}

            <MenuComponent routes={authorizedRoutes} />
          </Sider>
          <DrawerSchedule
            open={currentComponent === "DrawerSchedule"}
            onclose={closeSchedule}
          />
          <ModalAlertEvent
            open={currentReminder !== null}
            event={currentReminder}
            close={closeModalAlertEvent}
          />
        </>
      )}
    </>
  );
}

import GenericTable from "../../components/Commons/Table/generic-table.component";
import ShowDataLength from "../../components/Commons/Table/results-length-table.component";
import { useEffect, useState } from "react";
import { PaginationProps } from "antd/lib";
import { FilterValue } from "antd/es/table/interface";
import { useMutation, useQuery } from "react-query";
import { getProducts, deleteProduct } from "../../services/products.service";
import { Button, Space, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../components/Commons/Modal/confirm-modal.component";
import { AlertService } from "../../services/alert.service";
import { cutLongText } from "../../services/utils";

export default function ProductsTable() {
    const [currentPage, setCurrentPage] = useState<number | undefined>(1);
    const [perPage, setPerPage] = useState<number | undefined>(10);
    const [search, setSearch] = useState<string | undefined>(undefined);
    const [columnSort, setColumnSort] = useState<string | undefined>(undefined);
    const [columnOrder, setColumnOrder] = useState<string | undefined>(undefined);
    const [productList, setProductList] = useState<any[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [openModalDelete, setOpenModalDelete] = useState<number | null>(null);

    const navigate = useNavigate();

    const { isLoading, refetch: refetchList } = useQuery(
        ["products", { page: currentPage, perPage, search, columnSort, columnOrder }],
        () => getProducts({ page: currentPage, perPage, search, sort: columnSort, order: columnOrder }),
        {
            onSuccess: (data) => {
                setProductList(data.data.map((product: any, key: number) => {
                    return {
                        ...product,
                        key: key,
                    };
                }));
                setTotal(data.total);
                setPerPage(data.per_page);
            },
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        }
    );

    const {
        mutate: mutateDeleteProduct,
        isSuccess: isSuccessdelete,
        isError: isErrordelete,
    } = useMutation(deleteProduct);

    const goToEditForm = (id: number) => {
        navigate("/products/edit/" + id);
    };

    const columns = [
        { title: "Nome", dataIndex: "name", sorter: true, key: "name" },
        { title: "Valor referencial", dataIndex: "value", sorter: true, key: "value", render: (_: any, record: any) => cutLongText(record.value, 9)},
        { title: "Comissão", dataIndex: "commission", sorter: true, key: "commission" , render: (_: any, record: any) => cutLongText(record.commission, 9)},
        {
            title: "Status",
            dataIndex: "status",
            sorter: true,
            key: "status",
            render: (value: any) => (
                <span>{value == true ? "Ativo" : "Inativo"}</span>
            ),
        },
        {
            title: "Ações",
            key: "action",
            width: "15%",
            render: (_: any, record: any) => (
                <Space size="small">
                    <Tooltip placement="top" title="Editar">
                        <Button
                            type="default"
                            shape="circle"
                            size="middle"
                            icon={<EditOutlined />}
                            onClick={() => goToEditForm(record.id)}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="Apagar">
                        <Button
                            type="default"
                            shape="circle"
                            size="middle"
                            icon={<DeleteOutlined />}
                            onClick={() => setOpenModalDelete(record.id)}
                        />
                    </Tooltip>
                </Space>
            ),
        }
    ];

    const handleTableChange = (
        pagination: PaginationProps,
        filters: Record<string, FilterValue | null>,
        sorter: any,
    ) => {
        if (pagination.current != currentPage) {
            setCurrentPage(pagination.current);
        }

        if (pagination.pageSize != perPage) {
            setPerPage(pagination.pageSize);
        }

        if (sorter.order) {
            setColumnSort(sorter.field);
            setColumnOrder(sorter.order);
        }
    };

    useEffect(() => {
        if (isErrordelete || isSuccessdelete)
            setOpenModalDelete(null);

        if (isSuccessdelete) {
            refetchList();
            AlertService.sendAlert([
                {
                    text: "Produto apagado com sucesso.",
                    nextPage: false,
                },
            ]);
        }
    }, [isSuccessdelete, isErrordelete]);

    return (
        <>
            <GenericTable
                columns={columns}
                dataSource={productList}
                pagination={{
                    current: currentPage,
                    pageSize: perPage,
                    total: total,
                    showSizeChanger: true,
                    onShowSizeChange: (current, size) => {
                        setPerPage(size);
                    }
                }}
                loading={false}
                totalChildren={
                    <div className="zone_result_size_n_export">
                        <ShowDataLength size={total} className="show_result_size" />
                    </div>
                }
                empty={{
                    createTheFirst: !productList.length && !search,
                    textOne: "Esta tabela encontra-se vazia, uma vez que ainda não foram criado produtos.",
                    textTwo: "Para começar, crie um novo produto.",
                    link: "/products/new",
                }}
                handleTableChange={handleTableChange}
            />
            <ConfirmModal
                open={!!openModalDelete}
                title="Tem a certeza que deseja apagar o produto?"
                okText="Apagar"
                cancelText="Voltar"
                onOk={() => openModalDelete ? mutateDeleteProduct(openModalDelete) : setOpenModalDelete(null)}
                onCancel={() => setOpenModalDelete(null)}
            ></ConfirmModal>
        </>
    )
}
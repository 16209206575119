import React from 'react';
import { Button, Col, ColorPicker, Form, Input, Modal, Row, Typography } from 'antd';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

interface ModalEditColumnProps {
    onOk: (form: any) => void;
    onCancel: () => void;
    open: boolean;
    loading: boolean;
    defaultShowColors?: boolean;
    text?: string;
    description?: string;
    okText?: string;
    cancelText?: string;
    data: { title: string; color: string };
    colorsInUse?: string[];
}

export default function ModalEditColumn({
    onOk,
    onCancel,
    open,
    loading,
    text = '',
    okText = 'Sim',
    cancelText = 'Não',
    defaultShowColors = false,
    data,
    colorsInUse,
}: ModalEditColumnProps) {
    const [form] = Form.useForm();

    const [editColor, setEditColor] = React.useState(defaultShowColors);

    const colors = [
        '#141414', '#545454', '#737373', '#A6A6A6',
        '#D9D9D9', '#FFFFFF', '#FF3131', '#FF5757',
        '#FF66C4', '#CB6CE6', '#8C52FF', '#5E17EB',
        '#0097B2', '#0CC0DF', '#5CE1E6', '#38B6FF',
        '#5271FF', '#004AAD', '#00BF63', '#7ED957',
        '#C1FF72', '#FFDE59', '#FFBD59', '#FF914D',
    ]

    const sendForm = () => {
        form.validateFields().then(() => {
            return onOk(form.getFieldsValue());
        }).catch((error) => {
            return error;
        });
    }

    return (
        <Modal
            title={
                <Title level={4}>
                    <InfoCircleOutlined style={{ color: '#faad14', marginRight: 10 }} />
                    {text}
                </Title>
            }
            open={open}
            onOk={sendForm}
            cancelText={cancelText}
            okButtonProps={{ size: 'large', loading }}
            cancelButtonProps={{ size: 'large' }}
            onCancel={onCancel}
        >
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                initialValues={data}
            >
                <Form.Item
                    label="Nome da etapa"
                    name="title"
                    rules={[{ required: true, message: 'Por favor, informe o nome da etapa', max: 35 }]}
                >
                    <Input maxLength={35} required />
                </Form.Item>

                {!editColor && <Form.Item
                    label="Cor da etapa"
                    rules={[{ required: true }]}
                >
                    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', padding: 8, border: '1px solid #D9D9D9', borderRadius: 8 }}>
                        <Form.Item
                            name="color"
                            rules={[{ required: true, message: 'Por favor, informe a cor da etapa', max: 30 }]}
                            style={{ marginBottom: 0 }}
                        >
                            <ColorPicker
                                size="large"
                                open={false}
                                showText />
                        </Form.Item>
                        <Button icon={<EditOutlined />} size='large' onClick={() => setEditColor(!editColor)}></Button>
                    </div>
                </Form.Item>}
                {editColor && <Form.Item
                    label="Escolha a cor para a etapa"
                >
                    <Row gutter={[16, 16]} style={{ display: 'flex', padding: 8, border: '1px solid #D9D9D9', borderRadius: 8 }}>
                        {colors.length > 0 && colors.map((color: string) =>
                            <Col span={4} onClick={() => {
                                if (!colorsInUse?.includes(color)) {
                                    form.setFieldsValue({ color });
                                    setEditColor(false);
                                }
                            }}>
                                <ColorPicker
                                    disabled={colorsInUse?.includes(color)}
                                    defaultValue={color}
                                    size="large"
                                    open={false}
                                />
                            </Col>
                        )}
                    </Row>
                </Form.Item>}
            </Form>
        </Modal >
    );
}
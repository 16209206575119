import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Form,
  Row,
  Tag,
  Typography,
} from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import {
  cancelOneBusiness,
  editOneBusiness,
  getBusinessId,
  reopenOneBusiness,
} from '../../../services/business.service';
import { useEffect, useState } from 'react';
import Client from './Client';
import Business from './Business';
import Notes from './Notes';
import TimeLine from './TimeLine';
import FollowUp from './FollowUp';
import { ArrowLeftOutlined, MoreOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd/lib';
import ConfirmModal from '../../../components/Commons/Modal/confirm-modal.component';
import { AlertService } from '../../../services/alert.service';
import DisplayAlert from '../../../components/Commons/Alert';
import { getAll } from '../../../services/users.service';
import LoadingComponent from '../../../components/Commons/Loading';

const { Title } = Typography;

export default function Details() {
  const [formClient] = Form.useForm();
  const [formBusiness] = Form.useForm();
  const [business, setBusiness] = useState<any>();
  const [historic, setHistoric] = useState<any>([]);
  const [responsibles, setResponsibles] = useState<any>([]);
  const [kanbanId, setKanbanId] = useState<undefined | any>();
  const userLogged: any = localStorage.getItem('user');
  const [user, setUser] = useState(
    localStorage.getItem('user') ? JSON.parse(userLogged) : null
  );
  const [isEdit, setIsEdit] = useState(false);
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const [propsModalCancel, setPropsModalCancel] = useState<any>({});
  const [userList, setUserList] = useState([]);
  const [showContent, setShowContent] = useState(false);

  const { id, edit } = useParams();
  const navigate = useNavigate();

  const { mutate: editBusinessMutation, isLoading: isLoadingEdit } =
    useMutation(editOneBusiness, {
      onSuccess: () => {
        refetchBusiness();
        AlertService.sendAlert([
          {
            text: 'Negócio atualizado com sucesso!',
            nextPage: false,
          },
        ]);
        setIsEdit(false);
      },
    });

  const { mutate: cancelBusinessMutation, isLoading: isLoadingCancel } =
    useMutation(cancelOneBusiness, {
      onSuccess: () => {
        refetchBusiness();
        setOpenModalCancel(false);
      },
    });

  const { mutate: reopenBusinessMutation } = useMutation(reopenOneBusiness, {
    onSuccess: () => {
      refetchBusiness();
      setOpenModalCancel(false);
    },
  });

  const { isLoading: userLoading } = useQuery(
    'userList',
    () => getAll({ status: 'active' }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (users) => {
        setUserList(users.data);
      },
    }
  );

  const {
    refetch: refetchBusiness,
    isLoading: isLoadingBusiness,
    isSuccess: isSucessBusiness,
  } = useQuery('businessId', () => getBusinessId(id), {
    refetchOnWindowFocus: false,
    onSuccess: (business) => {
      formClient.setFieldsValue(business.data.client);
      formBusiness.setFieldsValue(business.data);

      setBusiness(business.data);
      setHistoric(business?.data?.historic);
      setKanbanId(business.data.business_kanban_id);

      let responsibles = [];

      business.data?.referrer?.id && responsibles.push(business.data?.referrer);
      business.data?.coach?.id && responsibles.push(business.data?.coach);
      business.data?.closer?.id && responsibles.push(business.data?.closer);

      responsibles = responsibles.map((responsible) => {
        return {
          label: responsible.name,
          value: responsible.name,
          id: responsible.id,
          key: responsible.id,
        };
      });
      setResponsibles(responsibles);
      setShowContent(true);
    },
    enabled: userList.length > 0,
  });

  const editBusiness = () => {
    formBusiness
      .validateFields()
      .then(() => {
        editBusinessMutation({ id, data: formBusiness.getFieldsValue() });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const cancelBusiness = () => {
    setPropsModalCancel({
      title: 'Tem certeza que deseja cancelar este negócio?',
      content:
        'Ao cancelar este negócio ele será fechado automaticamente, e todos os pagamentos serão encerrados.',
      okText: 'Cancelar negócio',
      cancelText: 'Cancelar',
      onOk: () => cancelBusinessMutation({ id }),
    });
    setOpenModalCancel(true);
  };

  const reopenBusiness = () => {
    setPropsModalCancel({
      title: 'Tem certeza que deseja reabrir este negócio?',
      content: 'Ao reabrir este negócio ele será reaberto automaticamente.',
      okText: 'Reabrir negócio',
      cancelText: 'Cancelar',
      onOk: () => reopenBusinessMutation({ id }),
    });
    setOpenModalCancel(true);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => setIsEdit(true)}
        >
          Editar
        </a>
      ),
    },
    {
      key: '2',
      label: business?.state_business != 'fechado' &&
        !business?.canceled_at && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => cancelBusiness()}
          >
            Cancelar negócio
          </a>
        ),
      disabled: business?.state_business == 'fechado',
    },
    {
      key: '3',
      label: (business?.state_business == 'fechado' ||
        !!business?.canceled_at) && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => reopenBusiness()}
        >
          Reabrir negócio
        </a>
      ),
    },
  ];

  useEffect(() => {
    if (edit) {
      setIsEdit(true);
    }
  }, []);
  const handleCancelForm = () => {
    setIsEdit(false);
    setOpenModalCancel(false);
    formBusiness.setFieldsValue(business);
  }
  const cancelarEditar = () => {
    setPropsModalCancel({
      title: 'Tem a certeza que deseja sair sem guardar as alterações?',
      okText: 'Sair',
      cancelText: 'Voltar',
      onOk: () => {
        handleCancelForm()
      },
    });
    setOpenModalCancel(true);
  };

  return (
    <div className="content_page">
      <DisplayAlert />
      <Row gutter={8}>
        <Col span={24}>
          <Button
            type="text"
            className={'title_page'}
            style={{ marginBottom: 20 }}
            onClick={() => navigate('/business')}
          >
            <ArrowLeftOutlined /> Voltar para funil
          </Button>
        </Col>
      </Row>
      {!showContent ? (
        <Card
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '200px',
          }}
        >
          <LoadingComponent />
        </Card>
      ) : (
        <Card>
          <Row gutter={8}>
            <Col span={24}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: 16,
                  }}
                >
                  <Title
                    className={'title_page'}
                    style={{ marginTop: 0, marginBottom: 0 }}
                    level={5}
                  >
                    Detalhes do negócio
                  </Title>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                    }}
                  >
                    {business?.state_business == 'fechado' &&
                      !business?.canceled_at && (
                        <Tag color="#D9D9D9" style={{ color: '#000' }}>
                          Negócio fechado
                        </Tag>
                      )}
                    {business?.state_business == 'fechado' &&
                      business?.closed_state &&
                      (business?.closed_state === 'ganho' ||
                        business?.closed_state === 'perdido') && (
                        <Tag
                          color={
                            business?.closed_state === 'ganho'
                              ? '#D9F7BE'
                              : '#FFCCC7'
                          }
                          style={{ color: '#000', textTransform: 'capitalize' }}
                        >
                          {business?.closed_state === 'ganho'
                            ? 'Ganho'
                            : 'Perdido'}
                        </Tag>
                      )}
                    {!!business?.canceled_at && (
                      <Tag color="#FFE58F" style={{ color: '#000' }}>
                        Negócio cancelado
                      </Tag>
                    )}
                  </div>
                </div>
                <Dropdown menu={{ items }} placement="bottomRight" arrow>
                  <Button
                    type="primary"
                    shape="circle"
                    size="small"
                    icon={<MoreOutlined />}
                  />
                </Dropdown>
              </div>
            </Col>
          </Row>
          <Divider />

          <Row gutter={8}>
            <Col span={14}>
              <Client data={formClient} />

              <Business
                kanbanId={kanbanId}
                data={formBusiness}
                isEdit={isEdit}
                userList={userList}
                userLoading={userLoading}
              />

              <Notes data={user} id={id} />
            </Col>

            <Col span={10}>
              <TimeLine historic={historic} />

              <FollowUp data={user} id={id} responsibles={responsibles} />
            </Col>
          </Row>
          {isEdit && (
            <Row
              gutter={16}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Col offset={18}>
                <Button size="large" onClick={() => cancelarEditar()}>
                  Cancelar
                </Button>
              </Col>
              <Col>
                <Button
                  size="large"
                  type="primary"
                  loading={isLoadingEdit}
                  onClick={() => editBusiness()}
                >
                  Gravar
                </Button>
              </Col>
            </Row>
          )}
          <ConfirmModal
            open={openModalCancel}
            title={propsModalCancel.title}
            content={propsModalCancel.content}
            okText={propsModalCancel.okText}
            cancelText={propsModalCancel.cancelText}
            onOk={propsModalCancel.onOk}
            onCancel={() => setOpenModalCancel(false)}
          ></ConfirmModal>
        </Card>
      )}
    </div>
  );
}

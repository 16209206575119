import { useEffect, useState } from "react";
import GenericTable from "../Commons/Table/generic-table.component";
import { cutLongText } from "../../services/utils";
import ShowDataLength from "../Commons/Table/results-length-table.component";
import { Button, PaginationProps, Tooltip } from "antd";
import { FilterValue } from "antd/es/table/interface";
import { EnumTypeOfBusiness } from "../../Enums/TypeOfBusiness.enums";
import { getLabel } from "../../Enums/TypeOfKanban.enums";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export default function HistoryBoard({ history,
    currentPageValue,
    perPageValue,
    totalValue,
    setCurrentPageValue,
    setPerPageValue,
    setTotalValue
}: {
    history: any,
    currentPageValue: number | undefined,
    perPageValue: number | undefined,
    totalValue: number | undefined,
    setCurrentPageValue: any,
    setPerPageValue: any,
    setTotalValue: any
}) {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [columnSort, setColumnSort] = useState<string | undefined>(undefined);
    const [columnOrder, setColumnOrder] = useState<string | undefined>(undefined);

    const columns = [
        {
            title: "Nome do négocio",
            dataIndex: "name",
            key: "name",
            render: (_: any, record: any) => cutLongText(record.name, 40),
        },
        {
            title: "Organização",
            dataIndex: "organization",
            key: "organization",
            render: (_: any, record: any) => record?.client?.organization || '',
        },
        {
            title: "Status",
            dataIndex: "state",
            key: "state",
            render: (_: any, record: any) => record?.state_business ? record.state_business.charAt(0).toUpperCase() + record.state_business.slice(1) : '',
        },
        {
            title: "Tipo de funil",
            dataIndex: "type_business",
            key: "type_business",
            render: (_: any, record: any) => getLabel(record.business_kanban.type),
        },
        {
            title: "Data de fecho",
            dataIndex: "closed_at",
            key: "closed_at",
            render: (_: any, record: any) => record.closed_at
                ? new Date(record.closed_at).toLocaleString("pt-BR", { hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' })
                : "N/A",
        },
        {
            title: "Propriétário do Negócio",
            dataIndex: "client",
            key: "client",
            render: (_: any, record: any) => record?.client?.name || 'Este dado foi apagado devido a RGPD',
        },
        {
            title: "Ações",
            dataIndex: "action",
            key: "action",
            render: (_: any, record: any) => <Button
                type="default"
                shape="circle"
                size="large"
                onClick={() => navigate(`/business/details/${record.id}`)} >
                <Tooltip placement="top" title="Visualizar">
                    <EyeOutlined style={{ fontSize: 20 }} />
                </Tooltip>
            </Button >,
        },
    ];

    const handleTableChange = (
        pagination: PaginationProps,
        filters: Record<string, FilterValue | null>,
        sorter: any,
    ) => {
        if (pagination.current != currentPageValue) {
            setCurrentPageValue(pagination.current);
        }

        if (pagination.pageSize != perPageValue) {
            setPerPageValue(pagination.pageSize);
            setCurrentPageValue(1);
        }

        if (sorter.order) {
            setCurrentPageValue(1);
            setColumnSort(sorter.field);
            setColumnOrder(sorter.order);
        }
    };

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }, [history]);

    return (
        <GenericTable
            columns={columns}
            dataSource={history?.data?.content?.data}
            pagination={{
                current: currentPageValue,
                pageSize: perPageValue,
                total: totalValue,
                showSizeChanger: true,
                onShowSizeChange: (current, size) => {
                    setPerPageValue(size);
                }
            }}
            loading={isLoading}
            totalChildren={
                <div className="zone_result_size_n_export">
                    <ShowDataLength size={history?.data?.content?.total} className="show_result_size" />
                </div>
            }
            empty={{
                createTheFirst: !history.length,
                textOne: "Esta tabela encontra-se vazia, uma vez que ainda não temos negócios fechados.",
                textTwo: "Para começar, crie um novo negócio.",
                link: "/business/new",
            }}
            handleTableChange={handleTableChange}
        />
    )
};
import { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Form, Input, Layout as LayoutAnt, Modal, message } from "antd";
import SiderCustom from "./Sidercustom";
import HeaderCustom from "./Headercustom";
import FooterCustom from "./Footercustom";
import ContentCustom from "./Contentcustom";
import { changePassword, getUser } from "../services/user.service";
import { isDisabled } from "@testing-library/user-event/dist/utils";
import { AlertService } from "../services/alert.service";

const { Password } = Input;

export default function Layout() {
  const userStorage = localStorage.getItem("user") || null;
  const [collapsed, setCollapsed] = useState(false);
  const [user, setUser] = useState(
    userStorage ? JSON.parse(userStorage) : null
  );
  const [title, setTitle] = useState("");

  const [formEditPassUser] = Form.useForm();
  const [showChangePassword, setShowChangePassword] = useState<boolean>(
    userStorage ? JSON.parse(userStorage)?.first_access : false
  );

  const {
    data: dataUser,
    isSuccess: isSuccessDataUser,
    isError: isErrorDataUser,
  } = useQuery(["getUser"], getUser, {
    refetchOnWindowFocus: false,
    enabled: !user?.token || !user.canAccess.length,
  });

  const {
    mutate: mutateChangePassword,
    isLoading: isLoadingChangePassword
  } = useMutation(changePassword, {
    onSuccess: () => {
      setFirstAccessFalse();

      AlertService.sendAlert([
        {
          text: "A password foi editada com sucesso!",
          nextPage: false,
        }
      ])
    }
  })

  const setFirstAccessFalse = () => {
    localStorage.setItem("user", JSON.stringify({ ...user, first_access: false }));
    setUser({ ...user, first_access: false });
    setShowChangePassword(false);
  }

  const modalChangePassword = () => {
    // remover a negação para comitar o projeto.
    return (
      <Modal
        open={showChangePassword}
        title="Definir nova password"
        confirmLoading={isLoadingChangePassword}
        cancelButtonProps={
          { disabled: isLoadingChangePassword }
        }
        onOk={() => {
          formEditPassUser
            .validateFields()
            .then((values) => {
              mutateChangePassword({ id: user.id, data: values })
            })
            .catch((error) => {
              console.log("error: ", error);
            });
        }}
        onCancel={() => {
          setFirstAccessFalse()
        }}
      >
        <Form
          form={formEditPassUser}
          layout="vertical"
        >
          <Form.Item
            label="Nova password"
            required
            name="new_password"
            rules={[
              { required: true, message: "Campo obrigatorio" },
              {
                min: 8,
                message: "A password deve ter no mínimo 8 caracteres",
              },
              {
                pattern: new RegExp(
                  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
                ),
                message:
                  "A password deve ter letras maiúsculas, minúsculas, números e caracteres especiais",
              },
            ]}
          >
            <Password max={20} />
          </Form.Item>
          <Form.Item
            label="Confirmar nova password"
            required
            name="confirm_password"
            rules={[
              { required: true, message: "Campo obrigatorio" },
              {
                min: 8,
                message: "A password deve ter no mínimo 8 caracteres",
              },
              {
                pattern: new RegExp(
                  "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
                ),
                message:
                  "A password deve ter letras maiúsculas, minúsculas, números e caracteres especiais",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('O campo nova password e confirmar nova password estão com valores diferentes.');
                },
              }),
            ]}
          >
            <Password max={20} />
          </Form.Item>
        </Form>
      </Modal >
    )
  }

  const handlePermissions = () => {
    if (user.canAccess) {
      return user.canAccess.reduce((array: any, item: any) => {
        if (typeof item.permissions == 'string') {
          item.permissions = JSON.parse(item.permissions);
        }

        Object.entries(item.permissions || []).forEach(([key, value]) => array.push(`${item.module}-${key}:${value}`));

        return array;
      }, []);
    }

    return [];
  }

  useEffect(() => {
    if (isSuccessDataUser && dataUser?.user) {
      localStorage.setItem("user", JSON.stringify(dataUser.user));
      setUser(dataUser.user);
    }
  }, [isSuccessDataUser, dataUser]);

  useEffect(() => {
    if (isErrorDataUser) {
      localStorage.removeItem("user");
      window.location.replace("/login");
    }
  }, [isErrorDataUser]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      {user && (
        <LayoutAnt style={{ minHeight: "100vh" }}>
          <SiderCustom modulePermissions={handlePermissions()} collapsed={collapsed} setTitle={setTitle} />
          <LayoutAnt>
            <HeaderCustom
              user={user}
              collapsed={collapsed}
              toggleCollapsed={toggleCollapsed}
              title={title}
            />
            <ContentCustom modulePermissions={handlePermissions()} />
            <FooterCustom />
            {modalChangePassword()}
          </LayoutAnt>
        </LayoutAnt>
      )}
    </>
  );
}

import {
  EuroOutlined,
  InfoCircleFilled,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tooltip,
  Typography,
  Tag,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { EnumStateClosed } from '../../../Enums/StateBusiness.enums';
import { EnumPaymentType } from '../../../Enums/PaymentType.enums';
import { useQuery } from 'react-query';
import { getBusinessType } from '../../../services/business.service';
import { useEffect, useState } from 'react';
import { getAllProducts } from '../../../services/products.service';

const { Title, Text } = Typography;

export default function Business(props: any) {
  const [listBusinessType, setListBusinessType] = useState<undefined | any>();
  const [listBusinessStage, setListBusinessStage] = useState<undefined | any>();
  const [disabledFields, setDisabledFields] = useState<boolean>(false);
  const [showClosedState, setShowClosedState] = useState<boolean>(
    props?.data.getFieldValue('state_business') === 'fechado'
  );
  const [productList, setProductList] = useState([]);

  useEffect(() => {
    if (props?.data.getFieldValue('state_business') === 'fechado') {
      setShowClosedState(true);
      setDisabledFields(true);
    } else {
      setDisabledFields(false);
    }
  }, [props.data.getFieldValue('state_business')]);

  const { isLoading: isLoadingDataBusinessType } = useQuery(
    ['getBusinessKanban'],
    getBusinessType,
    {
      refetchOnWindowFocus: false,
      onSuccess: (list) => {
        setListBusinessType(list.data);
      },
    }
  );

  const formatPrice: any = (price: any) => {
    return price >= 0 ? Math.round(price * 100) / 100 : price;
  };

  const parseCurrency: any = (value: any) => {
    const parsedValue = parseFloat(value.replace(/[^\d.-]/g, ''));

    if (isNaN(parsedValue)) {
      return null;
    }
    return parsedValue;
  };

  useQuery(['listProducts'], () => getAllProducts({ status: 'active' }), {
    onSuccess: (data) => {
      if (data.length) setProductList(data);
    },
  });

  const changeStage = (id: number) => {
    let stages = listBusinessType.filter((value: any) => value.id === id)[0]
      ?.columns;
    setListBusinessStage(stages);
  };

  const onChangeStage = (value: any) => {
    const newStage = listBusinessStage.find((item: any) => item.id === value);

    if (newStage.is_last) {
      props.data.setFieldValue('state_business', 'fechado');
      setShowClosedState(true);
      setDisabledFields(true);
    } else {
      props.data.setFieldValue('state_business', 'aberto');
      props.data.setFieldValue('closed_state', null);
      setShowClosedState(false);
      setDisabledFields(false);
    }
  };

  useEffect(() => {
    if (listBusinessType && props.kanbanId) {
      let stages = listBusinessType.filter(
        (value: any) => value.id === props.kanbanId
      )[0]?.columns;

      setListBusinessStage(stages);
    }
  }, [listBusinessType, props.kanbanId]);

  const validateCurrency = (rule: any, value: any) => {
    if (value === undefined || value === null || value === '') {
      return Promise.resolve(); // Allow empty values
    }
    const currencyRegex = /^(?:0|[1-9]\d*)(?:\.\d{1,2})?$/; // Updated regex
    if (!currencyRegex.test(value.toString())) {
      return Promise.reject('Por favor, insira um valor de moeda válido');
    }
    return Promise.resolve();
  };

  return (
    <Form form={props.data} layout="vertical">
      <Card style={{ marginTop: 15 }}>
        <Row gutter={8}>
          <Col span={24}>
            <Title style={{ margin: '0px 0px 16px 0px' }} level={5}>
              Detalhes do Negócio
            </Title>
          </Col>
        </Row>
        <Divider />

        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              label="Nome do negócio"
              name="name"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Input
                size="large"
                maxLength={255}
                placeholder="Nome do negócio"
                disabled={!props.isEdit}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Valor total" name="value">
              <InputNumber
                size="large"
                addonAfter={<EuroOutlined />}
                style={{ width: '100%' }}
                formatter={formatPrice}
                parser={parseCurrency}
                maxLength={255}
                placeholder="Valor total"
                disabled={!props.isEdit}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              label="Produto"
              name="product_id"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                placeholder="Escolha um produto"
                size="large"
                disabled={!props.isEdit}
              >
                {productList &&
                  productList.map((value: any) => (
                    <Select.Option key={value.id} value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            {/* <Form.Item
                            label="Produto"
                            name="type_product"
                            rules={[
                                { required: true, message: "Campo obrigatório" },
                            ]}
                        >
                            <Input
                                size="large"
                                maxLength={255}
                                placeholder="Produto"
                                disabled={!props.isEdit}
                            />
                        </Form.Item> */}
          </Col>
          <Col span={12}>
            <Form.Item
              label="Tipo de funil"
              name="business_kanban_id"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                placeholder="Escolha um tipo de funil"
                size="large"
                loading={isLoadingDataBusinessType}
                disabled
                onChange={(value: any) => changeStage(value)}
              >
                {listBusinessType &&
                  listBusinessType.map((value: any) => (
                    <Select.Option key={value.id} value={value.id}>
                      {value.type === 'COACHING_BUSINESS_CLUB'
                        ? 'Clube de empresários'
                        : 'Diagnóstico'}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              label="Etapa"
              name="stage"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                placeholder="Escolha uma etapa"
                size="large"
                disabled={!props.isEdit}
                onChange={(value) => onChangeStage(value)}
              >
                {listBusinessStage &&
                  listBusinessStage.map((value: any) => (
                    <Select.Option key={value.id} value={value.id}>
                      <span style={{ color: value.color }}>⬤</span> {value.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Row gutter={8}>
              <Col span={10} style={{ display: 'none' }}>
                <Form.Item
                  label="Status do negócio"
                  name="state_business"
                  rules={[{ required: true, message: 'Campo obrigatório' }]}
                >
                  <Input
                    size="large"
                    maxLength={255}
                    placeholder="Status do negócio"
                    readOnly
                    disabled={!props.isEdit}
                  />
                </Form.Item>
              </Col>
              <Col span={14}>
                {showClosedState && (
                  <Form.Item
                    label="Status do negócio"
                    name="closed_state"
                    rules={[{ required: true, message: 'Campo obrigatório' }]}
                  >
                    <Select size="large" disabled={!props.isEdit}>
                      {Object.keys(EnumStateClosed).map((key) => (
                        <Select.Option key={key} value={key}>
                          {EnumStateClosed[key as keyof typeof EnumStateClosed]}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={24}>
            <Form.Item label="Descrição do negócio" name="description">
              <TextArea
                showCount
                size="large"
                rows={6}
                maxLength={600}
                placeholder="Descrição do negócio"
                disabled={!props.isEdit}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card style={{ marginTop: 15 }}>
        <Row gutter={8}>
          <Col span={24}>
            <Title style={{ marginTop: 0, marginBottom: 16 }} level={5}>
              Responsáveis do negócio
              <Tooltip title="Os pagamentos iniciarão no mês seguinte à mudança do negócio para o status 'Ganho'.">
                <InfoCircleOutlined style={{ marginLeft: 8 }} />
              </Tooltip>
            </Title>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={10}>
            <Form.Item
              label="Referenciador"
              name="referrer_id"
              rules={[{ required: true, message: 'Campo obrigatório' }]}
            >
              <Select
                placeholder="-"
                size="large"
                loading={props.userLoading}
                disabled={props.data.getFieldValue('referrer_id')}
              >
                {props.userList &&
                  props.userList.map((value: any) => (
                    <Select.Option key={value.id} value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Comissão"
              name="referrer_commission"
              rules={[
                ({ getFieldValue }) => ({
                  required: !!getFieldValue('referrer_id'),
                  message: 'Campo obrigatório',
                }),
                { validator: validateCurrency },
              ]}
            >
              <InputNumber
                size="large"
                addonAfter={<EuroOutlined />}
                style={{ width: '100%' }}
                formatter={formatPrice}
                parser={parseCurrency}
                maxLength={255}
                placeholder="Valor da comissão"
                disabled={!props.isEdit || disabledFields}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label={
                props?.data.getFieldValue('closed_state') === 'perdido' ||
                props?.data.getFieldValue('referrer_payment_status') ===
                  'canceled' ? (
                  <span>
                    Tipo de pagamento{' '}
                    <Tag
                      color="#FFCCC7"
                      style={{ color: '#000', textTransform: 'capitalize' }}
                    >
                      {props?.data.getFieldValue('referrer_payment_status') ===
                      'canceled'
                        ? 'cancelado'
                        : 'encerrado'}
                    </Tag>
                  </span>
                ) : (
                  'Tipo de pagamento'
                )
              }
              name="referrer_commission_method"
              rules={[
                ({ getFieldValue }) => ({
                  required: !!getFieldValue('referrer_id'),
                  message: 'Campo obrigatório',
                }),
              ]}
            >
              <Select
                placeholder="-"
                size="large"
                disabled={!props.isEdit || disabledFields}
              >
                {Object.keys(EnumPaymentType).map((key) => (
                  <Select.Option key={key} value={key}>
                    {EnumPaymentType[key as keyof typeof EnumPaymentType]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={10}>
            <Form.Item label="Closer" name="closer_id">
              <Select
                placeholder="-"
                size="large"
                loading={props.userLoading}
                disabled={!props.isEdit || disabledFields}
              >
                {props.userList &&
                  props.userList.map((value: any) => (
                    <Select.Option key={value.id} value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Comissão"
              name="closer_commission"
              rules={[
                ({ getFieldValue }) => ({
                  required: !!getFieldValue('closer_id'),
                  message: 'Campo obrigatório',
                }),
                { validator: validateCurrency },
              ]}
            >
              <InputNumber
                size="large"
                addonAfter={<EuroOutlined />}
                style={{ width: '100%' }}
                formatter={formatPrice}
                parser={parseCurrency}
                maxLength={255}
                placeholder="Valor da comissão"
                disabled={!props.isEdit || disabledFields}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label={
                props?.data.getFieldValue('closed_state') === 'perdido' ||
                props?.data.getFieldValue('referrer_payment_status') ===
                  'canceled' ? (
                  <span>
                    Tipo de pagamento{' '}
                    <Tag
                      color="#FFCCC7"
                      style={{ color: '#000', textTransform: 'capitalize' }}
                    >
                      {props?.data.getFieldValue('referrer_payment_status') ===
                      'canceled'
                        ? 'cancelado'
                        : 'encerrado'}
                    </Tag>
                  </span>
                ) : (
                  'Tipo de pagamento'
                )
              }
              rules={[
                ({ getFieldValue }) => ({
                  required: !!getFieldValue('closer_id'),
                  message: 'Campo obrigatório',
                }),
              ]}
              name="closer_commission_method"
            >
              <Select
                placeholder="-"
                size="large"
                disabled={!props.isEdit || disabledFields}
              >
                {Object.keys(EnumPaymentType).map((key) => (
                  <Select.Option key={key} value={key}>
                    {EnumPaymentType[key as keyof typeof EnumPaymentType]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={10}>
            <Form.Item label="Business coach" name="coach_id">
              <Select
                placeholder="-"
                size="large"
                loading={props.userLoading}
                disabled={!props.isEdit || disabledFields}
              >
                {props.userList &&
                  props.userList.map((value: any) => (
                    <Select.Option key={value.id} value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Comissão"
              name="coach_commission"
              rules={[
                ({ getFieldValue }) => ({
                  required: !!getFieldValue('coach_id'),
                  message: 'Campo obrigatório',
                }),
                { validator: validateCurrency },
              ]}
            >
              <InputNumber
                size="large"
                addonAfter={<EuroOutlined />}
                style={{ width: '100%' }}
                formatter={formatPrice}
                parser={parseCurrency}
                maxLength={255}
                placeholder="-"
                disabled={!props.isEdit || disabledFields}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label={
                props?.data.getFieldValue('closed_state') === 'perdido' ||
                props?.data.getFieldValue('referrer_payment_status') ===
                  'canceled' ? (
                  <span>
                    Tipo de pagamento{' '}
                    <Tag
                      color="#FFCCC7"
                      style={{ color: '#000', textTransform: 'capitalize' }}
                    >
                      {props?.data.getFieldValue('referrer_payment_status') ===
                      'canceled'
                        ? 'cancelado'
                        : 'encerrado'}
                    </Tag>
                  </span>
                ) : (
                  'Tipo de pagamento'
                )
              }
              name="coach_commission_method"
              rules={[
                ({ getFieldValue }) => ({
                  required: !!getFieldValue('coach_id'),
                  message: 'Campo obrigatório',
                }),
              ]}
            >
              <Select
                placeholder="-"
                size="large"
                disabled={!props.isEdit || disabledFields}
              >
                {Object.keys(EnumPaymentType).map((key) => (
                  <Select.Option key={key} value={key}>
                    {EnumPaymentType[key as keyof typeof EnumPaymentType]}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
}

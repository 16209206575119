import { Card, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Typography } from "antd";
import { useEffect } from "react";
import { getLabel } from "../../Enums/TypeOfKanban.enums";
import { EuroOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const typeOfBusiness = [
    { id: 'diagnostic', description: 'Diagnóstico' },
    { id: 'business_club', description: 'Clube de Empresários' }
]

export default function DetailComponent({ client, open, close, title, readOnly }: any) {
    const [formClient] = Form.useForm();

    useEffect(() => {
        if (client) {
            client.status = client.status ? "Ativo" : "Inativo";
            client.type_business = typeOfBusiness.find((type) => type.id === client.type_business)?.description;
            client.referencer = client?.referencer?.name;

            formClient.setFieldsValue(client);
        }
    }, [client, formClient]);

    const formatDate = (date: any) => {
        return new Date(date).toLocaleDateString('pt-PT')
    }

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={close}
            destroyOnClose={true}
            width={1000}
            footer={null}
        >
            <Divider />
            <Form
                form={formClient}
                layout="vertical"
            >
                <Row gutter={8}>
                    <Col span={24}>
                        <Title style={{ margin: '0px 0px 16px 0px' }} level={5}>Informações do Cliente</Title>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={[
                                { type: "email", message: "Formato do email inválido" },
                                { required: true, message: "Campo obrigatório" },
                            ]}
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                                maxLength={255}
                                placeholder="E-mail"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Contacto"
                            name="phone"
                            rules={[
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                                {
                                    validator: (_, value: number) => {
                                        if (value >= 0 && value?.toString()?.length < 9) {
                                            return Promise.reject('O número de telefone deve ter 9 dígitos');
                                        }
                                        return Promise.resolve();
                                    },
                                }
                            ]}
                        >
                            <InputNumber
                                readOnly={readOnly}
                                size="large"
                                style={{ width: "100%" }}
                                maxLength={11}
                                parser={(value) => `${value}`.replace(/[^0-9]/g, '')}
                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome"
                            name="name"
                            rules={[
                                { required: true, message: "Campo obrigatório" },
                            ]}
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                                maxLength={255}
                                placeholder="Nome do cliente"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Organização"
                            name="organization"
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                                maxLength={255}
                                placeholder="Nome da organização"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Divider /> 
                <Row gutter={8}>
                    <Col span={24}>
                        <Title style={{ margin: '0px 0px 16px 0px' }} level={5}>Informações do Referenciador</Title>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="Referenciador"
                            name="referencer"
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                                maxLength={100}
                                placeholder="Nome do referenciador"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label="Tipo de negócio"
                            name="type_business"
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                                placeholder="Tipo de negócio"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item
                            label="Status"
                            name="status"
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                                placeholder="Status"
                            />
                        </Form.Item>
                    </Col>
                </Row> */}
                {client.business.length > 0 && <>
                    <Divider />
                    <Row gutter={8}>
                        <Col span={24}>
                            <Title style={{ margin: '0px 0px 24px 0px' }} level={5}>Negócios associados</Title>
                        </Col>
                    </Row>
                </>}
                {client.business.map((business: any, key: number) => <Card style={{ marginBottom: 16 }}>
                    <Row gutter={8}>
                        <Col span={16}>
                            <Title className={'title_page'} style={{ marginTop: 0, marginBottom: 0 }} level={5}>Detalhes do negócio</Title>
                        </Col>
                        <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Text>Data de criação: {formatDate(business.created_at)}</Text>
                        </Col>
                    </Row>
                    <Divider />
                    <Form
                        layout="vertical"
                        initialValues={{
                            name: business.name,
                            referrer: business.referrer?.name,
                            closer: business.closer?.name,
                            business_coach: business.coach?.name,
                            business_kanban: getLabel(business.business_kanban.type),
                            business_status: business.state_business
                        }}
                    >
                        <Row gutter={8}>
                            <Col span={8}>
                                <Form.Item
                                    label="Nome do negócio"
                                    name="name"
                                >
                                    <Input
                                        size="large"
                                        placeholder="Nome do negócio"
                                        readOnly
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Tipo de funil"
                                    name="business_kanban"
                                >
                                    <Input
                                        size="large"
                                        readOnly
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Status do negócio"
                                    name="business_status"
                                >
                                    <Input
                                        size="large"
                                        maxLength={255}
                                        placeholder="Status do negócio"
                                        readOnly
                                        style={{ textTransform: "capitalize" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={8}>
                            <Col span={8}>
                                <Form.Item
                                    label="Nome do referenciador"
                                    name="referrer"
                                >
                                    <Input
                                        size="large"
                                        style={{ textTransform: "capitalize" }}
                                        readOnly
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Nome do closer"
                                    name="closer"
                                >
                                    <Input
                                        size="large"
                                        style={{ textTransform: "capitalize" }}
                                        readOnly
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label="Nome do business coach"
                                    name="business_coach"
                                >
                                    <Input
                                        size="large"
                                        readOnly
                                        style={{ textTransform: "capitalize" }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>)}
            </Form>
        </Modal>
    )
}
import { AutoComplete, Button, Card, Checkbox, Col, DatePicker, Divider, Form, Input, Row, TimePicker, Typography } from "antd";
import { useMutation } from "react-query";
import { storeBusinessFollowup } from "../../../services/business.service";
import { useState } from "react";
import moment from "moment";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/pt_BR";

const { Title } = Typography

export default function FormFollowUp(props: any) {
    const [formFollowup] = Form.useForm();
    const [responsability, setResponsability] = useState([]);
    const [responsable, setResponsable] = useState<any>();

    const { isLoading: isLoadingStore, mutate: store } = useMutation(storeBusinessFollowup, {
        onSuccess: () => {
            formFollowup.resetFields()
            props.handlerOpenFormFollowup()
            props.refetch()
        }
    })

    const save = () => {
        formFollowup
            .validateFields()
            .then((values) => {
                store({
                    ...values,
                    time: values.time.format('HH:mm'),
                    business_id: props.businessId,
                    created_by: props.userId,
                    responsible_id: responsable
                });
            }).catch((error) => {

            });
    }

    const getSearchResponsability = (searchText: string) => {
        return searchText.length > 2 ?
            props.responsibles.filter((option: any) => option.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1) :
            [];
    }

    const onSelectResponsability = (data: any, option: any) => {
        setResponsable(option.id);
    };

    return (
        <>
            <Row gutter={8}>
                <Card style={{ width: '100%' }}>
                    <Col span={24}>
                        <Form
                            form={formFollowup}
                            layout="vertical"
                            initialValues={props.initialValue}
                        >
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Title
                                        className={'title_page'}
                                        style={{ margin: '0px 0px 16px 0px' }}
                                        level={5}>Criar follow-up</Title>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Título"
                                        name="title"
                                        rules={[{ required: true, message: "Campo obrigatório" }]}
                                    >
                                        <Input
                                            size="large"
                                            maxLength={255}
                                            placeholder="Título"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Data"
                                        name="date"
                                        rules={[{ required: true, message: "Campo obrigatório" }]}
                                    >
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            format="DD/MM/YYYY"
                                            size="large"
                                            placeholder="Data"
                                            disabledDate={(current) => current && current < moment().startOf('day')}
                                            locale={{
                                                ...locale,
                                                lang: {
                                                    ...locale.lang,
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Hora"
                                        name="time"
                                        rules={[{ required: true, message: "Campo obrigatório" }]}
                                    >
                                        <TimePicker
                                            style={{ width: '100%' }}
                                            format="HH:mm"
                                            size="large"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label="Nome do responsável"
                                        name="responsible_id"
                                        rules={[{ required: true, message: "Campo obrigatório" }]}
                                    >
                                        <AutoComplete
                                            options={props.responsibles}
                                            onSelect={(data, option) => onSelectResponsability(data, option)}
                                            onSearch={(text) => setResponsability(getSearchResponsability(text))}
                                            placeholder="Nome do responsável"
                                            allowClear
                                            size="large"
                                            onClear={() => setResponsable(undefined)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="is_important"
                                        valuePropName="checked"
                                    >
                                        <Checkbox>Sinalizar como importante</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col offset={18} span={6} style={{ display: 'flex', justifyContent: 'flex-end', gap: 16 }}>
                                    <Button type="default" size="large" onClick={props.handlerOpenFormFollowup}>Cancelar</Button>
                                    <Button
                                        type="primary"
                                        size="large"
                                        // disabled={!isSubmit}
                                        loading={isLoadingStore}
                                        onClick={save}
                                    >Gravar</Button>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Card>
            </Row>
            <Divider />
        </>
    )
}
import { Button, Card, Col, Divider, Row, Tabs, Typography } from "antd";
import EmptyList from "../../../components/Commons/Empty";
import type { TabsProps } from 'antd';
import FollowUpCard from "../../../components/Business/FollowUp";
import { useState } from "react";
import FormFollowUp from "./FormFollowUp";
import { useQuery } from "react-query";
import { getBusinessFollowup } from "../../../services/business.service";

const { Title } = Typography

export default function FollowUp(props: any) {
    const [openFormFollowup, setOpenFormFollowup] = useState(false);
    const [followup, setFollowup] = useState([]);
    const [pastFollowup, setPastFollowup] = useState([]);
    const [editInitialValue, setEditInitialValue] = useState<any>({})

    const handlerOpenFormFollowup = () => {
        setOpenFormFollowup(!openFormFollowup)
    }

    const { refetch } = useQuery(['businessFollowup', props.id], () => getBusinessFollowup(props.id), {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            if (data?.data?.active?.length > 0) {
                setFollowup(data.data.active)
            } else {
                setFollowup([])
            }
            if (data?.data?.inactive?.length > 0) {
                setPastFollowup(data.data.inactive)
            } else {
                setPastFollowup([])
            }
        }
    })

    const items: TabsProps['items'] = [
        {
            key: '1',
            label: 'Ativos',
            children: followup.length > 0 ? followup.map((item: any, index: number) => (
                <FollowUpCard key={index} data={item} refetch={refetch} />
            )) : <EmptyList text="Nenhum follow-up criado" />,
        },
        {
            key: '2',
            label: 'Fechados',
            children: pastFollowup.length > 0 ? pastFollowup.map((item: any, index: number) => (
                <FollowUpCard key={index} data={item} refetch={refetch} closed/>
            )) : <EmptyList text="Nenhum follow-up inativo" />,
        }
    ];

    return (
        <Card style={{ marginTop: 15 }}>
            {openFormFollowup &&
                <FormFollowUp
                    handlerOpenFormFollowup={handlerOpenFormFollowup}
                    businessId={props.id}
                    userId={props.data.id}
                    refetch={refetch}
                    responsibles={props.responsibles}
                    initialValue={editInitialValue}
                />
            }

            <Row gutter={8}>
                <Col
                    span={24}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <Title
                        style={{ margin: '0px 0px 16px 0px' }}
                        level={5}>Follow-up</Title>
                    {!openFormFollowup
                        && <Button type="default" size="large" onClick={handlerOpenFormFollowup} >Criar follow-up</Button>}
                </Col>
            </Row>
            <Divider />
            <Row gutter={8}>
                <Col span={24}>
                    <Tabs defaultActiveKey="1" items={items} />
                </Col>
            </Row>
        </Card>
    )
}
import { del, get, post, put } from './api.service';

const list = ({
  page,
  perPage,
  search,
  sort,
  order,
}: {
  page: number | undefined;
  perPage: number | undefined;
  search: string | undefined;
  sort: string | undefined;
  order: string | undefined;
}) => {
  const params = [];
  if (page) {
    params.push(`page=${page}`);
  }
  if (perPage) {
    params.push(`per_page=${perPage}`);
  }
  if (search) {
    params.push(`search=${search}`);
  }
  if (sort) {
    params.push(`sort=${sort}`);
  }
  if (order) {
    params.push(`order=${order === 'ascend' ? 'asc' : 'desc'}`);
  }

  if (params.length > 0) return get(`uniclients/v1?${params.join('&')}`);

  return get('uniclients/v1');
};

const storeClient = (data: any) => {
  return post('uniclients/v1', data);
};

const editClient = ({ data, id }: { data: any; id: number }) => {
  return put(`uniclients/v1/${id}`, data);
};

const deleteClient = (id: number) => {
  return put(`uniclients/v1/delete/${id}`);
};

const getOneById = (id: number) => {
  return get(`uniclients/v1/${id}`);
};

const getOneByIdOnlyBusinessOpen = (id: number) => {
  return get(`uniclients/v1/${id}/business-open`);
};

const checkIfEmailLinkedToReferrer = (email: string) => {
  return get(`uniclients/v1/check-email/${email}`);
};

const RequestToChangeReferrer = ({ referrer, client }: any) => {
  return put(`uniclients/v1/request-change-referrer/${client}`, {
    referrer,
    client,
  });
};

const deleteTotalClient = (id: number) => {
  return del(`uniclients/v1/${id}`);
};

const getNotificationChangeReferrerList = () => {
  return get('uniclients/v1/request-notification-list');
};

export {
  storeClient,
  deleteClient,
  editClient,
  getOneById,
  list,
  checkIfEmailLinkedToReferrer,
  RequestToChangeReferrer,
  deleteTotalClient,
  getNotificationChangeReferrerList,
  getOneByIdOnlyBusinessOpen,
};

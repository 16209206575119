import { get, post, put } from './api.service';

const getProducts = ({
  page,
  perPage,
  search,
  sort,
  order,
}: {
  page: number | undefined;
  perPage: number | undefined;
  search: string | undefined;
  sort: string | undefined;
  order: string | undefined;
}) => {
  const params = [];
  if (page) {
    params.push(`page=${page}`);
  }
  if (perPage) {
    params.push(`per_page=${perPage}`);
  }
  if (search) {
    params.push(`search=${search}`);
  }
  if (sort) {
    params.push(`sort=${sort}`);
  }
  if (order) {
    params.push(`order=${order === 'ascend' ? 'asc' : 'desc'}`);
  }

  if (params.length > 0) return get(`products/v1?${params.join('&')}`);

  return get('products/v1');
};

const getAllProducts = ({ status }: { status?: string }) => {
  const params = [];

  if (status) {
    params.push(`status=${status}`);
  }

  if (params.length > 0) return get(`products/v1/all?${params.join('&')}`);

  return get('products/v1/all');
};

const getOneById = (id: number) => {
  return get(`products/v1/${id}`);
};

const storeProduct = (data: any) => {
  return post('products/v1', data);
};

const editProduct = ({ data, id }: { data: any; id: number }) => {
  return put(`products/v1/${id}`, data);
};

const deleteProduct = (id: number) => {
  return put(`products/v1/delete/${id}`);
};

export {
  getProducts,
  storeProduct,
  editProduct,
  deleteProduct,
  getOneById,
  getAllProducts,
};

import React from 'react';
import { Modal, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

export default function ModalConfirmCancelEvent({
  open,
  onOk,
  onCancel,
  loading,
  text = 'Tem certeza que deseja excluir o evento?',
  description = 'O evento e os alarmes serão removidos.',
  okText = 'Sim',
  cancelText = 'Não',
}) {
  return (
    <Modal
      okText={okText}
      cancelText={cancelText}
      onOk={() => onOk()}
      onCancel={() => onCancel()}
      confirmLoading={loading}
      open={open}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 16,
          margin: '16px 0px',
        }}
      >
        <InfoCircleOutlined style={{ color: '#FAAD14', fontSize: 20 }} />
        <Title style={{ margin: 0 }} level={4}>
          {text}
        </Title>
      </div>
      <div>
        <Text>{description}</Text>
      </div>
    </Modal>
  );
}

export enum EnumTypeKanban {
    COACHING_BUSINESS_CLUB = 'COACHING_BUSINESS_CLUB',
    DIAGNOSIS = 'DIAGNOSIS',
}

export function getLabel(type: EnumTypeKanban): string {
    switch (type) {
        case EnumTypeKanban.COACHING_BUSINESS_CLUB:
            return 'Clube de Empresários';
        case EnumTypeKanban.DIAGNOSIS:
            return 'Diagnóstico';
    }
}
import { Col, Row } from "antd"

export default function Footer(props: any) {
    const { name, date } = props

    return (
        <Row style={{ paddingTop: 15 }}>
            <Col span={8}>
                <div style={{ fontWeight: 500 }}>{ name }</div>
            </Col>
            <Col span={8} offset={8} style={{textAlign: 'end', fontSize: 12}}>
                <div style={{ color: '#8C8C8C' }}>{ date }</div>
            </Col>
        </Row>
    )
}
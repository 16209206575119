import { Empty } from "antd";

export default function EmptyList(props: any) {
    return (
        <Empty
            imageStyle={{ height: 85 }}
            style={{
                width: "100%",
                display: "flex", 
                flexDirection: "column", 
                justifySelf: "center"
            }}
            description={
                <span style={{ color: '#1F1F1F', fontSize: 14 }}>
                    {props.text}
                </span>
            }>
        </Empty>
    )
}
import {
  Button,
  Card,
  Form,
  Input,
  PaginationProps,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import ConfirmPasswordModal from '../../../components/Commons/Modal/confirm-password-modal.component';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { cutLongText } from '../../../services/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import {
  generatePayments,
  getAll,
  hardDelete,
} from '../../../services/users.service';
import ConfirmModal from '../../../components/Commons/Modal/confirm-modal.component';
import ShowDataLength from '../../../components/Commons/Table/results-length-table.component';
import GenericTable from '../../../components/Commons/Table/generic-table.component';
import DisplayAlert from '../../../components/Commons/Alert';
import { deleteTotalClient, list } from '../../../services/clients.service';
import { AlertService } from '../../../services/alert.service';
import { FilterValue } from 'antd/lib/table/interface';

const { Search } = Input;

const { Title } = Typography;

export default function Administrative() {
  const adminAuthCookie = document.cookie.replace(
    /(?:(?:^|.*;\s*)adminsitrativa\s*=\s*([^;]*).*$)|^.*$/,
    '$1'
  );

  const [openRemoveUserId, setOpenRemoveUserId] = useState<boolean>(false);
  const [openRemoveClientId, setOpenRemoveClientId] = useState<boolean>(false);

  const [openPassConfirmDelete, setOpenPassConfirmDelete] =
    useState<boolean>(false);
  const [
    openPassConfirmGeneratePagamentos,
    setOpenPassConfirmGeneratePagamentos,
  ] = useState<boolean>(false);
  const [generationPayments, setGenerationPayments] = useState<boolean>(false);
  const [openPassConfirm, setOpenPassConfirm] = useState<boolean>(
    adminAuthCookie ? false : true
  );
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);
  const [perPage, setPerPage] = useState<number | undefined>(10);
  const [total, setTotal] = useState<number>(0);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [type, setType] = useState<string>('users');
  const [order, setOrder] = useState<string>('id-asc');

  const [formSearch] = Form.useForm();

  const [removeUserId, setRemoveUserId] = useState<number | undefined>(
    undefined
  );
  const [removeClientId, setRemoveClientId] = useState<number | undefined>(
    undefined
  );
  const [userList, setUserList] = useState<{ id: number; name: string }[]>([]);

  const navigate = useNavigate();

  const {
    data: data,
    refetch: reload,
    isLoading: userLoading,
  } = useQuery(
    ['userList', currentPage, search, perPage, type],
    () => {
      if (type === 'users') {
        return getAll({
          page: currentPage,
          search,
          per_page: perPage,
        });
      }

      return list({
        page: currentPage,
        search,
        perPage,
        sort: undefined,
        order: undefined,
      });
    },
    { refetchOnWindowFocus: false }
  );

  useEffect(() => {
    if (data) {
      if (type === 'users') {
        setUserList(
          data.data.map((user: any) => {
            return {
              key: user.id,
              ...user,
            };
          })
        );
      }
      if (type === 'clients') {
        setUserList(
          data.data.map((client: any) => {
            return {
              key: client.data.id,
              ...client.data,
            };
          })
        );
      }

      setCurrentPage(data.meta.current_page);
      setPerPage(data.meta.per_page);
      setTotal(data.meta.total);
    }
  }, [data]);

  const handleTableChange = (
    pagination: PaginationProps,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    if (pagination.current != currentPage) {
      setCurrentPage(pagination.current);
    }

    if (pagination.pageSize != perPage) {
      setPerPage(pagination.pageSize);
      setCurrentPage(1);
    }

    if (sorter.order) {
      let orderBy = `${sorter.field}-${sorter.order.substr(
        0,
        sorter.order.length - 3
      )}`;

      if (order != orderBy) {
        setOrder(orderBy);
      }
    }
  };
  const useQueryParams = () => {
    return new URLSearchParams(useLocation().search);
  };

  const queryParams = useQueryParams();
  const userTypeQueryParam = queryParams.get('type');
  useEffect(() => {
    if (userTypeQueryParam) {
      setType(userTypeQueryParam);
      formSearch.setFieldValue('type', userTypeQueryParam);
    }
  }, [userTypeQueryParam]);

  const callbackPasswordValidate = (result: boolean) => {
    if (result) {
      const expiration = new Date(new Date().getTime() + 10 * 60000);
      document.cookie = `adminsitrativa=${encodeURIComponent(
        JSON.stringify({ authenticated: true })
      )}; expires=${expiration.toUTCString()}`;
    }

    setOpenPassConfirm(!result);
  };

  const { mutate: mutateDeleteTotalClient } = useMutation(deleteTotalClient, {
    onSuccess: () => {
      reload();
      AlertService.sendAlert([{ text: 'Cliente apagado com sucesso.' }]);
    },
  });

  const { mutate: mutateDeleteTotalUser } = useMutation(hardDelete, {
    onSuccess: () => {
      reload();
      AlertService.sendAlert([{ text: 'Utilizador apagado com sucesso.' }]);
    },
  });

  const { mutate: mutateGeneratePayments } = useMutation(generatePayments, {
    onSuccess: () => {
      reload();
      AlertService.sendAlert([
        {
          text: 'A ação está sendo executada em segundo plano e pode levar alguns minutos para ser concluída. Não é preciso esperar; você pode continuar navegando pelo sistema.',
        },
      ]);
    },
  });

  const callbackPasswordValidateToDelete = (result: boolean) => {
    if (result) {
      if (type === 'users') {
        mutateDeleteTotalUser(removeUserId);
        setRemoveUserId(undefined);
        setOpenPassConfirmDelete(false);
        setOpenRemoveUserId(false);
      }
      if (type === 'clients' && removeClientId) {
        mutateDeleteTotalClient(removeClientId);
        setRemoveClientId(undefined);
        setOpenPassConfirmDelete(false);
        setOpenRemoveClientId(false);
      }
    }
  };

  const callbackPasswordValidateToGeneratePagamentos = () => {
    if (type === 'pagamentos') {
      mutateGeneratePayments();
      setGenerationPayments(true);
      setOpenPassConfirmGeneratePagamentos(false);

      setTimeout(() => {
        setGenerationPayments(false);
      }, 10000);
    }
  };

  const toPage = (user_id: number = 0) => {
    if (type === 'users') {
      navigate(`/manager/administrative/user/${user_id}/edit`);
    }
    if (type === 'clients') {
      navigate(`/manager/administrative/client/${user_id}/edit`);
    }
  };

  const onSearch = (search_value: any) => {
    setCurrentPage(1);
    setSearch(search_value);
  };

  const onChangeType = (type: string) => {
    setCurrentPage(1);
    navigate(`/manager/administrative?type=${type}`);
    setType(type);
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, record: any) => cutLongText(record.name, 40),
    },
    { title: 'Email', dataIndex: 'email', sorter: false, key: 'email' },
    {
      title: 'Status',
      dataIndex: 'active',
      key: 'active',
      render: (_: any, record: any) =>
        type === 'users' ? (
          <span>{record.active == true ? 'Ativo' : 'Inativo'}</span>
        ) : (
          <span>{record.status == true ? 'Ativo' : 'Inativo'}</span>
        ),
    },
    {
      title: 'Ações',
      key: 'action',
      width: '15%',
      render: (_: any, record: any) => (
        <Space size="small">
          <Tooltip placement="top" title="Editar">
            <Button
              type="default"
              shape="circle"
              size="middle"
              icon={<EditOutlined />}
              onClick={() => toPage(record.id)}
            />
          </Tooltip>
          <Tooltip placement="top" title="Apagar">
            <Button
              type="default"
              shape="circle"
              size="middle"
              icon={<DeleteOutlined />}
              onClick={() => {
                if (type === 'users') {
                  setRemoveUserId(record.id);
                  setOpenRemoveUserId(true);
                }
                if (type === 'clients') {
                  setRemoveClientId(record.id);
                  setOpenRemoveClientId(true);
                }
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const callbackCancel = () => {
    window.history.back();
  };

  return (
    <div>
      <div style={{ padding: 24, paddingBottom: 0 }}>
        <DisplayAlert />
      </div>
      <div className="content_page">
        <Card
          bodyStyle={{ padding: 8 }}
          headStyle={{
            paddingTop: 20,
            paddingBottom: 20,
            color: '#107B99',
            fontWeight: 600,
          }}
        >
          <div className="search_bar">
            <Form
              form={formSearch}
              name="search_user"
              layout="inline"
              autoComplete="off"
              initialValues={{
                type: type,
              }}
              style={{ width: '100%' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Form.Item name="search_user" className="item_search">
                  {type != 'pagamentos' && (
                    <Search
                      onSearch={onSearch}
                      placeholder="Pesquisa por nome do utilizador ou email"
                      enterButton="Pesquisar"
                      allowClear
                      size="large"
                      maxLength={255}
                    />
                  )}
                  {type === 'pagamentos' && (
                    <Title level={4} style={{ margin: 0 }}>
                      Instruções para Gerar Pagamentos Pendentes
                    </Title>
                  )}
                </Form.Item>
                <Form.Item name="type" className="item_filter">
                  <Select
                    onChange={onChangeType}
                    style={{ width: 200 }}
                    size="large"
                    options={[
                      { value: 'users', label: 'Utilizadores' },
                      { value: 'clients', label: 'Clientes' },
                      { value: 'pagamentos', label: 'Pagamentos' },
                    ]}
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
          {type != 'pagamentos' && (
            <div className="user_table" style={{ marginTop: 20 }}>
              <GenericTable
                columns={columns}
                dataSource={userList}
                pagination={{
                  current: currentPage,
                  pageSize: perPage,
                  total: total,
                  showSizeChanger: true,
                }}
                loading={userLoading}
                totalChildren={
                  <div className="zone_result_size_n_export">
                    <ShowDataLength size={total} className="show_result_size" />
                  </div>
                }
                empty={{
                  createTheFirst: !userList.length && !search,
                  textOne:
                    'Esta tabela encontra-se vazia, uma vez que ainda não foram criados utilizadores.',
                  textTwo: 'Para começar, crie um novo utilizador.',
                  link: '/manager/users/create',
                }}
                handleTableChange={handleTableChange}
              />

              <ConfirmModal
                open={openRemoveUserId}
                title="Está prestes a apagar os dados deste utilizador. Tem a certeza que deseja fazer esta alteração?"
                content="Ao apagar este utilizador, está a apagar permanentemente todos os dados existentes deste utilizador no sistema"
                okText="Validar password"
                cancelText="Voltar"
                onOk={() => {
                  setOpenRemoveUserId(false);
                  setOpenPassConfirmDelete(true);
                }}
                onCancel={() => {
                  setOpenRemoveUserId(false);
                  setRemoveUserId(undefined);
                }}
              ></ConfirmModal>
              <ConfirmModal
                open={openRemoveClientId}
                title="Está prestes a apagar os dados deste cliente. Tem a certeza que deseja fazer esta alteração?"
                content="Ao apagar este cliente, está a apagar permanentemente todos os dados existentes deste utilizador no sistema"
                okText="Validar password"
                cancelText="Voltar"
                onOk={() => {
                  setOpenRemoveClientId(false);
                  setOpenPassConfirmDelete(true);
                }}
                onCancel={() => {
                  setOpenRemoveClientId(false);
                  setRemoveClientId(undefined);
                }}
              ></ConfirmModal>
            </div>
          )}
          {type === 'pagamentos' && (
            <div className="user_table" style={{ margin: 24 }}>
              <div>
                <Button
                  type="primary"
                  size="large"
                  onClick={() => setOpenPassConfirmGeneratePagamentos(true)}
                  loading={generationPayments}
                >
                  Gerar pagamentos pendentes
                </Button>
              </div>
              <div>
                <p>
                  Ao clicar em "Gerar pagamentos pendentes", serão gerados todos
                  os pagamentos pendentes para os negócios do mês atual com
                  status de "fechado" que ainda não haviam sido gerados.
                </p>
                <p>Importante:</p>
                <ul>
                  <li>
                    Negócios com pagamentos já gerados anteriormente não serão
                    alterados.
                  </li>
                  <li>Pagamentos já gerados não serão duplicados.</li>
                </ul>
              </div>
            </div>
          )}
        </Card>
      </div>
      <ConfirmPasswordModal
        open={openPassConfirm}
        callback={callbackPasswordValidate}
        title="Está a entrar numa área restrita, valide os seus dados para aceder a esta página"
        content={null}
        onCancel={callbackCancel}
      />
      <ConfirmPasswordModal
        open={openPassConfirmDelete}
        callback={callbackPasswordValidateToDelete}
        title={
          type === 'users'
            ? 'Está prestes a apagar os dados deste utilizador. Tem a certeza que deseja fazer esta alteração?'
            : 'Está prestes a apagar os dados deste cliente. Tem a certeza que deseja fazer esta alteração?'
        }
        content={
          type === 'users'
            ? 'Ao apagar este utilizador, está a apagar permanentemente todos os dados existentes deste utilizador no sistema'
            : 'Ao apagar este cliente, está a apagar permanentemente todos os dados existentes deste utilizador no sistema'
        }
        onCancel={() => setOpenPassConfirmDelete(false)}
      />
      <ConfirmPasswordModal
        open={openPassConfirmGeneratePagamentos}
        callback={callbackPasswordValidateToGeneratePagamentos}
        title={'Confirmar Geração de Pagamentos Pendentes'}
        content={
          'Ao executar este comando, os pagamentos serão gerados para todos os negócios fechados deste mês que ainda não foram gerados. Pagamentos já criados não serão alterados ou duplicados.'
        }
        onCancel={() => setOpenPassConfirmGeneratePagamentos(false)}
      />
    </div>
  );
}

import {
    Button,
    Card,
    Form,
    Input,
    Space,
    Select,
    Col,
    Row,
    Switch,
    InputNumber,
} from "antd";
import DisplayAlert from "../../../components/Commons/Alert";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { AlertService } from "../../../services/alert.service";
import { create, getById, update } from "../../../services/users.service";
import React, { useEffect, useState } from "react";
import ConfirmModal from "../../../components/Commons/Modal/confirm-modal.component";
import { getAll as getProfiles } from "../../../services/profiles.service";
import ConfirmPasswordModal from "../../../components/Commons/Modal/confirm-password-modal.component";

export default function UserFormAdministrative(props: any) {
    const [leaveThePage, setLeaveThePage] = useState<boolean>(false);
    const [alertEdit, setAlertEdit] = useState<boolean>(false);
    const [valuesChanged, setValuesChanged] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [openPassConfirm, setOpenPassConfirm] = useState<boolean>(false)
    const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);

    const [formUser] = Form.useForm();
    const { id } = useParams();

    const navigate = useNavigate();

    const toPage = () => {
        navigate("/manager/administrative?type=users");
    };

    const onSubmit = () => {
        formUser
            .validateFields()
            .then((values) => {
                updateUserMutate({
                    values: { ...values, active: checked },
                    id: Number(id),
                });
            })
            .catch(() => {
                AlertService.sendAlert([
                    { text: "Campo(s) com preenchimento inválido", type: "error" },
                ]);
                setAlertEdit(false);
            });
    };

    const {
        mutate: updateUserMutate,
        isLoading: updateUserLoading,
        isSuccess: isSuccessEditUser,
    } = useMutation(update);

    useEffect(() => {
        if (isSuccessEditUser) {
            formUser.resetFields();
            AlertService.sendAlert([
                {
                    text: "Utilizador editado com sucesso.",
                    nextPage: true,
                },
            ]);
            setAlertEdit(false);
            toPage();
        }
    }, [isSuccessEditUser]);

    useQuery(["loadUser", id], () => (id ? getById(Number(id)) : null), {
        onSuccess: (response) => {
            if (response) {
                formUser.setFieldsValue(response.data);

                setChecked(response.data.active);
            }
        },
        refetchOnWindowFocus: false,
    });

    const [submittable, setSubmittable] = useState(false);

    const values = Form.useWatch([], formUser);

    useEffect(() => {
        formUser
            .validateFields({
                validateOnly: true,
            })
            .then(() => {
                setSubmittable(true)
            }).catch((error: any) => {
                setSubmittable(false)
            });
    }, [formUser, values]);

    const callbackPasswordValidate = (result: boolean) => {
        if (result) {
            setOpenPassConfirm(!result);
            onSubmit();
        }
    }

    const callbackCancel = () => {
        setOpenPassConfirm(false);
    }

    return (
        <>
            <DisplayAlert />
            <div className="content_page">
                <Card
                    title="Editar utilizador"
                >
                    <Form
                        form={formUser}
                        name="create_users"
                        layout="vertical"
                        className="form_table"
                        autoComplete="off"
                        onValuesChange={() => setValuesChanged(true)}
                    >
                        <Row gutter={20} style={{ marginTop: 20 }}>
                            <Col span={8}>
                                <Form.Item
                                    name="name"
                                    label="Nome"
                                    rules={[{ required: true, message: "Campo obrigatório" }]}
                                >
                                    <Input size="large" maxLength={255} />
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        { type: "email", message: "Formato do email inválido" },
                                        { required: true, message: "Campo obrigatório" },
                                    ]}
                                >
                                    <Input size="large" maxLength={50} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    data-html="true"
                                    rules={[
                                        {
                                            min: 8,
                                            message: "A password deve ter no mínimo 8 caracteres",
                                        },
                                        {
                                            pattern: new RegExp(
                                                "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[-+_!@#$%^&*.,?]).+$"
                                            ),
                                            message:
                                                "A password deve ter letras maiúsculas, minúsculas, números e caracteres especiais",
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        type="password"
                                        placeholder="Password"
                                        size="large"
                                        maxLength={100}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Item name="active">
                                    <Row>
                                        <Col flex="auto">
                                            <Switch
                                                checked={checked}
                                                onChange={($event) => {
                                                    setChecked($event);
                                                    setValuesChanged(true);
                                                }}
                                            />
                                        </Col>
                                        <Col style={{ marginLeft: 10 }}>
                                            Utilizador {checked == true ? "ativo" : "inativo"}
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row
                            gutter={20}
                            style={{
                                marginTop: 20,
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Col>
                                <Space>
                                    <Button
                                        loading={updateUserLoading}
                                        size="large"
                                        onClick={() => setOpenModalCancel(true)}
                                    >
                                        Cancelar
                                    </Button>

                                    <Button
                                        type="primary"
                                        size="large"
                                        loading={updateUserLoading}
                                        onClick={() => setAlertEdit(true)}
                                    >
                                        Gravar
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                    <ConfirmModal
                        open={openModalCancel}
                        title="Tem certeza que deseja sair sem gravar as alterações?"
                        content="Toda a informação não gravada será perdida."
                        okText="Sair"
                        cancelText="Cancelar"
                        onOk={() => toPage()}
                        onCancel={() => setOpenModalCancel(false)}
                    ></ConfirmModal>
                    <ConfirmModal
                        open={alertEdit}
                        title="Está prestes a alterar um dado sensível. Tem a certeza que deseja fazer esta alteração?"
                        okText="Validar password"
                        cancelText="Voltar"
                        onOk={() => {
                            setOpenPassConfirm(true)
                            setAlertEdit(false);
                        }}
                        onCancel={() => setAlertEdit(false)}
                    ></ConfirmModal>
                    <ConfirmModal
                        open={leaveThePage}
                        title="Tem certeza que deseja sair sem gravar as alterações?"
                        content="Toda a edição não gravada será perdida."
                        okText="Sair"
                        cancelText="Voltar"
                        onOk={() => {
                            setLeaveThePage(false);
                            toPage();
                        }}
                        onCancel={() => setLeaveThePage(false)}
                    ></ConfirmModal>
                    <ConfirmPasswordModal
                        open={openPassConfirm}
                        callback={callbackPasswordValidate}
                        onCancel={callbackCancel}
                        title="Está prestes a editar os dados deste utilizador. Tem a certeza  que deseja fazer esta alteração?"
                    />
                </Card>
            </div>
        </>
    );
}

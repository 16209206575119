import { Button, Card, Divider, Form, PaginationProps, Space, Tooltip } from "antd";
import Search from "antd/es/input/Search";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DisplayAlert from "../../components/Commons/Alert";
import { useMutation, useQuery } from "react-query";
import { deleteClient, list, getNotificationChangeReferrerList } from "../../services/clients.service";
import GenericTable from "../../components/Commons/Table/generic-table.component";
import { cutLongText } from "../../services/utils";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import ConfirmModal from "../../components/Commons/Modal/confirm-modal.component";
import { AlertService } from "../../services/alert.service";
import ShowDataLength from "../../components/Commons/Table/results-length-table.component";
import { FilterValue } from "antd/es/table/interface";
import DetailComponent from "../../components/Clients/details.component";
import { EnumTypeOfBusiness } from "../../Enums/TypeOfBusiness.enums";

export default function Clients() {
  const [formSearch] = Form.useForm();
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);
  const [perPage, setPerPage] = useState<number | undefined>(10);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [columnSort, setColumnSort] = useState<string | undefined>(undefined);
  const [columnOrder, setColumnOrder] = useState<string | undefined>(undefined);
  const [clientList, setClientList] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [openModalDelete, setOpenModalDelete] = useState<number | null>(null);
  const [openModalCantDelete, setOpenModalCantDelete] = useState<number | null>(null);
  const [openModalDetail, setOpenModalDetail] = useState<any | null>(null);
  const [notificationReferrerList, setNotificationReferrerList] = useState<[]>([]);

  const navigate = useNavigate();

  const onSearch = (search_value: any) => {
    setCurrentPage(1);
    setSearch(search_value);
  };

  const goToForm = () => {
    navigate("/clients/new");
  };

  const goToEditForm = (id: number) => {
    navigate("/clients/edit/" + id);
  };

  const {
    mutate: mutateDeleteClient,
    isSuccess: isSuccessdelete,
    isError: isErrordelete,
  } = useMutation(deleteClient);

  useEffect(() => {
    if (isErrordelete || isSuccessdelete)
      setOpenModalDelete(null);

    if (isSuccessdelete) {
      refetchList();
      AlertService.sendAlert([
        {
          text: "Cliente apagado com sucesso.",
          nextPage: false,
        },
      ]);
    }
  }, [isSuccessdelete, isErrordelete]);

  const { isLoading, refetch: refetchList } = useQuery(
    ["clients", currentPage, perPage, search, columnSort, columnOrder],
    () => list({ page: currentPage, perPage, search, sort: columnSort, order: columnOrder }),
    {
      onSuccess: (data) => {
        setClientList(data.data.map((client: any, key: number) => {
          const { data } = client;
          return {
            ...data,
            key: key,
          };
        }));
        setTotal(data.meta.total);
        setPerPage(data.meta.per_page);
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: notificationList} = useQuery(
    ["notificationChangeReferrerList"],
    () => getNotificationChangeReferrerList(),
    {
      onSuccess: (data) => {
        setNotificationReferrerList(data.data.map((client: any, key: number) => {
          return {
            client_id: client.notifiable.client_id,
            key: key
          };
        }));
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  
  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      sorter: true,
      key: "name",
      id: "teste",
      render: (_: any, record: any) =>{
        return cutLongText(record.name, 40);
      },
      onCell: (record: any) => {
        let iconBoolean = notificationReferrerList.some((item: any) => item.client_id === record.id)
        return {
          className: iconBoolean ? 'hasChangedRequest' : ''
        };
      },
    },
    { title: "Organização", dataIndex: "organization", sorter: true, key: "organization" },
    { title: "Email", dataIndex: "email", sorter: true, key: "email" },
    { title: "Contacto", dataIndex: "phone", sorter: true, key: "phone" },
    {
      title: "Status",
      dataIndex: "status",
      sorter: false,
      key: "status",
      render: (value: any) => (
        <span>{value == true ? "Ativo" : "Inativo"}</span>
      ),
    },
    {
      title: "Ações",
      key: "action",
      width: "15%",
      render: (_: any, record: any) => (
        <Space size="small">
          <Tooltip placement="top" title="Visualizar">
            <Button
              type="default"
              shape="circle"
              size="middle"
              icon={<EyeOutlined />}
              onClick={() => setOpenModalDetail(record)}
            />
          </Tooltip>
          <Tooltip placement="top" title="Editar">
            <Button
              type="default"
              shape="circle"
              size="middle"
              icon={<EditOutlined />}
              onClick={() => goToEditForm(record.id)}
            />
          </Tooltip>
          <Tooltip placement="top" title="Apagar">
            <Button
              type="default"
              shape="circle"
              size="middle"
              icon={<DeleteOutlined />}
              onClick={() => record.has_business ? setOpenModalCantDelete(record.id) : setOpenModalDelete(record.id)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleTableChange = (
    pagination: PaginationProps,
    filters: Record<string, FilterValue | null>,
    sorter: any,
  ) => {
    if (pagination.current != currentPage) {
      setCurrentPage(pagination.current);
    }

    if (pagination.pageSize != perPage) {
      setPerPage(pagination.pageSize);
    }

    if (sorter.order) {
      setColumnSort(sorter.field);
      setColumnOrder(sorter.order);
    }
  };

  return (
    <div className="content_page">
      <DisplayAlert />
      <Card bodyStyle={{ padding: 16 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Form
            form={formSearch}
            style={{ margin: 0 }}
            name="search_user"
            layout="vertical"
            className="form_search_horizontal"
            autoComplete="off"
          >
            <Form.Item name="search_user" className="item_search" style={{ margin: 0 }}>
              <Search
                style={{ margin: 0 }}
                onSearch={onSearch}
                placeholder="Pesquisa por nome do utilizador ou email"
                enterButton="Pesquisar"
                allowClear
                size="large"
                maxLength={255}
              />
            </Form.Item>
          </Form>
          <Button type="primary" size="large" onClick={() => goToForm()}>
            Novo cliente
          </Button>
        </div>
        <Divider />
        <GenericTable
          columns={columns}
          dataSource={clientList}
          pagination={{
            current: currentPage,
            pageSize: perPage,
            total: total,
            showSizeChanger: true,
            onShowSizeChange: (current, size) => {
              setPerPage(size);
            }
          }}
          loading={isLoading}
          totalChildren={
            <div className="zone_result_size_n_export">
              <ShowDataLength size={total} className="show_result_size" />
            </div>
          }
          empty={{
            createTheFirst: !clientList.length && !search,
            textOne: "Esta tabela encontra-se vazia, uma vez que ainda não foram criado clientes.",
            textTwo: "Para começar, crie um novo cliente.",
            link: "/clients/new",
          }}
          handleTableChange={handleTableChange}
        />
      </Card>
      <ConfirmModal
        open={!!openModalDelete}
        title="Tem a certeza que deseja apagar o cliente?"
        content="Nenhum negócio será afetado"
        okText="Apagar"
        cancelText="Voltar"
        onOk={() => openModalDelete ? mutateDeleteClient(openModalDelete) : setOpenModalDelete(null)}
        onCancel={() => setOpenModalDelete(null)}
      ></ConfirmModal>
      <ConfirmModal
        open={!!openModalCantDelete}
        title="Não é possível apagar este cliente pois existem negócios associados à ele"
        content="Não é possível apagar este cliente pois existem negócios associados a ele."
        okText="Editar associações"
        cancelText="Voltar"
        onOk={() => openModalCantDelete ? navigate(`/clients/${openModalCantDelete}/business`) : setOpenModalCantDelete(null)}
        onCancel={() => setOpenModalCantDelete(null)}
      ></ConfirmModal>
      {openModalDetail && (
        <DetailComponent
          client={openModalDetail}
          open={!!openModalDetail}
          close={() => setOpenModalDetail(null)}
          title="Mais detalhes"
          readOnly={true}
        />
      )}
    </div>
  );
}

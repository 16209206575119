import { Card, Col, Divider, Row, Timeline, Typography } from "antd";
import { getLabel } from "../../../Enums/TypeOfKanban.enums";

const { Title, Text } = Typography;

export default function TimeLine(props: any) {
    const columns: { [key: string]: string } = {
        'client_id': 'cliente',
        'name': 'nome',
        'value': 'valor',
        'type_product': 'produto',
        'business_kanban_id': 'kanban',
        'stage': 'etapa',
        'state_business': 'estado',
        'referrer_id': 'referenciador',
        'referrer_commission': 'comissão do referenciador',
        'referrer_commission_method': 'método de comissão do referenciador',
        'coach_id': 'coach',
        'coach_commission': 'comissão do coach',
        'coach_commission_method': 'método de comissão do coach',
        'closer_id': 'closer',
        'closer_commission': 'comissão do closer',
        'closer_commission_method': 'método de comissão do closer',
        'description': 'descrição',
        'index': 'índice',
        'closed_state': 'estado fechado',
        'closed_at': 'fechado em',
        'canceled_at': 'cancelado em',
        'product_id': 'produto'
    }

    const formatValue = (key: string, value: any) => {
        const currency = ['value', 'referrer_commission', 'coach_commission', 'closer_commission']

        if (currency.includes(key)) {
            return new Intl.NumberFormat('pt-PT', { style: 'currency', currency: 'EUR' }).format(value)
        }

        if (key === 'business_kanban_id') {
            return getLabel(value);
        }

        return value;
    }

    const formatDate = (date: any) => {
        return new Date(date).toLocaleDateString('pt-PT')
    }

    const timelineMessage = (item: any) => {
        console.info(item);
        const { event, old_values, new_values, created_at } = item;

        const ignoreKeys = ['index']

        let message: any[] = [];

        if (event === 'created') {
            message.push(<div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text>Criação do negócio</Text>
                <Text type="secondary">{formatDate(created_at)}</Text>
            </div>);
        } else {
            Object.keys(new_values).forEach((key) => {
                if (new_values[key] !== old_values[key]) {
                    if (!ignoreKeys.includes(key)) {
                        if (event === 'updated' && key === 'canceled_at') {
                            message.push(<div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Text>{new_values[key] ? 'Negócio cancelado' : 'Negócio reaberto'}</Text>
                                <Text type="secondary">{formatDate(created_at)}</Text>
                            </div>);
                        } else if (event === 'updated' && key === 'closed_at') {
                            message.push(<div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Text>Negócio fechado</Text>
                                <Text type="secondary">{formatDate(created_at)}</Text>
                            </div>);
                        } else if (event === 'updated' && old_values[key]) {
                            message.push(<div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Text>Alterado {columns[key]} de {formatValue(key, old_values[key])} para {formatValue(key, new_values[key])}</Text>
                                <Text type="secondary">{formatDate(created_at)}</Text>
                            </div>);
                        } else if (event === 'updated' && !old_values[key]) {
                            message.push(<div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Text>Alterado {columns[key]} para {formatValue(key, new_values[key])}</Text>
                                <Text type="secondary">{formatDate(created_at)}</Text>
                            </div>);
                        }
                    }
                }
            });
        }

        return message;
    }



    const timelineItems = [
        ...props.historic.map((item: any) => ({
            children: timelineMessage(item),
        })).filter((item: any) => item.children.length > 0)
    ]

    return (
        <Card>
            <Row gutter={8}>
                <Col span={24}>
                    <Title style={{ margin: '0px 0px 16px 0px' }} level={5}>Histórico de atividades</Title>
                </Col>
            </Row>
            <Divider />
            <Row gutter={8} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Timeline
                    mode="alternate"
                    items={timelineItems}
                />
            </Row>
        </Card>
    )
}
import { MoreOutlined } from "@ant-design/icons";
import { Button, Col, Dropdown, MenuProps, Row } from "antd"

export default function Note(props: any) {
    const { msg } = props

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={() => props.edit(props)}>
                    Editar
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={() => props.delete(props)}>
                    Apagar
                </a>
            )
        }
    ];

    return (
        <Row gutter={8}>
            <Col span={22}>
                <div style={{ color: '#262626' }}>{msg}</div>
            </Col>
            {props.enableAction && <Col offset={1} span={1}>
                <Dropdown menu={{ items }} placement="bottomRight" arrow>
                    <Button
                        shape="circle"
                        size="small"
                        icon={<MoreOutlined />}
                    />
                </Dropdown>
            </Col>}
        </Row>
    )
}
import Footer from "./Footer"
import Message from "./Message"

export default function Note(props: any) {
    return (
        <div style={{
            backgroundColor: '#F5F5F5',
            padding: 15,
            borderRadius: '8px'
        }}>
            <Message {...props} />
            <Footer {...props} />
        </div>
    )
}
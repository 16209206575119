import { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import Layout from "./Layout/Layout";
import Login from "./Pages/Login/login";
import { ConfigProvider } from "antd";
import ptPT from 'antd/lib/locale/pt_PT';
// import { useSocket } from "./services/socketHook";
// import './laravel-echo';


export default function App({ children, header }: any) {
  const queryClient = new QueryClient();
  const userStorage = localStorage.getItem("user") || null;
  const [user] = useState(userStorage ? JSON.parse(userStorage) : null);

  return (
    <ConfigProvider
      locale={ptPT}
      theme={{
        token: {
          colorPrimary: "#141414",
          colorBgLayout: "#F0F0F0",
        },
        components: {
          Button: {
            colorPrimary: "#0E3441",
            colorLink: "#0E3441",
            colorPrimaryHover: "#428B93",
            boxShadow: '0'
          },
          Menu: {
            itemColor: '#404040',
            itemSelectedBg: '#14424C',
          },
          Modal: {
            titleColor: '#14424C'
          },
          Dropdown: {
            boxShadowSecondary: '0',
            controlItemBgHover: 'none',
          },
        }
      }}
    >
      <QueryClientProvider client={queryClient}>
        {!user ? <Login /> : <Layout />}
      </QueryClientProvider>
    </ConfigProvider>
  );
}

import { Tabs } from 'antd';
import { TabsProps } from 'antd/lib';
import TabCurrent from './Tab/Current';
// import TabHistoric from "./Tab/Historic";

export default function Payment() {
  // const items: TabsProps['items'] = [
  //     {
  //       key: '1',
  //       label: 'Atual',
  //       children: <TabCurrent />,
  //     },
  //     {
  //       key: '2',
  //       label: 'Histórico',
  //       children: <TabHistoric />,
  //     }
  // ];

  return (
    // <Tabs
    //   defaultActiveKey="1"
    //   tabBarStyle={{
    //     backgroundColor: '#FFF',
    //     padding: '8px 52px 0px 24px',
    //     color: '#000',
    //     marginBottom: 0,
    //   }}
    //   size="large"
    //   destroyInactiveTabPane={true}
    //   items={items}
    // />
    <TabCurrent />
  );
}

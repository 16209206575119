import { Line } from "@ant-design/plots";
import { Empty } from "antd";

export function LinesGraphic({data}: any) {
    const GraphicLine = () => {
        const config = {
            data: data,
            xField: 'month',
            yField: 'values',
            colorField: 'name',
            interaction: {
                tooltip: {
                    marker: false,
                },
            },
            style: {
                lineWidth: 2,
            },
        };
        return <Line {...config} />;
      };

    return (data.length > 0 ? <GraphicLine /> : <Empty />);
}
import { Button, Col, Divider, Form, Row, Input, FormInstance } from "antd";
import { useMutation } from "react-query";
import { storeBusinessFollowup, storeBusinessNotes } from "../../../services/business.service";

const { TextArea } = Input;

export default function FormNotes(props: any) {
    const formNotes: FormInstance = props.formNotes;

    const { isLoading: isLoadingStore, mutate: store } = useMutation(storeBusinessNotes, {
        onSuccess: () => {
            formNotes.resetFields()
            props.handlerOpenFormNotes()
            props.refetch()
        }
    })

    const save = () => {
        formNotes
            .validateFields()
            .then((values) => {
                if (formNotes.getFieldValue('id'))
                    values.id = formNotes.getFieldValue('id');

                store({ ...values, business_id: props.businessId, created_by: props.userId });
            })
    }

    return (
        <>
            <Divider />
            <Row>
                <span style={{ fontSize: 14, color: '#141414' }}>
                    {props.user}
                </span>
                <Col span={24}>
                    <Form
                        form={formNotes}
                        layout="vertical"
                    >
                        <Form.Item
                            name="content"
                            rules={[{ required: true, message: 'Campo obrigatório' }]}
                        >
                            <TextArea
                                showCount
                                rows={4}
                                size="large"
                                maxLength={100}
                                placeholder="Escreva sua nota...."
                                style={{ height: 120, resize: 'none' }}
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item>
                            <Row align="middle" style={{ marginTop: 16 }}>
                                <Col span={12}>
                                    <span style={{ fontSize: 12, color: '#8C8C8C', fontWeight: 400 }}>
                                        {new Date().toLocaleString("pt-PT") + ""}
                                    </span>
                                </Col>
                                <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', gap: 16 }}>
                                    <Button type="default" size="large" onClick={props.handlerOpenFormNotes}>Cancelar</Button>
                                    <Button
                                        type="primary"
                                        size="large"
                                        loading={isLoadingStore}
                                        onClick={save}
                                    >Gravar</Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </>
    )
}
import { QuestionCircleFilled } from "@ant-design/icons";
import { Card, Col, Row, Statistic, Tooltip } from "antd";

export function StatisticCards({
    items,
    gutter
}: any) {
    return (
        <Row gutter={gutter}>
            <Col span={4} >
                <Card bordered={true}>
                    <Statistic
                        title="Leads"
                        value={items.leads}
                        valueStyle={{
                            borderRadius: '8px',
                            borderColor: '#D9D9D9',
                            borderWidth: '1px'
                        }}
                    />
                </Card>
            </Col>
            <Col span={4} >
                <Card bordered={true}>
                    <Statistic
                        title="Negócios abertos"
                        value={items.newBusiness}
                        valueStyle={{
                            borderRadius: '8px',
                            borderColor: '#D9D9D9',
                            borderWidth: '1px'
                        }}
                    />
                </Card>
            </Col>
            <Col span={4} >
                <Card bordered={true}>
                    <Statistic
                        title="Negócios fechados"
                        value={items.closedBusiness}
                        valueStyle={{
                            borderRadius: '8px',
                            borderColor: '#D9D9D9',
                            borderWidth: '1px'
                        }}
                    />
                </Card>
            </Col>
            <Col span={4} >
                <Card bordered={true}>
                    <Statistic
                        title="Negócios ganhos"
                        value={items.wonBusiness}
                        valueStyle={{
                            borderRadius: '8px',
                            borderColor: '#D9D9D9',
                            borderWidth: '1px'
                        }}
                    />
                </Card>
            </Col>
            <Col span={4} >
                <Card bordered={true}>
                    <Statistic
                        title="Negocios perdidos "
                        value={items.loseBusiness}
                        valueStyle={{
                            borderRadius: '8px',
                            borderColor: '#D9D9D9',
                            borderWidth: '1px'
                        }}
                    />
                </Card>
            </Col>
            <Col span={4} >
                <Card bordered={true}>
                    <Statistic
                        title={(<div style={{ display: 'flex', justifyContent: 'space-between' }}><span style={{ paddingRight: 4 }}>Novos clientes</span><Tooltip title="Os valores de novos clientes são afetados exclusivamente pelo filtro de data."><QuestionCircleFilled /></Tooltip></div>)}
                        value={items.newClients}
                        valueStyle={{
                            borderRadius: '8px',
                            borderColor: '#D9D9D9',
                            borderWidth: '1px'
                        }}
                    />
                </Card>
            </Col >
        </Row >
    )
}
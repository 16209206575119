import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  Tooltip,
  Typography,
} from 'antd';
import Search from 'antd/es/input/Search';
import { useEffect, useState } from 'react';
import GenericTable from '../../../../components/Commons/Table/generic-table.component';
import ShowDataLength from '../../../../components/Commons/Table/results-length-table.component';
import { EyeOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  authorizedAccess,
  authorizedUser,
  cutLongText,
} from '../../../../services/utils';
import { PaginationProps } from 'antd/lib';
import { FilterValue } from 'antd/es/table/interface';
import { useMutation, useQuery } from 'react-query';
import { madePayment, payments } from '../../../../services/business.service';
import DetailComponent from '../../../../components/Payments/details.component';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { getAll } from '../../../../services/users.service';

const userLogged: any = localStorage.getItem('user');

export default function TabCurrent() {
  const [user, setUser] = useState(userLogged ? JSON.parse(userLogged) : null);
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);
  const [perPage, setPerPage] = useState<number | undefined>(10);
  const [search, setSearch] = useState<string | undefined>(undefined);
  const [agent, setAgent] = useState<number | undefined>(undefined);
  const [listBusiness, setListBusiness] = useState<any>();
  const [userList, setUserList] = useState<[]>([]);
  const [formSearch] = Form.useForm();
  const [openModalDetail, setOpenModalDetail] = useState<any | null>(null);
  const [total, setTotal] = useState<number>(0);
  const [rangeDate, setRangeDate] = useState<string[]>([]);
  const [pendent, setPendent] = useState<boolean>(false);
  const [canWrite, setCanWrite] = useState<boolean>(false);

  const { isLoading: userLoading, refetch: refetchList } = useQuery(
    ['payments', currentPage, perPage, search, rangeDate, pendent, agent],
    () =>
      payments({
        page: currentPage,
        perPage,
        search,
        rangeDate,
        pendent,
        agent,
      }),
    {
      onSuccess: (data) => {
        let businesses: any = [];
        data.data.map((user: any, key: number) => {
          businesses.push({
            ...user,
            key: key,
          });
        });
        setListBusiness(businesses);
        setTotal(data.meta.total);
        setPerPage(data.meta.per_page);
      },
      refetchOnWindowFocus: false,
    }
  );

  useQuery('userList', () => getAll(), {
    refetchOnWindowFocus: false,
    onSuccess: (users) => {
      setUserList(users.data);
    },
  });

  useEffect(() => {
    if (user) {
      setCanWrite(authorizedUser(user.canAccess, ['manager-payments:write']));
    }
  }, [user, authorizedUser]);

  const {
    mutate: madePaymentMutate,
    isLoading: madePaymentLoading,
    isSuccess: isSuccessMadePayment,
    data: dataMadePayment,
  } = useMutation(madePayment);

  useEffect(() => {
    if (isSuccessMadePayment && dataMadePayment) {
      const updatedListBusiness = listBusiness.map((item: any) => {
        if (item.id === dataMadePayment.data.id) {
          return {
            ...item,
            paid_at: dataMadePayment.data.paid_at,
          };
        }
        return item;
      });

      setListBusiness(updatedListBusiness);
    }
  }, [isSuccessMadePayment, dataMadePayment]);

  const onSearch = (search_value: any) => {
    setCurrentPage(1);
    setSearch(search_value);
  };

  const handleTableChange = (
    pagination: PaginationProps,
    filters: Record<string, FilterValue | null>,
    sorter: any
  ) => {
    if (pagination.current != currentPage) {
      setCurrentPage(pagination.current);
    }

    if (pagination.pageSize != perPage) {
      setPerPage(pagination.pageSize);
      setCurrentPage(1);
    }
  };

  const handlePaymentStatusChange = (record: any) => {
    madePaymentMutate(record.id);
  };

  const columns = [
    {
      title: 'Negócio',
      dataIndex: 'name',
      key: 'name',
      render: (_: any, record: any) => cutLongText(record.business.name, 40),
    },
    {
      title: 'Status do negócio',
      dataIndex: 'status',
      key: 'status',
      render: (_: any, record: any) =>
        cutLongText(record.business.closed_state, 40),
    },
    {
      title: 'Mês do pagamento',
      dataIndex: 'month',
      key: 'month',
      render: (_: any, record: any) =>
        cutLongText(record.month_payment_date, 40),
    },
    {
      title: 'Status do pagamento',
      dataIndex: 'month',
      key: 'month',
      render: (_: any, record: any) => {
        return (
          <div>
            {!madePaymentLoading && (
              <Row>
                {canWrite && (
                  <Col>
                    <Checkbox
                      checked={record.paid_at ? true : false}
                      onChange={() => handlePaymentStatusChange(record)}
                    />
                  </Col>
                )}
                <Col style={{ marginLeft: 10 }}>
                  {record.paid_at ? 'Pago' : 'Pendente'}
                </Col>
              </Row>
            )}
            {madePaymentLoading && (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 24,
                    }}
                    spin
                  />
                }
                size="large"
                tip="Carregando"
              />
            )}
          </div>
        );
      },
    },
    {
      title: 'Valor potencial a receber',
      dataIndex: 'commissions',
      sorter: false,
      key: 'commissions',
      render: (_: any, record: any) => {
        return (
          <div>
            {parseFloat(record.value).toLocaleString('pt-PT', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
            })}
          </div>
        );
      },
    },
    {
      title: 'Valor de comissões a pagar',
      dataIndex: 'commissions',
      sorter: false,
      key: 'commissions',
      render: (_: any, record: any) => {
        let commissions = [];

        const closerComission = record.business.closer_commission
          ? parseFloat(record.business.closer_commission)
          : 0;
        const coachComission = record.business.coach_commission
          ? parseFloat(record.business.coach_commission)
          : 0;
        const referrerComission = record.business.referrer_commission
          ? parseFloat(record.business.referrer_commission)
          : 0;

        if (agent) {
          if (record.business.closer_id === agent)
            commissions.push(closerComission);
          if (record.business.coach_id === agent)
            commissions.push(coachComission);
          if (record.business.referrer_id === agent)
            commissions.push(referrerComission);
        } else {
          commissions.push(closerComission, coachComission, referrerComission);
        }

        const totalCommissions = commissions.reduce(
          (accumalator, currentValue) => accumalator + currentValue,
          0
        );

        return (
          <div>
            {totalCommissions.toLocaleString('pt-PT', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
            })}
          </div>
        );
      },
    },
    {
      title: 'Ações',
      key: 'action',
      width: '10%',
      render: (_: any, record: any) => (
        <Space size="small">
          <Tooltip placement="top" title="Visualizar">
            <Button
              type="default"
              shape="circle"
              size="middle"
              icon={<EyeOutlined />}
              onClick={() => setOpenModalDetail(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div className="content_page">
      <Card>
        <div>
          <Form
            form={formSearch}
            name="search_current"
            layout="vertical"
            className="form_search_horizontal"
            autoComplete="off"
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 16,
              alignItems: 'center',
            }}
          >
            <div>
              <Form.Item name="search_current" className="item_search">
                <Search
                  onSearch={onSearch}
                  placeholder="Pesquisar"
                  enterButton="Pesquisar"
                  allowClear
                  size="large"
                  maxLength={255}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item name="period">
                <DatePicker.RangePicker
                  style={{ width: 300 }}
                  size="large"
                  format={'MM/YYYY'}
                  picker="month"
                  onChange={(date, dateString) => {
                    dateString[0] =
                      dateString[0] != '' ? `01/${dateString[0]}` : '';
                    dateString[1] =
                      dateString[1] != '' ? `30/${dateString[1]}` : '';
                    setRangeDate(dateString);
                  }}
                  locale={{
                    ...locale,
                    lang: {
                      ...locale.lang,
                      rangePlaceholder: ['Início', 'Fim'],
                    },
                  }}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item name="period">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 8,
                  }}
                >
                  <Switch
                    checked={pendent}
                    onChange={() => {
                      setCurrentPage(1);
                      setPendent(!pendent);
                    }}
                  />
                  <span>{!pendent ? 'Pendentes' : 'Pagos'}</span>
                </div>
              </Form.Item>
            </div>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Form.Item name="agent">
                <Select
                  size="large"
                  style={{ width: 300 }}
                  allowClear
                  placeholder="Comissionistas"
                  onSelect={(id) => {
                    setAgent(id);
                  }}
                  onClear={() => {
                    setAgent(undefined);
                  }}
                >
                  {userList &&
                    userList.map((value: any) => (
                      <Select.Option key={value.id} value={value.id}>
                        {value.name}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </div>
          </Form>
        </div>
        <Divider />

        <GenericTable
          columns={columns}
          dataSource={listBusiness}
          pagination={{
            current: currentPage,
            pageSize: perPage,
            total: total,
            showSizeChanger: true,
          }}
          loading={userLoading}
          totalChildren={
            <div className="zone_result_size_n_export">
              <ShowDataLength size={total} className="show_result_size" />
            </div>
          }
          empty={{
            createTheFirst: !listBusiness && !search,
            textOne:
              'Esta tabela encontra-se vazia, uma vez que ainda não foram criados pagamentos.',
            textTwo: '',
            link: '',
          }}
          handleTableChange={handleTableChange}
        />
      </Card>
      {openModalDetail && (
        <DetailComponent
          payment={openModalDetail}
          open={!!openModalDetail}
          close={() => setOpenModalDetail(null)}
          title="Mais detalhes"
          readOnly={true}
        />
      )}
    </div>
  );
}

export enum EnumStateBusiness {
    'aberto' = 'Aberto',
    'fechado' = 'Fechado',
}

export enum EnumStateClosed {
    'aberto' = 'Aberto',
    'ganho' = 'Ganho',
    'perdido' = 'Perdido',
}


import { InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import { Card, Col, Divider, Form, Input, Row, Typography } from "antd";

const { Title } = Typography;

export default function Client(props: any) {
    return (
        <Card>
            <Row gutter={8}>
                <Col span={24}>
                    <Title
                        style={{ margin: '0px 0px 16px 0px' }}
                        level={5}>Informações do Cliente</Title>
                </Col>
            </Row>
            <Divider />
            {props.data.getFieldValue('name') ? <Form
                form={props.data}
                layout="vertical"
            >
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome"
                            name="name"
                        >
                            <Input
                                size="large"
                                maxLength={255}
                                placeholder="Nome do cliente"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="E-mail"
                            name="email"
                        >
                            <Input
                                size="large"
                                maxLength={255}
                                placeholder="E-mail"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="Contacto"
                            name="phone"
                        >
                            <Input
                                size="large"
                                maxLength={255}
                                placeholder="Contacto"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form> : <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center' }}>
                <Title level={5} style={{ margin: 0 }}>
                    <InfoCircleFilled style={{ color: '#14424C', fontSize: 18, paddingRight: 12 }} />
                    Este dado foi apagado devido a RGPD
                </Title>
            </div>}
        </Card>
    )
}
import { get, patch, put, post } from "./api.service";

export const getNotification = () => {
    return get(`notifications/v1`);
};

export const acceptRequestToChangeReferrer = ({ request_id, notification_id }: { request_id: number, notification_id: number }) => {
    return put(`uniclients/v1/request-accepted/${request_id}/notification/${notification_id}`);
}
export const rejectRequestToChangeReferrer = ({ request_id, notification_id }: { request_id: number, notification_id: number }) => {
    return put(`uniclients/v1/request-rejected/${request_id}/notification/${notification_id}`);
}

export const markAllAsRead = () => {
    return patch(`notifications/v1/mark-all-as-read`);
}

export const requestByUser = (data: any) => {
    return post(`notifications/v1/request-by-user`, data);
}
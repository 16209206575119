import { get, post } from "./api.service";

export const listUserRole = (role?: string) => {
  return get(`users/v2/list/${role}`);
};

export const getUser = () => {
  return get("/user");
};

export const authenticate = (data: any) => {
  return post("users/v2/login", data);
};

export const changePassword = (data: any) => {
  return post(`users/v2/${data.id}/force-new-password`, data.data);
};
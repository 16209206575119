import { Button, Card, Col, Divider, Form, Row, Typography } from "antd";
import EmptyList from "../../../components/Commons/Empty";
import Note from "../../../components/Business/Notes";
import { useState } from "react";
import FormNotes from "./FormNotes";
import { useMutation, useQuery } from "react-query";
import { delBusinessNotes, getBusinessNotes } from "../../../services/business.service";
import ConfirmModal from "../../../components/Commons/Modal/confirm-modal.component";

const { Title } = Typography;

export default function Notes(props: any) {
    const [openFormNotes, setOpenFormNotes] = useState(false);
    const [notes, setNotes] = useState<any>([])
    const [formNotes] = Form.useForm();
    const [openModal, setOpenModal] = useState(false)
    const [propsModal, setPropsModal] = useState<any>({})

    const handlerOpenFormNotes = () => {
        setOpenFormNotes(!openFormNotes)
    }

    const { refetch } = useQuery(['businessNotes', props.id], () => getBusinessNotes(props.id), {
        refetchOnWindowFocus: false,
        onSuccess: (data: any) => {
            setNotes(data.data)
        }
    })

    const { mutate: delNote } = useMutation(delBusinessNotes, {
        onSuccess: () => {
            setOpenModal(false);
            loadNotes();
        }
    });

    const loadNotes = () => {
        refetch()
    }

    const editNote = (note: any) => {
        setPropsModal({
            title: 'Tem certeza que deseja editar esta nota?',
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => openFormAndPopulateEdit(note)
        })
        setOpenModal(true);

    }

    const openFormAndPopulateEdit = (note: any) => {
        setOpenModal(false);

        formNotes.setFieldsValue({
            content: note.content,
            id: note.id
        });
        setOpenFormNotes(true)
    }

    const deleteNote = (note: any) => {
        setPropsModal({
            title: 'Tem certeza que deseja apagar esta nota?',
            okText: 'Apagar',
            cancelText: 'Não',
            onOk: () => delNote(note.id)
        })
        setOpenModal(true);
    }

    return (
        <Card style={{ marginTop: 15 }}>
            <Row gutter={8}>
                <Col
                    span={24}
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <Title
                        style={{ margin: '0px 0px 16px 0px' }}
                        level={5}>Notas</Title>
                    {!openFormNotes
                        && <Button type="default" size="large" onClick={handlerOpenFormNotes}>Adicionar nota</Button>}
                </Col>
            </Row>
            <Divider />
            <Row gutter={[8, 8]}>
                {notes.length > 0 ? notes.map((note: any) => (
                    <Col span={24}>
                        <Note
                            id={note.id}
                            msg={note.content}
                            name={note.created_by.name}
                            enableAction={props.data.id === note.created_by.id}
                            date={new Date(note.created_at).toLocaleString('pt-BR', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                            })}
                            edit={(edit: any) => editNote(note)}
                            delete={(edit: any) => deleteNote(note)}
                        />
                    </Col>
                )) : <EmptyList text="Nenhuma nota criada" />}
            </Row>

            {openFormNotes &&
                <FormNotes
                    businessId={props.id}
                    userId={props.data.id}
                    user={props.data.name}
                    handlerOpenFormNotes={handlerOpenFormNotes}
                    refetch={loadNotes}
                    formNotes={formNotes}
                />
            }

            <ConfirmModal
                open={openModal}
                title={propsModal.title}
                okText={propsModal.okText}
                cancelText={propsModal.cancelText}
                onOk={propsModal.onOk}
                onCancel={() => setOpenModal(false)}
            ></ConfirmModal>
        </Card>
    )
}
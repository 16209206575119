import { dashParams } from "../Interfaces/Dashboard.interfaces";
import { get } from "./api.service";
const uniUrl = 'unidashboard/v1';
export const getStatisticData = (params: dashParams) => {
    const query = getQueryParams(params);
    if (query.length > 0)
        return get(`${uniUrl}/numbers?${query.join('&')}`);

    return get(uniUrl + '/numbers');
}

export const getReportColumnsData = (params: dashParams) => {
    const query = getQueryParams(params);

    if (query.length > 0)
        return get(`${uniUrl}/toReceive?${query.join('&')}`);

    return get(uniUrl + '/toReceive');
}

export const getReportLinesData = (params: dashParams) => {

    const query = getQueryParams(params);

    if (query.length > 0)
        return get(`${uniUrl}/newBusiness?${query.join('&')}`);


    return get(uniUrl + '/newBusiness');
}

export const getFunnelTypes = () => {
    return get('business/v1/kanban/types');
}

export const getFunnelLinesData = (params: dashParams) => {
    const query = getQueryParams(params);

    if (query.length > 0)
        return get(`${uniUrl}/historyByKanban/${params.type}?${query.join('&')}`);

    return get(`${uniUrl}/historyByKanban/${params.type}`);
}
const getQueryParams = ({
    client,
    startDate,
    endDate,
    type,
    referrer,
    businessCoach,
    closer,
    user
}: dashParams) => {
    const params = [];
    if (client) {
        params.push(`client=${client}`);
    }
    if (startDate) {
        params.push(`startDate=${startDate}`);
    }
    if (endDate) {
        params.push(`endDate=${endDate}`);
    }
    if (type) {
        params.push(`type=${type}`);
    }
    if (referrer) {
        params.push(`referrer=${referrer}`);
    }
    if (businessCoach) {
        params.push(`businessCoach=${businessCoach}`);
    }
    if (closer) {
        params.push(`closer=${closer}`);
    }
    if (user) {
        params.push(`user=${user}`);
    }
    return params;
}
import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { EnumDashboardTabs } from "../../Enums/DashboardTabs.enums";
import Report from "./Report";
import Funnel from "./Funnel";
import DisplayAlert from "../../components/Commons/Alert";

export function Dashboard() {
    const [activeTab, setActiveTab] = useState('1');
    const [dashboardType, setDashboardType] = useState<string | null>(EnumDashboardTabs.Funnel);
    const userStorage = localStorage.getItem("user") || null;
    const [user, setUser] = useState(
        userStorage ? JSON.parse(userStorage) : null
    );
    useEffect(() => {
        switch (activeTab) {
            case '1':
                setDashboardType(EnumDashboardTabs.Report);
                break;
            case '2':
                setDashboardType(EnumDashboardTabs.Funnel);
                break;
        }

    }, [activeTab]);

    return (
        <div>
            <Tabs
                tabBarStyle={{
                    backgroundColor: '#FFF',
                    padding: '8px 52px 0px 24px',
                    color: '#000',
                    marginBottom: 0
                }}
                size="large"
                destroyInactiveTabPane={true}
                defaultActiveKey={activeTab}
                onChange={(key) => setActiveTab(key)}
                items={[
                    {
                        key: '1',
                        label: `Relatório`,
                        children: dashboardType === EnumDashboardTabs.Report && <>
                            <div style={{ margin: 24 }}>
                                <DisplayAlert />
                            </div>
                            <Report user={user} />
                        </>,
                    },
                    {
                        key: '2',
                        label: `Tipos de Funil`,
                        children: dashboardType === EnumDashboardTabs.Funnel && <>
                            <div style={{ margin: 24 }}><DisplayAlert /></div>
                            <Funnel user={user} />
                        </>
                    }
                ]} >
            </Tabs>
        </div >
    );
} 
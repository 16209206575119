import { Tabs } from "antd";
import { useEffect, useState } from "react";
import { EnumTypeKanban } from "../../Enums/TypeOfKanban.enums";
import { BusinessBoardPage } from "./kanban";

export function BusinessPage() {
    const [activeTab, setActiveTab] = useState('1');
    const [typeKanban, setTypeKanban] = useState<string | null>(EnumTypeKanban.COACHING_BUSINESS_CLUB);

    useEffect(() => {
        switch (activeTab) {
            case '1':
                setTypeKanban(EnumTypeKanban.COACHING_BUSINESS_CLUB);
                break;
            case '2':
                setTypeKanban(EnumTypeKanban.DIAGNOSIS);
                break;
            case '3':
                setTypeKanban(null);
                break;
        }

    }, [activeTab]);

    return (
        <div>
            <Tabs
                tabBarStyle={{
                    backgroundColor: '#FFF',
                    padding: '8px 52px 0px 24px',
                    color: '#000',
                    marginBottom: 0
                }}
                size="large"
                destroyInactiveTabPane={true}
                defaultActiveKey={activeTab}
                onChange={(key) => setActiveTab(key)}
                items={[
                    {
                        key: '1',
                        label: `Clube de empresários`,
                        children: typeKanban === EnumTypeKanban.COACHING_BUSINESS_CLUB && <BusinessBoardPage type={EnumTypeKanban.COACHING_BUSINESS_CLUB} />,
                    },
                    {
                        key: '2',
                        label: `Diagnóstico`,
                        children: typeKanban === EnumTypeKanban.DIAGNOSIS && <BusinessBoardPage type={EnumTypeKanban.DIAGNOSIS} />,
                    },
                    {
                        key: '3',
                        label: `Histórico de negócios fechados`,
                        children: typeKanban === null && <BusinessBoardPage type="HISTORY" />
                    }
                ]} >
            </Tabs>
        </div >
    );
} 
import { del, get, post, put } from './api.service';

const listHistory = () => {
  return get(`business/v1/kanban/history`);
};

const getAllKanban = ({
  typeKanban,
  search,
  rangeDate,
  ownerBusiness,
  sortBusiness,
  clientBusiness,
}: {
  typeKanban: string;
  search?: string;
  rangeDate?: string[];
  ownerBusiness?: number;
  sortBusiness?: string;
  clientBusiness?: number;
}) => {
  const params = [];
  if (search) {
    params.push(`search=${search}`);
  }

  if (rangeDate?.length === 2) {
    params.push(`start_date=${rangeDate[0]}`);
    params.push(`end_date=${rangeDate[1]}`);
  }

  if (ownerBusiness) {
    params.push(`owner_business=${ownerBusiness}`);
  }

  if (sortBusiness) {
    params.push(`sort=${sortBusiness}`);
  }

  if (clientBusiness) {
    params.push(`client_business=${clientBusiness}`);
  }

  if (params.length > 0) {
    return get(`business/v1/kanban/${typeKanban}?${params.join('&')}`);
  }

  return get(`business/v1/kanban/${typeKanban}`);
};

const createColumnBoard = ({ column }: { column: any }) => {
  return post(`business/v1/kanban/store`, column);
};

const editColumnBoard = ({
  boardId,
  column,
}: {
  boardId: number;
  column: any;
}) => {
  return put(`business/v1/kanban/${boardId}`, column);
};

const deleteColumnBoard = ({ boardId }: { boardId: number }) => {
  return del(`business/v1/kanban/${boardId}`);
};

const moveCardInBoard = ({ card }: { card: any }) => {
  return put(`business/v1/kanban/move`, card);
};

const moveKanbanColumns = (columns: any) => {
  return put(`business/v1/kanban/moveColumns`, columns);
};

const setNewStateToCard = ({ card }: { card: any }) => {
  return put(`business/v1/state/${card.id}`, card);
};

const list = ({
  page,
  perPage,
  search,
  sort,
  order,
  state_business,
  rangeDate,
  ownerBusiness,
  clientBusiness,
  sortBusiness,
  typeKanban,
}: {
  page: number | undefined;
  perPage: number | undefined;
  search: string | undefined;
  sort: string | undefined;
  order: string | undefined;
  state_business: string | undefined;
  rangeDate?: string[] | undefined;
  ownerBusiness?: number | undefined;
  clientBusiness?: number | undefined;
  sortBusiness?: string | undefined;
  typeKanban?: any | undefined;
}) => {
  const params = [];
  if (page) {
    params.push(`page=${page}`);
  }
  if (perPage) {
    params.push(`per_page=${perPage}`);
  }
  if (search) {
    params.push(`search=${search}`);
  }
  if (sort) {
    params.push(`sort=${sort}`);
  }
  if (order) {
    params.push(`order=${order === 'ascend' ? 'asc' : 'desc'}`);
  }
  if (state_business) {
    params.push(`state_business=${state_business}`);
  }
  if (rangeDate?.length === 2) {
    params.push(`start_date=${rangeDate[0]}`);
    params.push(`end_date=${rangeDate[1]}`);
  }
  if (ownerBusiness) {
    params.push(`owner_business=${ownerBusiness}`);
  }
  if (clientBusiness) {
    params.push(`client_business=${clientBusiness}`);
  }
  if (sortBusiness) {
    params.push(`sort=${sortBusiness}`);
  }
  if (typeKanban) {
    params.push(`typeKanban=${typeKanban}`);
  }

  if (params.length > 0) return get(`business/v1?${params.join('&')}`);

  return get('business/v1');
};

const getBusinessType = () => {
  return get(`business/v1/kanban`);
};

const getBusinessStage = (id: any) => {
  return get(`business/v1/kanban/${id}`);
};

const storeBusiness = (data: any) => {
  return post('business/v1', data);
};

const getBusinessId = (id: any) => {
  return get(`business/v1/${id}`);
};

const storeBusinessFollowup = (data: any) => {
  return post('business/v1/followup/store', data);
};

const storeBusinessNotes = (data: any) => {
  return post('business/v1/notes/store', data);
};

const delBusinessNotes = (id: any) => {
  return del(`business/v1/notes/${id}`);
};

const getBusinessFollowup = (id: any) => {
  return get(`business/v1/followup/${id}`);
};

const deleteBusinessFollowup = (id: any) => {
  return del(`business/v1/followup/${id}`);
};

const getBusinessFollowupCompleted = (id: any) => {
  return get(`business/v1/followup/${id}/completed`);
};

const getBusinessNotes = (id: any) => {
  return get(`business/v1/notes/${id}`);
};

const editOneBusiness = (data: any) => {
  return put(`business/v1/${data.id}`, data.data);
};

const cancelOneBusiness = ({ id }: any) => {
  return get(`business/v1/${id}/cancel`);
};

const closeOneBusiness = ({ id }: any) => {
  return get(`business/v1/${id}/close`);
};

const reopenOneBusiness = ({ id }: any) => {
  return get(`business/v1/${id}/reopen`);
};

const changeClient = ({
  businessId,
  clientSelected,
}: {
  businessId: number;
  clientSelected: number;
}) => {
  return put(`business/v1/${businessId}/client/${clientSelected}`);
};

const payments = ({
  page,
  perPage,
  search,
  rangeDate,
  pendent,
  agent,
}: {
  page?: number | undefined;
  perPage?: number | undefined;
  search?: string | undefined;
  rangeDate?: any;
  pendent?: boolean;
  agent?: number | undefined;
}) => {
  const params = [];

  if (page) {
    params.push(`page=${page}`);
  }
  if (perPage) {
    params.push(`per_page=${perPage}`);
  }
  if (search) {
    params.push(`search=${search}`);
  }
  if (pendent) {
    params.push(`pendents=${pendent}`);
  }
  if (agent) {
    params.push(`agent=${agent}`);
  }
  if (rangeDate.length === 2) {
    params.push(`startDate=${rangeDate[0]}`);
    params.push(`endDate=${rangeDate[1]}`);
  }

  if (params.length > 0)
    return get(`business/v1/payments/list?${params.join('&')}`);

  return get(`business/v1/payments/list`);
};

const historic = ({
  page,
  perPage,
  search,
  sort,
  order,
}: {
  page: number | undefined;
  perPage: number | undefined;
  search: string | undefined;
  sort: string | undefined;
  order: string | undefined;
}) => {
  const params = [];

  if (page) {
    params.push(`page=${page}`);
  }
  if (perPage) {
    params.push(`per_page=${perPage}`);
  }
  if (search) {
    params.push(`search=${search}`);
  }
  if (sort) {
    params.push(`sort=${sort}`);
  }
  if (order) {
    params.push(`order=${order === 'ascend' ? 'asc' : 'desc'}`);
  }

  if (params.length > 0)
    return get(`business/v1/payments/historic?${params.join('&')}`);

  return get(`business/v1/payments/historic`);
};

const paymentsDetails = (id: any) => {
  return get(`business/v1/payments/list/responsibles/${id}`);
};

const historicPaymentUser = ({
  id,
  period,
  startDate,
  endDate,
}: {
  id: string | undefined;
  period: string | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
}) => {
  const params = [];

  if (id) {
    params.push(`user_id=${id}`);
  }

  if (startDate) {
    params.push(`startDate=${startDate}`);
  }

  if (endDate) {
    params.push(`endDate=${endDate}`);
  }

  if (period) {
    params.push(`period=${period}`);
  }

  return get(`business/v1/payments/historic/group?${params.join('&')}`);
};

const madePayment = (id: number) => {
  return put(`business/v1/payments/made/${id}`);
};

export {
  closeOneBusiness,
  cancelOneBusiness,
  reopenOneBusiness,
  editOneBusiness,
  getBusinessType,
  getBusinessStage,
  storeBusiness,
  getBusinessId,
  list,
  getAllKanban,
  editColumnBoard,
  createColumnBoard,
  deleteColumnBoard,
  moveCardInBoard,
  listHistory,
  setNewStateToCard,
  storeBusinessFollowup,
  storeBusinessNotes,
  getBusinessNotes,
  getBusinessFollowup,
  getBusinessFollowupCompleted,
  deleteBusinessFollowup,
  payments,
  historic,
  paymentsDetails,
  delBusinessNotes,
  historicPaymentUser,
  changeClient,
  moveKanbanColumns,
  madePayment,
};

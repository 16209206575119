import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Statistic,
} from 'antd';
import React, { useState } from 'react';
import TopFilter from '../../components/Dasboard/TopFilter';
import {
  getStatisticData,
  getReportColumnsData,
  getReportLinesData,
} from '../../services/dashboard.service';
import { StatisticCards } from '../../components/Dasboard/StatisticCards';
import { ColumnsGraphic } from '../../components/Commons/Graphics/ColumnsGraphic';
import { LinesGraphic } from '../../components/Commons/Graphics/LinesGraphic';
import { useQuery } from 'react-query';
import { list } from '../../services/clients.service';
import { getAll } from '../../services/users.service';
import { dashParams } from '../../Interfaces/Dashboard.interfaces';
import LoadingComponent from '../../components/Commons/Loading';
import Title from 'antd/lib/typography/Title';
import locale from 'antd/es/date-picker/locale/pt_BR';

export default function Report({ user }: { user: any }) {
  const [formSearch] = Form.useForm();
  const [statisticData, setStatisticData] = useState<any>([]);
  const [columnsData, setColumnsData] = useState([]);
  const [linesData, setLinesData] = useState([]);
  const [clients, setClients] = useState([]);
  const [userList, setUserList] = useState([]);
  const [totalPendent, setTotalPendent] = useState<number>(0);
  const [rangeDate, setRangeDate] = useState<string[]>([]);

  const [params, setParams] = useState<dashParams>({ user: user?.id });

  const { isLoading: loadingStatistic, refetch: refetchStatistic } = useQuery(
    ['getStatisitcData', params],
    () => getStatisticData(params),
    {
      onSuccess: (data) => {
        setStatisticData(data?.data);
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: loadingColumnsData, refetch: refetchColumnsData } =
    useQuery(
      ['getReportColumnsData', params],
      () => getReportColumnsData(params),
      {
        onSuccess: (result) => {
          const { data } = result;

          let total = 0;
          if (data.length > 1) total += data[data.length - 1].values;
          if (data.length > 2) total += data[data.length - 2].values;

          setTotalPendent(total);
          setColumnsData(data);
        },
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      }
    );

  const { isLoading: loadingLinesData, refetch: refetchLinesData } = useQuery(
    ['getReportLinesData', params],
    () => getReportLinesData(params),
    {
      onSuccess: (data) => {
        setLinesData(data?.data);
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useQuery(
    ['getAllClients'],
    () =>
      list({
        page: undefined,
        perPage: undefined,
        search: undefined,
        sort: undefined,
        order: undefined,
      }),
    {
      onSuccess: (data) => {
        setClients(
          data?.data.map((client: any) => {
            return {
              label: client.data.name,
              value: client.data.id,
            };
          })
        );
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useQuery('userList', () => getAll(), {
    refetchOnWindowFocus: false,
    onSuccess: (users) => {
      setUserList(users.data);
    },
  });

  const onMapDataToExport = (columns: any, lines: any) => {
    const result = [];

    if (columns.length > 0) {
      result.push({ Descrição: 'Valor Potencial a receber' });
      columns.forEach((item: any) => {
        result.push({
          Nome: item.name,
          Mês: item.month,
          Valor: item.values,
        });
      });
    }

    if (lines.length > 0) {
      result.push({ Descrição: 'Propostas e Produtos' });
      lines.forEach((item: any) => {
        result.push({
          Nome: item.name,
          Mês: item.month,
          Valor: item.values,
        });
      });
    }

    return result;
  };

  const cleanSearch = () => {
    formSearch.resetFields();
    setParams({ user: user?.id });
    refetchLinesData();
    refetchColumnsData();
    refetchStatistic();
  };

  const filteredReport = () => {
    formSearch
      .validateFields()
      .then((values: any) => {
        let newParams: dashParams = params;

        if (!values.clients) {
          newParams = { ...newParams, client: undefined };
        } else {
          newParams = { ...newParams, client: values.clients };
        }

        if (!values.referrer) {
          newParams = { ...newParams, referrer: undefined };
        } else {
          newParams = { ...newParams, referrer: values.referrer };
        }

        if (!values.business_coach) {
          newParams = { ...newParams, businessCoach: undefined };
        } else {
          newParams = { ...newParams, businessCoach: values.business_coach };
        }

        if (!values.closer) {
          newParams = { ...newParams, closer: undefined };
        } else {
          newParams = { ...newParams, closer: values.closer };
        }

        if (
          !values.period ||
          (values.period.length &&
            values.period[0] === '' &&
            values.period[1] === '')
        ) {
          newParams = {
            ...newParams,
            startDate: undefined,
            endDate: undefined,
          };
        } else {
          newParams = {
            ...newParams,
            startDate: values.period[0].format('DD/MM/YYYY'),
            endDate: values.period[1].format('DD/MM/YYYY'),
          };
        }
        setParams(newParams);
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  const filterContent = (
    <div>
      <Form
        form={formSearch}
        layout="vertical"
        autoComplete="off"
        style={{ width: 300 }}
      >
        <Form.Item
          label="Datas"
          name="period"
        >
          <DatePicker.RangePicker
            allowClear
            style={{ width: "100%" }}
            size="large"
            format={"DD/MM/YYYY"}
            onChange={(date, dateString) => {
              setRangeDate(dateString);
            }}
            locale={{
              ...locale,
              lang: {
                ...locale.lang,
                rangePlaceholder: ["Início", "Fim"],
              },
            }}
          />
        </Form.Item>

        <Form.Item label="Clientes" name="clients">
          <Select
            placeholder="Todos"
            allowClear
            size="large"
            style={{ width: '100%' }}
          >
            {clients &&
              clients.map((client: any) => (
                <Select.Option key={client.value} value={client.value}>
                  {client.label}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        {user?.role === 'admin' && (
          <>
            <Form.Item label="Referenciador" name="referrer">
              <Select
                size="large"
                style={{ width: '100%' }}
                allowClear
                placeholder="Todos"
              >
                {userList &&
                  userList.map((value: any) => (
                    <Select.Option key={value.id} value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label="Business Coach" name="business_coach">
              <Select
                size="large"
                style={{ width: '100%' }}
                allowClear
                placeholder="Todos"
              >
                {userList &&
                  userList.map((value: any) => (
                    <Select.Option key={value.id} value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            <Form.Item label="Closer" name="closer">
              <Select
                size="large"
                style={{ width: '100%' }}
                allowClear
                placeholder="Todos"
              >
                {userList &&
                  userList.map((value: any) => (
                    <Select.Option key={value.id} value={value.id}>
                      {value.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </>
        )}
        <Form.Item style={{ textAlign: 'right' }}>
          <Button
            type="default"
            size="large"
            onClick={() => cleanSearch()}
            style={{
              marginRight: '8px',
              borderWidth: '1px',
              borderColor: '#0E3441',
            }}
          >
            Limpar
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            onClick={() => filteredReport()}
          >
            Filtrar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <div className="content_page">
      <Card styles={{ body: { padding: 16 }}}>
        <TopFilter
          filterContent={filterContent}
          data={onMapDataToExport(columnsData, linesData)}
          tableName={`report`}
          params={params}
        />
        {loadingStatistic ? (
          <LoadingComponent />
        ) : (
          <StatisticCards items={statisticData} gutter={16} />
        )}

        <Row gutter={16} style={{ marginTop: '16px' }}>
          <Col span={24}>
            <Card bordered={true}>
              {loadingStatistic ? (
                <LoadingComponent />
              ) : (
                <Statistic
                  title={'Valor potencial a receber (mês atual)'}
                  value={statisticData?.totalReceive || 0}
                />
              )}
              {loadingColumnsData ? (
                <LoadingComponent />
              ) : (<>
                <Title level={5} style={{ fontWeight: 700, float: 'left' }}>Negócios ganhos</Title>
                <ColumnsGraphic data={columnsData} />
              </>)}
            </Card>
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: '16px' }}>
          <Col span={24}>
            <Card bordered={true}>
              {loadingStatistic ? (
                <LoadingComponent />
              ) : (
                <Statistic
                  title={'Propostas e Produtos'}
                  value={statisticData.totalBusiness || 0}
                />
              )}
              {loadingLinesData ? (
                <LoadingComponent />
              ) : (
                <LinesGraphic data={linesData} />
              )}
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

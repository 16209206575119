import { Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Typography } from "antd";
import { useEffect } from "react";

const { Title } = Typography;

const typeOfBusiness = [
    { id: 'diagnostic', description: 'Diagnóstico' },
    { id: 'business_club', description: 'Clube de Empresários' }
]

export default function RequestToChangeReferrerComponent({ client, open, close, title, readOnly, requestNewReferrer, loading }: any) {

    const [formClient] = Form.useForm();

    useEffect(() => {
        if (client) {
            client.status = client.status ? "Ativo" : "Inativo";
            client.type_business_description = typeOfBusiness.find((type) => type.id === client.type_business)?.description;
            client.referencer_name = client?.referencer?.name;
            client.referencer_email = client?.referencer?.email;
            client.referencer_contacto = client?.referencer?.phone;

            formClient.setFieldsValue(client);
        }
    }, [client, formClient]);

    return (
        <Modal
            title={title}
            centered
            open={open}
            onCancel={close}
            destroyOnClose={true}
            width={1000}
            footer={null}
        >
            <Divider />
            <Form
                form={formClient}
                layout="vertical"
            >
                <Row gutter={8}>
                    <Col span={24}>
                        <Title style={{ margin: '0px 0px 16px 0px' }} level={5}>Informações do Referenciador atual</Title>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="Referenciador"
                            name="referencer_name"
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                                maxLength={100}
                                placeholder="Nome do referenciador"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="E-mail"
                            name="referencer_email"
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="Contacto"
                            name="referencer_contacto"
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24}>
                        <Title style={{ margin: '0px 0px 16px 0px' }} level={5}>Informações do Cliente</Title>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            label="Nome"
                            name="name"
                            rules={[
                                { required: true, message: "Campo obrigatório" },
                            ]}
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                                maxLength={255}
                                placeholder="Nome do cliente"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Email"
                            name="email"
                        >
                            <Input
                                readOnly={readOnly}
                                size="large"
                                placeholder="Email"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8}>
                    <Col span={24} style={{ display: 'flex', justifyContent: 'right', gap: 8 }}>
                        <Button onClick={close} loading={loading}>Cancelar</Button>
                        <Button type="primary" onClick={() => requestNewReferrer()} loading={loading}>Solicitar</Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
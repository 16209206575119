import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { AlertService } from '../../../services/alert.service';
import DisplayAlert from '../../../components/Commons/Alert';
import RequestToChangeReferrerComponent from '../../../components/Clients/requestToChangeReferrer.component';
import ConfirmModal from '../../../components/Commons/Modal/confirm-modal.component';
import {
  RequestToChangeReferrer,
  checkIfEmailLinkedToReferrer,
  editClient,
  getOneById,
  storeClient,
} from '../../../services/clients.service';
import ConfirmPasswordModal from '../../../components/Commons/Modal/confirm-password-modal.component';

const { Search } = Input;
const { Title } = Typography;

export default function ClientFormAdministrative(props: any) {
  const [formClient] = Form.useForm();
  const [referrerAlreadyExist, setReferrerAlreadyExist] = useState<boolean>();
  const [clientAlreadyExist, setClientAlreadyExist] = useState<
    undefined | any
  >();
  const [alertEdit, setAlertEdit] = useState<boolean>(false);
  const userLogged: any = localStorage.getItem('user');
  const [user, setUser] = useState(
    localStorage.getItem('user') ? JSON.parse(userLogged) : null
  );
  const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);
  const [openPassConfirm, setOpenPassConfirm] = useState<boolean>(false);
  const [email, setEmail] = useState<string | null>(null);
  const [changeEmail, setChangeEmail] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(true);

  const { id } = useParams();

  const prepareClientForm = (client: any) => {
    client.data.referencer = client?.data?.referencer?.name ?? user?.name;
    const { data } = client;

    formClient.setFieldsValue(data);
    setChecked(data.status);
  };

  useQuery(['loadProfile', id], () => (id ? getOneById(Number(id)) : null), {
    enabled: !!id,
    onSuccess: prepareClientForm,
    refetchOnWindowFocus: false,
  });

  const {
    mutate: mutateCheckIfEmailLinkedToReferrer,
    data: dataCheckIfEmailLinkedToReferrer,
    isLoading: isLoadingCheckIfEmailLinkedToReferrer,
    isSuccess: isSuccessCheckIfEmailLinkedToReferrer,
  } = useMutation(checkIfEmailLinkedToReferrer);

  useEffect(() => {
    if (
      isSuccessCheckIfEmailLinkedToReferrer &&
      dataCheckIfEmailLinkedToReferrer.data
    ) {
      if (id != dataCheckIfEmailLinkedToReferrer.data.id) {
        formClient.setFieldValue('email', email);
        setReferrerAlreadyExist(true);
      } else {
        formClient.setFieldValue('email', email);
        setChangeEmail(false);
        setReferrerAlreadyExist(false);
      }
    }
    if (
      isSuccessCheckIfEmailLinkedToReferrer &&
      !dataCheckIfEmailLinkedToReferrer.data
    ) {
      formClient.setFieldValue('email', email);
      setChangeEmail(false);
      setReferrerAlreadyExist(false);
    }
  }, [isSuccessCheckIfEmailLinkedToReferrer, dataCheckIfEmailLinkedToReferrer]);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      formClient.setFieldsValue({
        referencer: user.name,
      });
    }
  }, [user]);

  const {
    mutate: edit,
    isLoading: isLoadingEdit,
    isSuccess: isSuccessEdit,
  } = useMutation(editClient);

  const typeOfContacts = [
    { id: 'commercial', description: 'Comercial' },
    { id: 'personal', description: 'Pessoal' },
  ];

  const goToClients = () => {
    navigate('/manager/administrative?type=clients');
  };

  useEffect(() => {
    if (isSuccessEdit) {
      formClient.resetFields();

      AlertService.sendAlert([
        {
          text: 'Cliente editado com sucesso.',
          nextPage: true,
        },
      ]);
      goToClients();
    }
  }, [isSuccessEdit]);

  const save = () => {
    formClient
      .validateFields()
      .then((values) => {
        values.referencer = user.id;
        values.status = checked;
        return edit({ data: values, id: Number(id) });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const callbackPasswordValidate = (result: boolean) => {
    if (result) {
      setOpenPassConfirm(!result);
      save();
    }
  };

  const callbackCancel = () => {
    setOpenPassConfirm(false);
  };

  return (
    <div className="content_page">
      <DisplayAlert />
      <Card>
        <Row gutter={8}>
          <Col span={24}>
            <Title
              className={'title_page'}
              style={{ marginTop: 0, marginBottom: 0 }}
              level={5}
            >
              {id ? 'Editar cliente' : 'Criar novo cliente'}
            </Title>
          </Col>
        </Row>
        <Divider />
        <Form form={formClient} layout="vertical">
          <Row gutter={8}>
            <Col span={24}>
              <Title style={{ marginTop: 0, marginBottom: 16 }} level={5}>
                Informações do cliente
              </Title>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label="E-mail"
                name="email"
                rules={[
                  { type: 'email', message: 'Formato do email inválido' },
                  { required: true, message: 'Campo obrigatório' },
                ]}
              >
                <Search
                  size="large"
                  maxLength={50}
                  loading={isLoadingCheckIfEmailLinkedToReferrer}
                  enterButton={true}
                  onChange={(value) => setChangeEmail(true)}
                  onSearch={(value: any) => {
                    setEmail(value);
                    mutateCheckIfEmailLinkedToReferrer(value);
                  }}
                />
                {referrerAlreadyExist && (
                  <p style={{ display: 'inline', verticalAlign: 'middle' }}>
                    <InfoCircleFilled
                      style={{ fontSize: 18, color: '#FFC53D', marginRight: 8 }}
                    />
                    Este email já está associado a outro cliente.
                  </p>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="Contacto"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                  {
                    validator: (_, value: number) => {
                      if (value >= 0 && value?.toString()?.length < 9) {
                        return Promise.reject(
                          'O número de telefone deve ter 9 dígitos'
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  maxLength={11}
                  parser={(value) => `${value}`.replace(/[^0-9]/g, '')}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="type"
                label="Tipo do contacto"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select
                  placeholder="Seleciona um tipo de contacto"
                  size="large"
                  options={typeOfContacts.map((type: any) => {
                    return { value: type.id, label: type.description };
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input
                  size="large"
                  maxLength={255}
                  placeholder="Nome do cliente"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Organização" name="organization">
                <Input
                  size="large"
                  maxLength={255}
                  placeholder="Nome da organização"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: 25,
              }}
            >
              <Form.Item name="status">
                <Row>
                  <Col flex="auto">
                    <Switch
                      checked={checked}
                      onChange={($event) => {
                        setChecked($event);
                      }}
                    />
                  </Col>
                  <Col style={{ marginLeft: 10 }}>
                    Cliente {checked == true ? 'ativo' : 'inativo'}
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col
              offset={18}
              span={6}
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 16 }}
            >
              <Button
                type="default"
                size="large"
                loading={isLoadingEdit}
                onClick={() => setOpenModalCancel(true)}
              >
                Cancelar
              </Button>
              <Button
                type="primary"
                size="large"
                loading={isLoadingEdit}
                onClick={() => setAlertEdit(true)}
                disabled={
                  changeEmail || referrerAlreadyExist || clientAlreadyExist
                }
              >
                Gravar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <ConfirmModal
        open={openModalCancel}
        title="Tem certeza que deseja sair sem gravar as alterações?"
        content="Toda a informação não gravada será perdida."
        okText="Sair"
        cancelText="Cancelar"
        onOk={goToClients}
        onCancel={() => setOpenModalCancel(false)}
      ></ConfirmModal>
      <ConfirmModal
        open={alertEdit}
        title="Está prestes a alterar um dado sensível. Tem a certeza que deseja fazer esta alteração?"
        okText="Validar password"
        cancelText="Voltar"
        onOk={() => {
          setOpenPassConfirm(true);
          setAlertEdit(false);
        }}
        onCancel={() => setAlertEdit(false)}
      ></ConfirmModal>
      <ConfirmPasswordModal
        open={openPassConfirm}
        callback={callbackPasswordValidate}
        onCancel={callbackCancel}
        title="Está prestes a editar os dados deste utilizador. Tem a certeza  que deseja fazer esta alteração?"
      />
    </div>
  );
}

import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AlertService } from '../../services/alert.service';
import { useMutation, useQuery } from 'react-query';
import {
  RequestToChangeReferrer,
  checkIfEmailLinkedToReferrer,
  editClient,
  getOneById,
  storeClient,
} from '../../services/clients.service';
import DisplayAlert from '../../components/Commons/Alert';
import ConfirmModal from '../../components/Commons/Modal/confirm-modal.component';
import RequestToChangeReferrerComponent from '../../components/Clients/requestToChangeReferrer.component';
import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';

const { Search } = Input;
const { Title } = Typography;

export default function FormClientPage(props: any) {
  const [formClient] = Form.useForm();
  const [referrerAlreadyExist, setReferrerAlreadyExist] = useState<
    undefined | any
  >();
  const [clientAlreadyExist, setClientAlreadyExist] = useState<
    undefined | any
  >();
  const [
    openModalRequestToChangeReferrer,
    setOpenModalRequestToChangeReferrer,
  ] = useState<boolean>(false);
  const userLogged: any = localStorage.getItem('user');
  const [user, setUser] = useState(
    localStorage.getItem('user') ? JSON.parse(userLogged) : null
  );
  const [openModalCancel, setOpenModalCancel] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(true);

  const { id } = useParams();

  const prepareClientForm = (client: any) => {
    client.data.referencer = client?.data?.referencer?.name ?? user?.name;
    const { data } = client;

    formClient.setFieldsValue(data);
    setChecked(data.status);
  };

  useQuery(['loadProfile', id], () => (id ? getOneById(Number(id)) : null), {
    enabled: !!id,
    onSuccess: prepareClientForm,
    refetchOnWindowFocus: false,
  });

  const {
    mutate: mutateCheckIfEmailLinkedToReferrer,
    data: dataCheckIfEmailLinkedToReferrer,
    isLoading: isLoadingCheckIfEmailLinkedToReferrer,
    isSuccess: isSuccessCheckIfEmailLinkedToReferrer,
  } = useMutation(checkIfEmailLinkedToReferrer);

  const {
    mutate: mutateRequestToChangeReferrer,
    isLoading: isLoadingRequestToChangeReferrer,
    isSuccess: isSuccessRequestToChangeReferrer,
  } = useMutation(RequestToChangeReferrer);

  useEffect(() => {
    if (isSuccessRequestToChangeReferrer) {
      setOpenModalRequestToChangeReferrer(false);
      AlertService.sendAlert([
        {
          type: 'info',
          text: 'A solicitação foi enviada! Aguarde enquanto contactamos o referenciador responsável.',
          nextPage: true,
        },
      ]);
      goToClients();
    }
  }, [isSuccessRequestToChangeReferrer]);

  useEffect(() => {
    if (isSuccessCheckIfEmailLinkedToReferrer) {
      const { data, message } = dataCheckIfEmailLinkedToReferrer;

      if (data?.referencer?.id) {
        setReferrerAlreadyExist(data);
      } else {
        AlertService.sendAlert([
          {
            text: message,
          },
        ]);

        formClient.setFieldsValue({ ...data, type: 'commercial' });
      }
    }
  }, [isSuccessCheckIfEmailLinkedToReferrer, dataCheckIfEmailLinkedToReferrer]);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      formClient.setFieldsValue({
        referencer: user.name,
      });
    }
  }, [user]);

  const {
    mutate: store,
    isLoading: isLoadingStore,
    isSuccess: isSuccessStore,
  } = useMutation(storeClient);

  const {
    mutate: edit,
    isLoading: isLoadingEdit,
    isSuccess: isSuccessEdit,
  } = useMutation(editClient);

  const typeOfContacts = [
    { id: 'commercial', description: 'Comercial' },
    { id: 'personal', description: 'Pessoal' },
  ];

  const goToClients = () => {
    navigate('/clients');
  };

  useEffect(() => {
    if (isSuccessStore) {
      formClient.resetFields();

      AlertService.sendAlert([
        {
          text: 'Cliente adicionado com sucesso.',
          nextPage: true,
        },
      ]);
      goToClients();
    }
  }, [isSuccessStore]);

  useEffect(() => {
    if (isSuccessEdit) {
      formClient.resetFields();

      AlertService.sendAlert([
        {
          text: 'Cliente editado com sucesso.',
          nextPage: true,
        },
      ]);
      goToClients();
    }
  }, [isSuccessEdit]);

  const save = () => {
    formClient
      .validateFields()
      .then((values) => {
        values.referencer = user.id;
        values.status = checked;
        if (id) return edit({ data: values, id: Number(id) });

        store(values);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const requestNewReferrer = () => {
    mutateRequestToChangeReferrer({
      referrer: user.id,
      client: id ?? referrerAlreadyExist?.id,
    });
  };

  return (
    <div className="content_page">
      <DisplayAlert />
      <Card>
        <Row gutter={8}>
          <Col span={24}>
            <Title
              className={'title_page'}
              style={{ marginTop: 0, marginBottom: 0 }}
              level={5}
            >
              {id ? 'Editar cliente' : 'Criar novo cliente'}
            </Title>
          </Col>
        </Row>
        <Divider />
        <Form form={formClient} layout="vertical">
          <Row gutter={8}>
            <Col span={24}>
              <Title style={{ marginTop: 0, marginBottom: 16 }} level={5}>
                Informações do cliente
              </Title>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label="E-mail"
                name="email"
                tooltip="Após gravado, este campo não poderá ser mais alterado."
                rules={[
                  { type: 'email', message: 'Formato do email inválido' },
                  { required: true, message: 'Campo obrigatório' },
                ]}
              >
                <Search
                  disabled={!!id ? true : false}
                  size="large"
                  maxLength={50}
                  placeholder="exemplo@exemplo.com"
                  loading={isLoadingCheckIfEmailLinkedToReferrer}
                  enterButton={true}
                  onSearch={(value) => {
                    mutateCheckIfEmailLinkedToReferrer(value);
                  }}
                />
                {referrerAlreadyExist &&
                  referrerAlreadyExist?.referencer?.id &&
                  referrerAlreadyExist?.referencer?.id != user.id && (
                    <p style={{ display: 'inline', verticalAlign: 'middle' }}>
                      <InfoCircleFilled
                        style={{
                          fontSize: 18,
                          color: '#FFC53D',
                          marginRight: 8,
                        }}
                      />
                      Este cliente está associado a outro referenciador.
                      <span
                        style={{ color: '#0E3441', cursor: 'pointer' }}
                        onClick={() =>
                          setOpenModalRequestToChangeReferrer(true)
                        }
                      >
                        {' '}
                        Clique aqui{' '}
                      </span>
                      e solicita a alteração do referenciador deste cliente.
                    </p>
                  )}
                {referrerAlreadyExist &&
                  referrerAlreadyExist?.referencer?.id == user.id && (
                    <p style={{ display: 'inline', verticalAlign: 'middle' }}>
                      <InfoCircleFilled
                        style={{
                          fontSize: 18,
                          color: '#FFC53D',
                          marginRight: 8,
                        }}
                      />
                      Este cliente já está associado a você.
                    </p>
                  )}
              </Form.Item>
              {openModalRequestToChangeReferrer && (
                <RequestToChangeReferrerComponent
                  client={referrerAlreadyExist}
                  open={openModalRequestToChangeReferrer}
                  close={() => setOpenModalRequestToChangeReferrer(false)}
                  title="Pedido para alteração de Referenciador"
                  readOnly={true}
                  requestNewReferrer={requestNewReferrer}
                  loading={isLoadingRequestToChangeReferrer}
                />
              )}
            </Col>
            <Col span={6}>
              <Form.Item
                label="Contacto"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Campo obrigatório',
                  },
                  {
                    validator: (_, value: number) => {
                      if (value >= 0 && value?.toString()?.length < 9) {
                        return Promise.reject(
                          'O número de telefone deve ter 9 dígitos'
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputNumber
                  size="large"
                  style={{ width: '100%' }}
                  maxLength={11}
                  parser={(value) => `${value}`.replace(/[^0-9]/g, '')}
                  formatter={(value) =>
                    `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  }
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="type"
                label="Tipo do contacto"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Select
                  placeholder="Seleciona um tipo de contacto"
                  size="large"
                  options={typeOfContacts.map((type: any) => {
                    return { value: type.id, label: type.description };
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                label="Nome"
                name="name"
                rules={[{ required: true, message: 'Campo obrigatório' }]}
              >
                <Input
                  size="large"
                  maxLength={255}
                  placeholder="Nome do cliente"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Organização" name="organization">
                <Input
                  size="large"
                  maxLength={255}
                  placeholder="Nome da organização"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: 25,
              }}
            >
              <Form.Item name="active">
                <Row>
                  <Col flex="auto">
                    <Switch
                      checked={checked}
                      onChange={($event) => {
                        setChecked($event);
                      }}
                    />
                  </Col>
                  <Col style={{ marginLeft: 10 }}>
                    Cliente {checked == true ? 'ativo' : 'inativo'}
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={8}>
            <Col span={24}>
              <Title style={{ marginTop: 0, marginBottom: 16 }} level={5}>
                Informações do referenciador
              </Title>
            </Col>
            <Col span={12}>
              <Form.Item label="Referenciador" name="referencer">
                <Input
                  disabled
                  size="large"
                  maxLength={100}
                  placeholder="Nome do referenciador"
                />
              </Form.Item>
            </Col>
            {/* <Col span={6}>
                            <Form.Item
                                label="Tipo de negócio"
                                name="type_business"
                            >
                                <Select
                                    placeholder="Seleciona um tipo de negócio"
                                    size="large"
                                    options={typeOfBusiness.map((type: any) => {
                                        return { value: type.id, label: type.description };
                                    })}
                                />
                            </Form.Item>
                        </Col> */}
          </Row>
          <Row>
            <Col
              offset={18}
              span={6}
              style={{ display: 'flex', justifyContent: 'flex-end', gap: 16 }}
            >
              <Button
                type="default"
                size="large"
                loading={isLoadingStore || isLoadingEdit}
                onClick={() => setOpenModalCancel(true)}
              >
                Cancelar
              </Button>
              <Button
                type="primary"
                size="large"
                loading={isLoadingStore || isLoadingEdit}
                onClick={save}
                disabled={referrerAlreadyExist || clientAlreadyExist}
              >
                Gravar
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <ConfirmModal
        open={openModalCancel}
        title="Tem certeza que deseja sair sem gravar as alterações?"
        content="Toda a informação não gravada será perdida."
        okText="Sair"
        cancelText="Cancelar"
        onOk={goToClients}
        onCancel={() => setOpenModalCancel(false)}
      ></ConfirmModal>
    </div>
  );
}

import DisplayAlert from '../../components/Commons/Alert';
import {
  AutoComplete,
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Popover,
  Select,
} from 'antd';
import KanbanBoard from '../../components/Kanban';
import { useEffect, useState } from 'react';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import ModalEditColumn from '../../components/Kanban/ModalEditColumn';
import HistoryBoard from '../../components/Business/HistoryBoard';
import {
  createColumnBoard,
  getAllKanban,
  list,
} from '../../services/business.service';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { EnumTypeKanban, getLabel } from '../../Enums/TypeOfKanban.enums';
import { getAll } from '../../services/users.service';
import { list as listClients } from '../../services/clients.service';
import { AlertService } from '../../services/alert.service';
import { getFunnelTypes } from '../../services/dashboard.service';

const { Search } = Input;

interface propsTypeKanban {
  type:
    | EnumTypeKanban.COACHING_BUSINESS_CLUB
    | EnumTypeKanban.DIAGNOSIS
    | 'HISTORY';
}
interface FunnelTypeArrayInterface {
  id?: number;
  type?: string;
}
const userLogged: any = localStorage.getItem('user');

export function BusinessBoardPage({ type }: propsTypeKanban) {
  const [user, setUser] = useState(userLogged ? JSON.parse(userLogged) : null);

  const [kanban, setKanban] = useState<any>(null);
  const [typeKanban, setTypeKanban] = useState<any>(null);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [formSearch] = Form.useForm();
  const [options, setOptions] = useState([]);
  const [users, setUsers] = useState([]);

  const [clientOptions, setClientOptions] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientBusiness, setClientBusiness] = useState<number | undefined>(
    undefined
  );

  const [search, setSearch] = useState<string>('');
  const [rangeDate, setRangeDate] = useState<string[]>([]);
  const [ownerBusiness, setOwnerBusiness] = useState<number | undefined>(
    undefined
  );
  const [sortBusiness, setSortBusiness] = useState<string | undefined>(
    undefined
  );

  const [funnelType, setFunnelType] = useState<any>();
  const [stateBusiness, setStateBusiness] = useState<any>('fechado');

  const [openNewColumnModal, setOpenNewColumnModal] = useState<boolean>(false);
  const [loadingNewColumn, setLoadingNewColumn] = useState<any>(null);
  const [funnelTypeArray, setFunnelTypeArray] = useState<
    FunnelTypeArrayInterface[]
  >([]);

  const [currentPage, setCurrentPage] = useState<number | undefined>();
  const [perPage, setPerPage] = useState<number | undefined>();
  const [total, setTotal] = useState<number | undefined>();

  const navigate = useNavigate();

  useEffect(() => {
    if (typeKanban !== type) {
      setTypeKanban(type);
      setKanban(null);
    }
  }, [typeKanban]);

  useQuery(['getFunnelTypes'], () => getFunnelTypes(), {
    onSuccess: (data) => {
      let items = data?.data;
      setFunnelTypeArray(items);
      setFunnelType('');
    },
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useQuery('userList', () => getAll(), {
    refetchOnWindowFocus: false,
    onSuccess: (users) => {
      setUsers(
        users.data.map((user: any) => ({
          id: user.id,
          value: user?.name,
          label: user?.name,
        }))
      );
    },
    enabled: user?.role === 'admin',
  });

  useQuery(
    'clientList',
    () =>
      listClients({
        page: 1,
        perPage: 9999,
        search: undefined,
        sort: undefined,
        order: undefined,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess: (clients) => {
        setClients(
          clients.data.map((client: any) => ({
            id: client.data.id,
            value: client.data?.name,
            label: client.data?.name,
          }))
        );
      },
      enabled: user?.role !== 'admin',
    }
  );

  const { refetch: refetchBusiness } = useQuery(
    [
      'business',
      typeKanban,
      search,
      rangeDate.toString(),
      ownerBusiness,
      sortBusiness,
      clientBusiness,
      stateBusiness,
      funnelType,
      currentPage,
      perPage,
    ],
    () => {
      if (
        typeKanban === EnumTypeKanban.COACHING_BUSINESS_CLUB ||
        typeKanban === EnumTypeKanban.DIAGNOSIS
      ) {
        return getAllKanban({
          typeKanban,
          search,
          rangeDate,
          ownerBusiness,
          sortBusiness,
          clientBusiness,
        });
      }
      if (typeKanban === 'HISTORY') {
        return list({
          page: currentPage ?? 1,
          perPage: perPage ?? 10,
          state_business: stateBusiness,
          typeKanban: funnelType,
          sort: undefined,
          order: undefined,
          search: search,
          rangeDate: rangeDate,
          ownerBusiness: ownerBusiness,
          sortBusiness: sortBusiness,
          clientBusiness: clientBusiness,
        });
      }
    },
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        if (
          EnumTypeKanban.COACHING_BUSINESS_CLUB === typeKanban ||
          (EnumTypeKanban.DIAGNOSIS === typeKanban &&
            data?.data?.columns?.length > 0)
        ) {
          data.data.columns = data?.data?.columns?.map((kanban: any) => {
            const newColumnObj = {
              ...kanban,
              title: kanban?.name,
              columns: kanban.business,
            };

            delete newColumnObj.business;
            delete newColumnObj?.name;

            return newColumnObj;
          });
          setKanban(data.data);
          return;
        }

        if (data && typeKanban === 'HISTORY') {
          setCurrentPage(data?.data?.content?.current_page);
          setPerPage(data?.data?.content?.per_page);
          setTotal(data?.data?.content?.total);
        }

        setKanban(data);
      },
    }
  );

  const { mutate: saveNewColumn } = useMutation(createColumnBoard, {
    onSuccess: () => {
      AlertService.sendAlert([
        {
          text: 'A etapa foi criada com sucesso!',
          nextPage: false,
        },
      ]);
      setLoadingNewColumn(false);
      setOpenNewColumnModal(false);
      refetchBusiness();
    },
    onError: (error: any) => {
      setLoadingNewColumn(false);
      setOpenNewColumnModal(false);
      refetchBusiness();
    },
  });

  const goToNew = () => {
    navigate('/business/new');
  };

  const confirmEditModal = (form: any) => {
    setLoadingNewColumn(true);

    if (!form?.color || form.color === '') {
      const allColors = [
        '#141414',
        '#545454',
        '#737373',
        '#A6A6A6',
        '#D9D9D9',
        '#FFFFFF',
        '#FF3131',
        '#FF5757',
        '#FF66C4',
        '#CB6CE6',
        '#8C52FF',
        '#5E17EB',
        '#0097B2',
        '#0CC0DF',
        '#5CE1E6',
        '#38B6FF',
        '#5271FF',
        '#004AAD',
        '#00BF63',
        '#7ED957',
        '#C1FF72',
        '#FFDE59',
        '#FFBD59',
        '#FF914D',
      ];

      const colorsInUse = kanban?.columns.map((column: any) => column.color);

      const availableColors = allColors.filter(
        (color) => !colorsInUse.includes(color)
      );

      if (availableColors.length > 0) {
        form.color =
          availableColors[Math.floor(Math.random() * availableColors.length)];
      }
    }

    saveNewColumn({ column: { ...form, kanban_type: typeKanban } });
  };

  const onSearch = (value: string) => {
    setSearch(value);
  };

  const onSelect = (data: any, option: any) => {
    setCurrentPage(1);
    setOwnerBusiness(option.id);
  };

  const getSearchUser = (searchText: string) => {
    return searchText.length > 2
      ? users.filter(
          (option: any) =>
            option.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        )
      : [];
  };

  const getSearchClient = (searchText: string) => {
    return searchText.length > 2
      ? clients.filter(
          (option: any) =>
            option.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
        )
      : [];
  };

  const onSelectClient = (data: any, option: any) => {
    setCurrentPage(1);
    setClientBusiness(option.id);
  };

  const filterContent = (
    <div>
      <Form form={formSearch} layout="vertical" autoComplete="off">
        <Form.Item label="Período" name="period">
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            size="large"
            format={'DD/MM/YYYY'}
            onChange={(date, dateString) => {
              setCurrentPage(1);
              setRangeDate(dateString);
            }}
            locale={{
              ...locale,
              lang: {
                ...locale.lang,
                rangePlaceholder: ['Início', 'Fim'],
              },
            }}
          />
        </Form.Item>
        {user?.role === 'admin' && (
          <Form.Item label="Responsável do negócio" name="searchName">
            <AutoComplete
              options={options}
              onSelect={(data, option) => onSelect(data, option)}
              onSearch={(text) => setOptions(getSearchUser(text))}
              placeholder="Nome do responsável do negócio"
              allowClear
              size="large"
              onClear={() => setOwnerBusiness(undefined)}
            />
          </Form.Item>
        )}
        {user?.role !== 'admin' && (
          <Form.Item label="Cliente" name="searchName">
            <AutoComplete
              options={clientOptions}
              onSelect={(data, option) => onSelectClient(data, option)}
              onSearch={(text) => setClientOptions(getSearchClient(text))}
              placeholder="Nome do cliente"
              allowClear
              size="large"
              onClear={() => setClientBusiness(undefined)}
            />
          </Form.Item>
        )}
        {!!kanban && typeKanban === 'HISTORY' && (
          <>
            <Form.Item label="Tipo de funil" name="business_kanban_id">
              <Select
                size="large"
                style={{ width: '100%' }}
                defaultValue={'all'}
                onSelect={(value: any) => {
                  setCurrentPage(1);
                  setFunnelType(value);
                }}
                allowClear
                onClear={() => {
                  formSearch.setFieldValue('business_kanban_id', 'all');
                  setFunnelType('all');
                }}
              >
                <option value="all">Todos</option>
                {funnelTypeArray?.map((item: any) => (
                  <option key={item.id} value={item.id}>
                    {getLabel(item.type)}
                  </option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Status do negócio" name="state_business">
              <Select
                size="large"
                style={{ width: '100%' }}
                defaultValue={'fechado'}
                onSelect={(value: any) => {
                  setCurrentPage(1);
                  setStateBusiness(value);
                }}
              >
                <option key="1" value="aberto">
                  Aberto
                </option>
                <option key="2" value="fechado">
                  Fechado
                </option>
              </Select>
            </Form.Item>
          </>
        )}
      </Form>
    </div>
  );

  return (
    <div>
      <div style={{ margin: 24 }}>
        <DisplayAlert />
      </div>
      <Card className="content_page">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form
            form={formSearch}
            name="search_user"
            layout="inline"
            className="form_search_horizontal"
            autoComplete="off"
          >
            <Form.Item name="search_user" className="item_search">
              <Search
                onSearch={onSearch}
                placeholder="Pesquisar por nome do negócio"
                enterButton="Pesquisar"
                allowClear
                size="large"
                maxLength={255}
              />
            </Form.Item>
            <Form.Item>
              <Popover
                content={filterContent}
                trigger="click"
                open={openFilter}
                onOpenChange={() => setOpenFilter(!openFilter)}
                placement="bottomRight"
              >
                <Button type="default" size="large">
                  <FilterFilled />
                  Filtro
                </Button>
              </Popover>
            </Form.Item>
            <Form.Item name="order">
              <Select
                style={{ width: 300 }}
                size="large"
                placeholder="Ordenar por"
                onChange={(value) => setSortBusiness(value)}
                allowClear
                onClear={() => setSortBusiness(undefined)}
              >
                <Select.Option value="old">
                  Do mais antigo para o mais novo
                </Select.Option>
                <Select.Option value="new">
                  Do mais novo para o mais antigo
                </Select.Option>
              </Select>
            </Form.Item>
          </Form>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: 16,
            }}
          >
            {user?.role === 'admin' && (
              <Button
                type="default"
                size="large"
                onClick={() => setOpenNewColumnModal(true)}
                disabled={kanban?.columns?.length >= 24}
              >
                Nova etapa
              </Button>
            )}
            <Button type="primary" size="large" onClick={() => goToNew()}>
              Novo negócio
            </Button>
          </div>
        </div>
        <Divider />
        {!!kanban &&
          (typeKanban === EnumTypeKanban.COACHING_BUSINESS_CLUB ||
            typeKanban === EnumTypeKanban.DIAGNOSIS) && (
            <KanbanBoard
              kanban={kanban}
              refetchBoard={(data: any) => data === true && refetchBusiness()}
              isAdmin={user?.role === 'admin'}
            />
          )}
        {!!kanban && typeKanban === 'HISTORY' && (
          <HistoryBoard
            history={kanban}
            currentPageValue={currentPage}
            perPageValue={perPage}
            totalValue={total}
            setCurrentPageValue={setCurrentPage}
            setPerPageValue={setPerPage}
            setTotalValue={setTotal}
          />
        )}
      </Card>
      {openNewColumnModal && (
        <ModalEditColumn
          open={openNewColumnModal}
          onOk={confirmEditModal}
          loading={loadingNewColumn}
          onCancel={() => setOpenNewColumnModal(false)}
          text="Nome da nova etapa"
          cancelText="Cancelar"
          okText="Gravar"
          defaultShowColors={true}
          colorsInUse={kanban?.columns.map((column: any) => column.color)}
          data={{ title: '', color: '' }}
        />
      )}
    </div>
  );
}

import { FlagOutlined, MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from 'antd';
import { Button, Col, Dropdown, Row } from "antd";
import ConfirmModal from "../../Commons/Modal/confirm-modal.component";
import { useState } from "react";
import { useMutation } from "react-query";
import { markAllAsRead } from "../../../services/notification.service";
import { deleteBusinessFollowup, getBusinessFollowupCompleted } from "../../../services/business.service";

export default function FollowUpCard(props: any) {
    const [openModalCancel, setOpenModalCancel] = useState(false);
    const [propsModalConfirm, setPropsModalConfirm] = useState<any>({});

    const { mutate: markReadMutation } = useMutation(getBusinessFollowupCompleted, {
        onSuccess: () => {
            setOpenModalCancel(false);
            props.refetch();
        }
    })

    const { mutate: deleteFollowupMutation } = useMutation(deleteBusinessFollowup, {
        onSuccess: () => {
            setOpenModalCancel(false);
            props.refetch();
        }
    })

    const markRead = () => {
        markReadMutation(props.data.id)
    }

    const removeFollowup = () => {
        deleteFollowupMutation(props.data.id)
    }

    const completed = () => {
        setPropsModalConfirm({
            title: 'Deseja concluir este follow up?',
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => markRead()
        });
        setOpenModalCancel(true);
    }

    const remove = () => {
        setPropsModalConfirm({
            title: 'Deseja apagar este follow up?',
            okText: 'Sim',
            cancelText: 'Não',
            onOk: () => removeFollowup()
        });
        setOpenModalCancel(true);
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={completed}>
                    Marcar como concluido
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={remove}>
                    Apagar
                </a>
            ),
        }
    ];

    return (
        <div style={{
            padding: 15,
            border: '1px solid #D9D9D9',
            borderRadius: '4px',
            marginTop: 8,
            marginBottom: 8
        }}>
            <Row>
                <Col
                    span={12}
                    style={{ fontSize: 16, fontWeight: 500 }}
                >
                    {props.data.title}
                </Col>
                <Col
                    span={4}
                    offset={8}
                    style={{ textAlign: 'end' }}
                >
                {!props.closed && 
                    <Dropdown menu={{ items }} placement="bottomRight" arrow>
                        <Button shape="circle" size="small" icon={<MoreOutlined />} />
                    </Dropdown>
                }
                </Col>
            </Row>

            <Row>
                <Col
                    span={24}
                    style={{
                        color: '#8C8C8C',
                        fontSize: 12,
                        marginTop: 10
                    }}
                >
                    {new Date(props.data.date).toLocaleDateString('pt-BR')} - {props.data.time}
                </Col>
            </Row>

            {!!props.data.is_important && <Row>
                <Col
                    span={24}
                    style={{
                        marginTop: 10,
                        padding: '8px 10px',
                        backgroundColor: '#F5F5F5',
                        borderRadius: '4px'
                    }}
                >
                    <FlagOutlined
                        style={{
                            color: "#F5222D",
                            fontSize: 16,
                            paddingRight: 5
                        }} />  Sinalizado como importante
                </Col>
            </Row>}

            <Row style={{ marginTop: 15 }}>
                <Col>
                    <span style={{ fontWeight: 500 }}>
                        {props.data.responsible.name}
                    </span>
                    <span
                        style={{
                            color: '#8C8C8C',
                            fontSize: 12,
                            paddingLeft: 5
                        }}
                    >
                        - criado {
                            new Date(props.data.created_at).toLocaleDateString('pt-BR')} às
                        {new Date(props.data.created_at).toLocaleTimeString('pt-BR',
                            { hour: '2-digit', minute: '2-digit' })
                        }
                    </span>
                </Col>
            </Row>
            <ConfirmModal
                open={openModalCancel}
                title={propsModalConfirm.title}
                okText={propsModalConfirm.okText}
                cancelText={propsModalConfirm.cancelText}
                onOk={propsModalConfirm.onOk}
                onCancel={() => setOpenModalCancel(false)}
            ></ConfirmModal>
        </div>
    )
}
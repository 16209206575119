import { Layout as LayoutAnt, theme } from "antd";
import { Route, Routes } from "react-router-dom";
import PageNotFound from "../Pages/Errors/PageNotFound";
import { Dashboard } from "../Pages/Dashboard";
import { authorizedAccess } from "../services/utils";
import Clients from "../Pages/Clients";
import ListPage from "../Pages/Management/Users/List";
import ListProfilePage from "../Pages/Management/Users/ListProfile";
import FormPage from "../Pages/Management/Users/Form";
import FormProfilePage from "../Pages/Management/Users/FormProfile";
import FormClientPage from "../Pages/Clients/Form";
import Products from "../Pages/Products";
import FormProductsPage from "../Pages/Products/Form";
import { BusinessPage } from "../Pages/Business";
import BusinessForm from "../Pages/Business/Form";
import Details from "../Pages/Business/Details";
import Payment from "../Pages/Management/Payment";
import DeleteClientPage from "../Pages/Clients/DeleteClientPage";
import Administrative from "../Pages/Management/Administrative";
import UserFormAdministrative from "../Pages/Management/Administrative/UserEdit";
import ClientFormAdministrative from "../Pages/Management/Administrative/ClientEdit";

const { Content } = LayoutAnt;

export default function ContentCustom({ content, modulePermissions = [] }: any) {
  const {
    token: { colorBgLayout },
  } = theme.useToken();

  const verifyAuthorizedAccess = (permissions: string[]) => authorizedAccess(modulePermissions, permissions);

  return (
    <Content style={{ background: colorBgLayout }} >
      <Routes>
        {verifyAuthorizedAccess([
          'dashboard-dashboard_all:have', 'dashboard-dashboard_personal:have'
        ])
          && <>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
          </>
        }

        {verifyAuthorizedAccess([
          'manager-users:read', 'manager-users:write',
        ])
          && <>
            <Route path="/manager/users" element={<ListPage />} />
          </>}

        {verifyAuthorizedAccess([
          'manager-users:write',
        ])
          && <>
            <Route path="/manager/users/create" element={<FormPage action="create" />} />
            <Route path="/manager/users/:id/edit" element={<FormPage action="edit" />} />
          </>}

        {verifyAuthorizedAccess([
          'manager-profiles:read', 'manager-profiles:write',
        ])
          && <>
            <Route path="/manager/profiles_privileges" element={<ListProfilePage />} />
            <Route path="/manager/profiles_privileges/:id/show" element={<FormProfilePage action="show" />} />
          </>}

        {verifyAuthorizedAccess([
          'manager-users:write',
        ])
          && <>
            <Route path="/manager/profiles_privileges/create" element={<FormProfilePage action="create" />} />
            <Route path="/manager/profiles_privileges/:id/edit" element={<FormProfilePage action="edit" />} />
          </>}

        {verifyAuthorizedAccess([
          'manager-payments:read', 'manager-payments:write'
        ])
          && <>
            <Route path="/manager/payment" element={<Payment />} />
          </>}

        <Route path="/manager/administrative" element={<Administrative />} />
        <Route path="/manager/administrative/user/:id/edit" element={<UserFormAdministrative />} />
        <Route path="/manager/administrative/client/:id/edit" element={<ClientFormAdministrative />} />

        {verifyAuthorizedAccess([
          'business-view_business:have',
          'business-edit_business:have',
          'business-new_business:have',
          'business-new_follow_up:have',
          'business-delete_business:have',
        ])
          && <>
            <Route path="/clients" element={<Clients />} />
            <Route path="/clients/:id/business" element={<DeleteClientPage />} />
            <Route path="/clients/new" element={<FormClientPage />} />
            <Route path="/clients/edit/:id" element={<FormClientPage />} />
          </>}

        {verifyAuthorizedAccess([
          'business-view_business:have',
        ])
          && <>
            <Route path="/products" element={<Products />} />
            <Route path="/products/new" element={<FormProductsPage />} />
            <Route path="/products/edit/:id" element={<FormProductsPage />} />
          </>}

        <Route path="/business" element={<BusinessPage />} />
        <Route path="/business/new" element={<BusinessForm />} />
        <Route path="/business/details/:id/:edit?" element={<Details />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Content>
  );
}
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Row,
  Select,
  Spin,
  Tabs,
  Typography,
} from 'antd';
import TopFilter from '../../components/Dasboard/TopFilter';
import { StatisticCards } from '../../components/Dasboard/StatisticCards';
import { LinesGraphic } from '../../components/Commons/Graphics/LinesGraphic';
import { useState } from 'react';
import { getLabel } from '../../Enums/TypeOfKanban.enums';
import locale from 'antd/es/date-picker/locale/pt_BR';
import {
  getFunnelLinesData,
  getFunnelTypes,
  getStatisticData,
} from '../../services/dashboard.service';
import { useQuery } from 'react-query';
import { range } from 'rxjs';
import { dashParams } from '../../Interfaces/Dashboard.interfaces';
import LoadingComponent from '../../components/Commons/Loading';

const { Text } = Typography;
interface FunnelTypeArrayInterface {
  id?: number;
  type?: string;
}
export default function Funnel({ user }: { user: any }) {
  const [formSearch] = Form.useForm();
  const [statisticData, setStatisticData] = useState([]);
  const [rangeDate, setRangeDate] = useState<string[]>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [funnelType, setFunnelType] = useState<number>();
  const [funnelTypeName, setFunnelTypeName] = useState('');
  const [funnelTypeArray, setFunnelTypeArray] = useState<
    FunnelTypeArrayInterface[]
  >([]);
  const [linesData, setLinesData] = useState([]);
  const [params, setParams] = useState<dashParams>({ user: user?.id });

  const { isLoading: loadingStatistic, refetch: refetchStatistic } = useQuery(
    ['getStatisitcData', params],
    () => getStatisticData(params),
    {
      onSuccess: (data) => {
        setStatisticData(data?.data);
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: loadingFunnelType, refetch: refetchFunnelType } = useQuery(
    ['getFunnelTypes'],
    () => getFunnelTypes(),
    {
      onSuccess: (data) => {
        let items = data?.data;
        setFunnelTypeArray(items);
        setFunnelTypeName(getLabel(items[0].type));
        setFunnelType(items[0].id);
        setParams({ type: items[0].id, user: user?.id });
        refetchLinesData();
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: loadingLinesData, refetch: refetchLinesData } = useQuery(
    ['getFunnelLinesData', params],
    () => getFunnelLinesData(params),
    {
      onSuccess: (data) => {
        setLinesData(data?.data);
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const onMapDataToExport = (data: any) => {
    const result = [];
    if (data.length > 0) {
      result.push({ Descrição: 'Progresso' });
      data.forEach((item: any) => {
        result.push({
          Nome: item.name,
          Mês: item.month,
          Valor: item.values,
        });
      });
    }
    return result;
  };
  const cleanSearch = () => {
    formSearch.resetFields();
    setParams({ type: funnelTypeArray[0]?.id, user: user?.id });
    refetchLinesData();
    refetchStatistic();
    setFunnelType(funnelTypeArray[0]?.id);
  };

  const filteredFunnel = () => {
    formSearch
      .validateFields()
      .then((values: any) => {
        let funnel: number = values.selectedFunnel ?? funnelType;
        setFunnelType(funnel);

        let newParams: dashParams = params;

        if (!rangeDate[0]) {
          newParams = { ...newParams };
        } else {
          newParams = { ...newParams, startDate: rangeDate[0] };
        }

        if (!rangeDate[1]) {
          newParams = { ...newParams };
        } else {
          newParams = { ...newParams, endDate: rangeDate[1] };
        }

        if (!values.selectedFunnel) {
          newParams = { ...newParams, type: funnelType };
        } else {
          newParams = { ...newParams, type: values.selectedFunnel };
        }

        let titleName;
        const selectedFunnelItem: any = funnelTypeArray.find(
          (item) => item.id === values.selectedFunnel
        );

        if (selectedFunnelItem) {
          titleName = getLabel(selectedFunnelItem.type);
        } else {
          titleName = funnelTypeName;
        }

        setFunnelTypeName(titleName);
        setParams(newParams);
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };
  const filterContent = (
    <div>
      <Form
        form={formSearch}
        layout="vertical"
        autoComplete="off"
        style={{ width: 300 }}
      >
        {/* <Form.Item
                    label="Datas"
                    name="period"
                >
                    <DatePicker.RangePicker
                        style={{ width: "100%" }}
                        size="large"
                        format={"DD/MM/YYYY"}
                        onChange={(date, dateString) => {
                            setRangeDate(dateString);
                        }}
                        locale={{
                            ...locale,
                            lang: {
                                ...locale.lang,
                                rangePlaceholder: ["Início", "Fim"],
                            },
                        }}
                    />
                </Form.Item> */}
        <Form.Item label="Tipo de Funil" name="selectedFunnel">
          <Select
            size="large"
            style={{ width: '100%' }}
            defaultValue={funnelType}
          >
            {funnelTypeArray?.map((item: any) => (
              <option key={item.id} value={item.id}>
                {getLabel(item.type)}
              </option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item style={{ textAlign: 'right' }}>
          <Button
            type="default"
            size="large"
            onClick={() => cleanSearch()}
            style={{
              marginRight: '8px',
              borderWidth: '1px',
              borderColor: '#0E3441',
            }}
          >
            Limpar
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            onClick={() => filteredFunnel()}
          >
            Filtrar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
  return (
    <div className="content_page">
      <Card bodyStyle={{ padding: 16 }}>
        <TopFilter
          filterContent={filterContent}
          data={onMapDataToExport(linesData)}
          tableName={`Funil-${funnelTypeName}`}
          title={funnelTypeName}
        />
        {loadingStatistic ? (
          <LoadingComponent />
        ) : (
          <StatisticCards items={statisticData} gutter={16} />
        )}
        <Row gutter={16} style={{ marginTop: '16px' }}>
          <Col span={24}>
            <Card bordered={true}>
              <Text style={{ fontSize: '24px' }}>Progresso</Text>
              <div style={{ marginTop: '24px' }}>
                {loadingLinesData ? (
                  <LoadingComponent />
                ) : (
                  <LinesGraphic data={linesData} />
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Card>
    </div>
  );
}

import { LoadingOutlined } from "@ant-design/icons"
import { Spin } from "antd"

const contentStyle = {
    padding: 50,
    borderRadius: 4,
};

export default function LoadingComponent() {
    return (
        <Spin
            indicator={
                <LoadingOutlined
                    style={{
                        fontSize: 24,
                    }}
                    spin
                />
            }
            size="large"
            tip="Carregando"
        >
            <div style={contentStyle} />
        </Spin>
    )
} 
import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Typography, Divider, Button, Dropdown, MenuProps, Tag, Select, Form } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DashOutlined, EllipsisOutlined, FlagOutlined, MoreOutlined } from '@ant-design/icons';
import moment from 'moment';
import ModalConfirmCancelEvent from '../schedule/ModalConfirmCancelEvent';
import ModalEditColumn from './ModalEditColumn';
import { deleteColumnBoard, editColumnBoard, moveCardInBoard, moveKanbanColumns, setNewStateToCard } from '../../services/business.service';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { AlertService } from '../../services/alert.service';

const { Title, Text } = Typography;

const ItemType = 'CARD';
const ItemTypeColumn = 'COLUMN';

const CardItem = ({ card, index, column, moveCard, isLastColumn = null, columnKey = null }: any) => {
    const navigate = useNavigate();

    const { mutate: mutateMoveCard } = useMutation(moveCardInBoard, {
        onSuccess: () => {
            console.log('Card movido com sucesso');
        },
        onError: (error) => {
            console.log('Erro ao mover card', error);
        }
    });

    const { mutate: mutateSetNewStateToCard } = useMutation(setNewStateToCard, {
        onSuccess: () => {
            console.log('Estado salvo com sucesso');
        },
        onError: (error) => {
            console.log('Estado salvo com sucesso', error);
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { type: ItemType, id: card.id, index, column },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover(item: any, monitor) {
            if (item.id !== card.id) {
                const hoverBoundingRect: any = monitor.getClientOffset();
                if (hoverBoundingRect) {
                    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                    const hoverActualY = hoverBoundingRect.y - hoverBoundingRect.top;
                    let hoverIndex = hoverActualY > hoverMiddleY ? index + 1 : index;
                    moveCard(item.index, hoverIndex, item.id, item.column, column);
                    item.index = hoverIndex;
                    item.column = column;
                }
            }
        },
        drop: (item: any) => {
            mutateMoveCard({ card: { id: item.id, column: column, index: index, columnKey: columnKey } });
        },
    });

    const goToDetail = (edit: any | null) => {
        navigate(`/business/details/${card.id}/${edit}`);
    }

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={() => goToDetail('')} >
                    Ver detalhes
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a target="_blank" rel="noopener noreferrer" onClick={() => goToDetail('edit')}>
                    Editar
                </a>
            ),
        }
    ];

    return (
        <div key={index} ref={(node) => drag(drop(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
            <Card style={{
                boxShadow: '0px 2px 8px 0px #00000026',
                borderRadius: 8,
                margin: '8px 0px 16px 0px',

            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 8,
                }}>
                    <div style={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 8,
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Dropdown menu={{ items }} placement="bottomRight" overlayStyle={{ width: "200px" }} arrow>
                                <Button shape="circle" size="small" icon={<EllipsisOutlined />} />
                            </Dropdown>
                        </div>
                        <div onClick={() => goToDetail('')}>
                            <Title style={{ margin: 0 }} level={5}>{card?.name}</Title>
                            <Text style={{ color: '#595959' }} >{card.client?.name || 'Este dado foi apagado devido a RGPD'}</Text>
                            <div>
                                {card.value && <Tag>{parseFloat(card.value).toLocaleString("pt-PT", {
                                    style: "currency",
                                    currency: "EUR",
                                    minimumFractionDigits: 2,
                                })}</Tag>}
                            </div>
                        </div>
                    </div>
                    <div>
                        {card?.follow_up?.map((importantFollowUp: any) => (
                            <div key={importantFollowUp.id} style={{
                                backgroundColor: '#F5F5F5',
                                border: '1px solid #D9D9D9',
                                borderRadius: 4,
                                padding: 8,
                                display: 'flex',
                                gap: 16,
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginBottom: 4
                            }}>
                                {!!importantFollowUp.is_important && <div>
                                    <FlagOutlined style={{ color: '#F5222D', fontSize: 24 }} />
                                </div>}
                                <div>
                                    <Text>Próximo Follow up</Text>
                                    <Title style={{ margin: '4px 0px 0px 0px' }} level={5}>{moment(importantFollowUp.date).format('DD/MM/YYYY')}</Title>
                                </div>
                            </div>
                        ))}
                        {!!isLastColumn && <div style={{ marginTop: 8 }}>
                            <Form
                                layout='vertical'
                                initialValues={{ close_status: card.closed_state === 'aberto' ? 'Aberto' : card.closed_state }}
                            >
                                <Form.Item label="Status do negócio" name='close_status'>
                                    <Select size='large' placeholder="Status" onChange={(value) => { mutateSetNewStateToCard({ card: { id: card.id, state_business: value } }) }} >
                                        <Select.Option value="ganho">Ganho</Select.Option>
                                        <Select.Option value="perdido">Perdido</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Form>
                        </div>}
                    </div>
                </div>
            </Card>
        </div>
    );
};

const Column = ({ index, columnKey, title, children, moveCard, size, color, canRemoveOrEdit, refetchBoard, isAdmin, colorsInUse, moveColumn, isLast, isFirst }: any) => {
    const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
    const [contentModal, setContentModal] = useState<{ text: string, description: string, cancelText: string, okText: string, onOk: any }>({ text: '', description: '', cancelText: '', okText: '', onOk: () => { } });
    const [loadingCancel, setLoadingCancel] = useState<any>(null);

    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [loadingEdit, setLoadingEdit] = useState<any>(null);

    const { mutate: mutateEdiColumn } = useMutation(editColumnBoard, {
        onSuccess: () => {
            AlertService.sendAlert([
                {
                    text: "A etapa foi editada com sucesso!",
                    nextPage: false,
                }
            ])
            setLoadingEdit(false);
            setOpenEditModal(false);
            refetchBoard(true);
        },
        onError: (error) => {
            console.log('Erro ao editar coluna', error);
        }
    });

    const { mutate: mutateDeleteColumn } = useMutation(deleteColumnBoard, {
        onSuccess: () => {
            AlertService.sendAlert([
                {
                    text: "A etapa foi apagada com sucesso!",
                    nextPage: false,
                }
            ])
            setLoadingCancel(false);
            setOpenModalConfirm(false);
            refetchBoard(true);
        },
        onError: (error) => {
            console.log('Erro ao apagar coluna', error);
        }
    });

    const confirmModal = () => {
        setLoadingCancel(true);
        mutateDeleteColumn({ boardId: columnKey });
    };

    const confirmEditModal = (form: any) => {
        setLoadingEdit(true);

        form.color = form?.color ? form.color : color;

        mutateEdiColumn({ boardId: columnKey, column: form });
    };

    const items = [
        {
            key: '1',
            label: 'Editar coluna',
            onClick: () => {
                setOpenEditModal(true);
            },
        },
        {
            key: '2',
            label: 'Apagar coluna',
            disabled: !canRemoveOrEdit,
            onClick: () => {
                if (children.length > 0) {
                    setContentModal({
                        text: 'Não é possível apagar esta etapa pois existem negócios associados a ela',
                        description: 'Antes de continuar, volte à secção de negócios para editar os negócios associados a esta etapa.',
                        cancelText: 'Voltar',
                        okText: 'Editar associações',
                        onOk: () => setOpenModalConfirm(false),
                    });
                } else {
                    setContentModal({
                        text: 'Tem certeza que deseja apagar a etapa?',
                        description: 'Nenhum negócio será afetado.',
                        cancelText: 'Voltar',
                        okText: 'Apagar',
                        onOk: () => confirmModal(),
                    });
                }
                setOpenModalConfirm(true);
            },
        }
    ];

    const { mutate: mutateMoveCard } = useMutation(moveCardInBoard, {
        onSuccess: () => {
            console.log('Card movido com sucesso');
        },
        onError: (error) => {
            console.log('Erro ao mover card', error);
        }
    });

    const [, drop] = useDrop({
        accept: ItemType,
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true }),
        }),
        drop: (item: any, monitor) => {
            if (!monitor.didDrop()) {
                moveCard(item.index, children.length, item.id, item.column, title);

                mutateMoveCard({ card: { ...item, columnKey: columnKey }, });

                item.column = title;
            }
        },
    });

    const [, dropColumn] = useDrop({
        accept: ItemTypeColumn,
        collect: (monitor) => ({
            isOver: monitor.isOver({ shallow: true }),
        }),
        hover(item: any, monitor) {
            if (item.isFirst || item.isLast) {
                return;
            }
            if (item.id !== columnKey) {
                const hoverBoundingRect: any = monitor.getClientOffset();
                if (hoverBoundingRect) {

                    if (isFirst || isLast) {
                        return;
                    }

                    let newIndex = index > item.index ? index + 1 :
                        (index < item.index) ? index - 1 : item.index;

                    item.oldIndex = index
                    item.oldColumnId = columnKey;
                    item.newIndex = newIndex;

                    moveColumn(item)

                }
            }
        },
        drop: (item: any, monitor) => {
            if (!monitor.didDrop()) {
                moveColumn(item, true);
            }
        },
    });

    const [{ isDragging }, dragColumn] = useDrag({
        type: ItemTypeColumn,
        item: { type: ItemTypeColumn, id: columnKey, index: index, isFirst, isLast },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    });

    const totalValue = children.reduce((total: any, child: any) => parseFloat(total) + (parseFloat(child.props.card.value) || 0), 0);

    return (
        <div ref={(node) => dragColumn(dropColumn(node))} key={columnKey}>
            <Col key={columnKey} ref={drop} span={Math.round(24 / size)} style={{ minWidth: 275, maxWidth: 395, height: '100%' }}>
                <div style={{
                    ...{
                        backgroundColor: color,
                        minHeight: 90,
                        padding: '16px 16px 8px 16px',
                        borderTopRightRadius: 16,
                        borderTopLeftRadius: 16,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    },
                    ...{ border: '1px solid #FAFAFA' },
                    ...(color === '#141414' || color === '#545454' || color === '#737373' || color === '#A6A6A6' ? { color: 'white' } : {}),
                }}>
                    <Title style={{ margin: 0, ...(color === '#141414' || color === '#545454' || color === '#737373' || color === '#A6A6A6' ? { color: 'white' } : {}) }} level={5}>{title}</Title >
                    {isAdmin && <Dropdown menu={{ items }} placement="bottomRight" arrow={{ pointAtCenter: true }}>
                        <Button shape='circle' size='small'><DashOutlined /></Button>
                    </Dropdown>}
                </div>
                <div style={{
                    backgroundColor: '#F5F5F5',
                    padding: '0px 12px 0px 12px',
                    borderBottom: '1px solid #BFBFBF',
                    // borderLeft: '1px solid #BFBFBF',
                    // borderRight: '1px solid #BFBFBF',
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        padding: '8px 0px 8px 0px',
                    }}>
                        <Text>{children.length} negócios</Text>
                        <Text>{totalValue.toLocaleString("pt-PT", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 2,
                        })}</Text>
                    </div>
                </div>
                <div style={{ padding: '0px 12px 0px 12px', backgroundColor: '#FAFAFA', minHeight: '50vh', overflowY: 'auto', height: '100%' }}>
                    {React.Children.map(children, (child, index) => React.cloneElement(child, { index, column: title, moveCard, columnKey }))}
                </div>
                {openModalConfirm && contentModal && (
                    <ModalConfirmCancelEvent
                        onOk={contentModal.onOk}
                        open={openModalConfirm}
                        loading={loadingCancel}
                        onCancel={() => setOpenModalConfirm(false)}
                        text={contentModal.text}
                        description={contentModal.description}
                        cancelText={contentModal.cancelText}
                        okText={contentModal.okText}
                    />
                )}
                {openEditModal && (
                    <ModalEditColumn
                        open={openEditModal}
                        onOk={confirmEditModal}
                        loading={loadingEdit}
                        onCancel={() => setOpenEditModal(false)}
                        text="Editar etapa"
                        cancelText="Cancelar"
                        okText="Gravar"
                        data={{ title: title, color: color }}
                        colorsInUse={colorsInUse}
                    />
                )}
            </Col >
        </div>
    );
};

const KanbanBoard = ({ kanban, refetchBoard, isAdmin }: any) => {
    const [columns, setColumns] = useState<any>([]);

    useEffect(() => {
        setColumns(kanban.columns.map((col: any, index: number) => {
            col.index = index
            return col;
        }));
    }, [kanban]);

    const { mutate: mutationMoveColumns } = useMutation(moveKanbanColumns);

    const moveCard = (dragIndex: number, hoverIndex: number, id: number, fromColumn: string, toColumn: string) => {
        const newColumns: any = [...columns];
        const fromColumnCards = newColumns.find((col: any) => col.title === fromColumn)?.columns || [];
        const toColumnCards = newColumns.find((col: any) => col.title === toColumn)?.columns || [];

        const dragCard = fromColumnCards.find((card: any) => card.id === id);

        fromColumnCards.splice(dragIndex, 1);
        toColumnCards.splice(hoverIndex, 0, dragCard);

        newColumns[fromColumn] = fromColumnCards;
        newColumns[toColumn] = toColumnCards;

        setColumns(newColumns);
    };

    const moveColumn = (item: any, drop: boolean = false) => {
        const newColumns: any = [...columns];

        const newColumnsNewOrder = newColumns.map((col: any) => {
            if (col.id === item.id) {
                col.index = item.newIndex
            }

            if (col.index > item.index) {
                col.index++;
            }

            return col;
        }).sort((a: any, b: any) => a.index - b.index).map((col: any, index: any) => {
            col.index = index;
            return col;
        });

        setColumns(newColumnsNewOrder);

        if (drop) {
            mutationMoveColumns({
                business_kanban_id: kanban.id,
                columns: newColumnsNewOrder.map((col: any) => ({
                    id: col.id,
                    index: col.index,
                }))
            })
        }
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Row style={{ overflowX: 'auto', flexWrap: 'nowrap', gap: 8 }}>
                {columns.map((column: any, index: number) => {
                    return (
                        <Column colorsInUse={columns.map((column: any) => column.color)}
                            index={column.index}
                            columnKey={column.id}
                            title={column.title}
                            moveCard={moveCard}
                            size={columns.length}
                            color={column.color}
                            canRemoveOrEdit={!column?.is_first && !column?.is_last}
                            refetchBoard={refetchBoard}
                            isAdmin={isAdmin}
                            isLast={column.is_last}
                            isFirst={column.is_first}
                            moveColumn={moveColumn}>
                            {column.columns.map((card: any, index: number) => {
                                return (
                                    <CardItem key={card.id} card={card} index={index} column={column} moveCard={moveCard} isLastColumn={column?.is_last} columnKey={column.id} />
                                );
                            })}
                        </Column>
                    )
                })}
            </Row>
        </DndProvider>
    );
};

export default KanbanBoard;
import { Button, Card, Divider } from "antd";
import DisplayAlert from "../../components/Commons/Alert";
import ProductsTable from "./Table";
import { useNavigate } from "react-router-dom";

export default function Products() {
    const navigate = useNavigate();

    return (
        <div className="content_page">
            <DisplayAlert />
            <Card bodyStyle={{ padding: 16 }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                    }}
                >
                    <Button type="primary" size="large" onClick={() => navigate('/products/new')}>
                        Novo produto
                    </Button>
                </div>
                <Divider />
                <ProductsTable />
            </Card>
        </div>
    )
}
import { Column } from "@ant-design/plots";
import { Empty } from "antd";
import { formatCurrency } from "../../../services/utils";

export function ColumnsGraphic({ data }: any) {
  const GraphicColumn = () => {
    const config = {
      data: {
        value: data,
      },
      xField: 'month',
      yField: 'values',
      colorField: 'name',
      group: true,
      style: {
        inset: 5,
      },
      axis: {
        y: {
          labelFormatter: (datum: any) => formatCurrency(datum || 0),
        },
      },
      label: {
        text: (datum: any) => formatCurrency(datum.values || 0),
        textBaseline: 'bottom',
      },
      tooltip: (
        datum: any,
        index: number,
        data: any,
        column: any,
      ) => {
        return (
          {
            name: datum.name,
            color: column.y.color,
            field: column.y.field,
            value: column.y.field === 'values'
              ? formatCurrency(column.y.value[index])
              : column.y.value[index]
          }
        );
      },
    };
    return <Column {...config} />;
  };

  return (data.length > 0 ? <GraphicColumn /> : <Empty />);
}
import { BellOutlined, CloseOutlined, InfoCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Divider, Dropdown, Empty, MenuProps, Typography } from "antd";
import { useMutation, useQuery } from "react-query";
import { getNotification, markAllAsRead, requestByUser } from "../../services/notification.service";
import { useState } from "react";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import AcceptRequestToChangeReferrerComponent from "../Clients/acceptRequestToChangeReferrer.component";

export default function NotificationListComponent() {

    const userStorage = localStorage.getItem("user") || null;

    const [user, setUser] = useState(
        userStorage ? JSON.parse(userStorage) : null
    );

    const [notifications, setNotifications] = useState<ItemType[]>([]);
    const [totalNotifications, setTotalNotifications] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);
    const [showRequestToChangeReferrer, setShowRequestToChangeReferrer] = useState<null | any>(null);

    const { mutate: mutateMarkAllAsRead } = useMutation(markAllAsRead);

    const calculateTimeSinceCreation = (createdAt: string): string => {
        const currentTime = new Date();
        const creationTime = new Date(createdAt);
        const differenceInSeconds = Math.floor((currentTime.getTime() - creationTime.getTime()) / 1000);

        if (differenceInSeconds < 60) {
            return `${differenceInSeconds} segundos atrás`;
        } else if (differenceInSeconds < 3600) {
            const minutes = Math.floor(differenceInSeconds / 60);
            return `${minutes}m atrás`;
        } else if (differenceInSeconds < 86400) {
            const hours = Math.floor(differenceInSeconds / 3600);
            return `${hours}h atrás`;
        } else {
            const days = Math.floor(differenceInSeconds / 86400);
            return `${days}d atrás`;
        }
    };

    const { mutate: mutateRequestByUser } = useMutation(requestByUser, {
        onSuccess: (data:any) => {
            showNotificationDetail(data.data)
        },
        onError: () => {
            console.info('teste');
        }
    });

    const showNotificationDetail = (item: any) => {
        setOpen(false);
        setShowRequestToChangeReferrer({
            referencer_email: item.notifiable.requested_by_user.email,
            name: item.notifiable.client.name,
            notification_id: item.id,
            request_id: item.notifiable.id,
            type_business: item.notifiable.client.type_business,
            referencer_name: item.notifiable.requested_by_user.name,
            referencer_phone: item.notifiable.requested_by_user.phone,
            email: item.notifiable.client.email
        });
    }

    const { refetch } = useQuery(["getNotification"], getNotification, {
        refetchOnWindowFocus: false,
        enabled: !!user?.token,
        onSuccess: (data: any) => {
            if (data?.data.length === 0) {
                setTotalNotifications(0);
                setNotifications([{
                    key: 0,
                    label: (
                        <div style={{ display: 'flex', justifyContent: 'center', gap: 16, alignItems: "center", width: 570 }}>
                            <Empty
                                image="/empty-table.svg"
                                imageStyle={{ height: 85 }}
                                description={
                                    <span style={{ color: 'rgba(0, 0, 0, 0.25)', fontSize: 14 }}>
                                        <p><Typography.Text style={{ fontSize: 16, color: '#8C8C8C' }}>Você não tem novas notificações</Typography.Text></p>
                                    </span>
                                }></Empty>
                        </div>
                    )
                }]);

                return;
            }

            setTotalNotifications(data?.data.filter((item: any) => !item.read).length);

            data?.data.sort((a:any, b:any) => {
                if (a.message === "referrer_change_request" && a.status === "pending") return -1;
                if (b.message === "referrer_change_request" && b.status === "pending") return 1;
                return 0;
            });
            setNotifications(data?.data.map((item: any, index: number) => {
                return {
                    key: index,
                    label: (
                        <div className={`notificationItem ${!item.read ? 'notificationItemUnread' : ''}`}>
                            <div style={{ display: 'flex', justifyContent: 'left', gap: 16 }}>
                                {!item.read ? <InfoCircleOutlined style={{ fontSize: 24, color: '#0E3441' }} /> : null}
                                <div>
                                    <p><Typography.Text style={{ fontSize: 16, color: '#1F1F1F' }}>{item.reason}</Typography.Text></p>
                                    <p><Typography.Text style={{ fontSize: 14, color: '#8C8C8C' }}>{calculateTimeSinceCreation(item.created_at)}</Typography.Text></p>
                                </div>
                            </div>
                            <div>
                                {item.message === 'referrer_change_request' && item.status === 'pending' ?
                                    <Button type="primary" onClick={() => mutateRequestByUser({notification_id: item.id})}>Ver mais</Button> :
                                    item.message === 'referrer_change_request' &&
                                    <Button type="primary" disabled>
                                        {item.status === 'accepted' ? 'Aprovado' : 'Rejeitado'}
                                    </Button>
                                }
                            </div>
                        </div>
                    ),
                }
            }));
        }
    });

    const openAndCloseDropdown = () => {
        if (!open) {
            mutateMarkAllAsRead();
            setTotalNotifications(0);
        }

        setOpen(!open)
    }

    return (
        <>
            <Dropdown
                placement="bottomRight"
                arrow
                menu={{ items: notifications }}
                dropdownRender={(menu) => (
                    <div style={{
                        maxHeight: 570,
                        overflowY: 'auto',
                        background: '#FFF',
                        borderRadius: 8,
                        boxShadow: '0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05)'
                    }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', gap: 16, alignItems: "center", padding: '16px 24px ' }}>
                            <div>
                                <Typography.Title level={4} style={{ color: "#14424C" }}>Notificações</Typography.Title>
                            </div>
                            <div>
                                <Button type="text" onClick={openAndCloseDropdown} icon={<CloseOutlined />} ></Button>
                            </div>
                        </div>
                        <Divider style={{ margin: 0 }} />
                        <div>
                            {menu}
                        </div>
                    </div>
                )}
                open={open}
            >
                <Badge count={totalNotifications} style={{ top: 5 }}>
                    <Button
                        type="default"
                        shape="circle"
                        icon={<BellOutlined />}
                        size="large"
                        onClick={openAndCloseDropdown}
                    ></Button>
                </Badge>
            </Dropdown>
            <AcceptRequestToChangeReferrerComponent
                client={showRequestToChangeReferrer}
                open={!!showRequestToChangeReferrer}
                close={() => {
                    refetch();
                    setShowRequestToChangeReferrer(null)
                }}
                title="Pedido para alteração de Referenciador"
                readOnly={true}
                loading={false}
            />
        </>
    )
}